import React from 'react';

export default function Footer(props: any) {
	return (
		<footer>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
					<div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2 d-flex justify-content-between">
						<div>
							<a href="https://dramki.pl" className="footer" target="_blank">
								Dramki.pl
							</a>
							<a
								href="https://dramki.pl/regulamin"
								className="footer ps-2"
								target="_blank"
							>
								Regulamin
							</a>
						</div>
						<div>
							<a
								href="https://dramki.pl/regulamin"
								className="footer d-md-none"
								target="_blank"
							>
								&copy; {new Date().getFullYear()}, dramki.pl
							</a>
						</div>
					</div>
					<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
						<a href="https://dramki.pl/regulamin" className="footer" target="_blank">
							&copy; {new Date().getFullYear()}, dramki.pl
						</a>
					</div>
					<div className="col-xl-1 d-none d-xl-block"></div>
				</div>
			</div>
		</footer>
	);
}
