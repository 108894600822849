import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { authenticationActions } from '../../../../business/Auth/AuthActions';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { routes } from '../../../../business/router/path-definitions';
import { NotLoggedMenu } from '../NotLoggedMenu/NotLoggedMenu';
import { MenuFooter } from '../MenuFooter/MenuFooter';

export interface ILandingMenuItemProps {
	auth: IAuthReduxState;
}
export const LandingMenuItem: React.FC<ILandingMenuItemProps> = (props) => {
	const dispatch = useDispatch();
	const handleLogout = (event: any) => {
		event.preventDefault();
		dispatch(authenticationActions.logOutAction());
	};
	return (
		<div className="sh-menu sticky">
			{props.auth.token ?? false ? (
				<>
					<p>Cześć {props.auth.username} </p>
					<Link to={'/profile/' + props.auth.userId} className="sh-btn">
						<i className="icon-Followers"></i>Zobacz swój profil
					</Link>
					<Link to={routes.addArticle} className="sh-btn">
						<i className="icon-Post"></i>Nowa dramka
					</Link>
					<Link to={routes.patreonList} className="sh-btn">
						<span className="icon-Cool emoticon">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
							<span className="path4"></span>
							<span className="path5"></span>
						</span>
						Patroni
					</Link>
					<a href="#" className="sh-btn" onClick={(e) => handleLogout(e)}>
						<i className="icon-Arrow_Down"></i>Wyloguj
					</a>
					<MenuFooter />
				</>
			) : (
				<NotLoggedMenu />
			)}
		</div>
	);
};
