import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/profilePage";
import { BecomeAPatron, PaymentChannel } from "../../../client";
import { Header } from "../../molecules/Header/Header";
import { PatreonMenu } from "../../molecules/Menu/PatreonMenu/PatreonMenu";
import { Patreon } from "../../molecules/Profile/Patreon/Patreon";

export interface IPatreonPageComponentProps {
  user: BecomeAPatron;
  paymentChannels: PaymentChannel[];
}
const useStyles = makeStyles(styles);

export const PatreonPageComponent: React.FC<IPatreonPageComponentProps> = (
  props
) => {
  const [editProfile, setEditProfile] = React.useState<{
    user: BecomeAPatron;
    paymentChannels: PaymentChannel[];
  }>({ user: props.user, paymentChannels: props.paymentChannels});
  React.useEffect(() => {
    setEditProfile({ user: props.user, paymentChannels: props.paymentChannels});
  }, [props.user]);
  const classes = useStyles();
  return (
    <>
      <Header>
        <PatreonMenu
          editProfile={editProfile}
          setEditProfile={setEditProfile}
        />
      </Header>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url('/assets/img/bg7.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      ></div>
      <div className={classes.mainRaised}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
              <Patreon
                editProfile={editProfile}
                setEditProfile={setEditProfile}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
              <PatreonMenu
                editProfile={editProfile}
                setEditProfile={setEditProfile}
              />
            </div>
            <div className="col-xl-1 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </>
  );
};
