import { createMatchSelector } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import * as Redux from 'redux';
import { AnyAction, Dispatch } from 'redux';
import { GetUserData } from '../../../business/Profile/ProfileEpics';
import { RootState } from '../../../business/root-store';
import { routes } from '../../../business/router/path-definitions';
import { EditProfilePageComponent } from './EditProfilePageComponent';

interface IEditProfilePageContainerReduxMergedProps {
	match: match<{ id?: string }>;
}

interface IEditProfilePageContainerOwnProps {}

interface IEditProfilePageContainerDispatchProps {
	getUserData: (userId: number) => void;
}

interface IEditProfilePageContainerProps
	extends IEditProfilePageContainerOwnProps,
		IEditProfilePageContainerDispatchProps,
		IEditProfilePageContainerReduxMergedProps {}

interface IEditProfilePageComponentState {}

class EditProfilePageContainer extends React.Component<
	IEditProfilePageContainerProps,
	IEditProfilePageComponentState
> {
	constructor(props: IEditProfilePageContainerProps, state: IEditProfilePageComponentState) {
		super(props, state);
		this.state = this.getInitialState();
		const userIdFromUrl = +this.props.match.params.id!;
		this.props.getUserData(userIdFromUrl);
	}

	public render(): JSX.Element {
		return <EditProfilePageComponent />;
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IEditProfilePageContainerOwnProps
	): IEditProfilePageContainerReduxMergedProps => {
		const matchSelector = createMatchSelector(routes.profile);
		return {
			match: matchSelector(state)!
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IEditProfilePageContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					getUserData: GetUserData
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IEditProfilePageComponentState => {
		return {} as IEditProfilePageComponentState;
	};
}
export const EditProfilePage = connect(
	EditProfilePageContainer.mapStateToProps,
	EditProfilePageContainer.mapDispatchToProps
)(EditProfilePageContainer);
