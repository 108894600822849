import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../business/router/path-definitions';

export default function VerifyMailMenu() {
	return (
		<div className="sh-section sh-menu sticky">
			<Link to={routes.home} className="sh-btn">
				<i className="icon-Arrow_Up"></i>Strona główna
			</Link>
		</div>
	);
}
