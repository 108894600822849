import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { RootState } from "../../../../business/root-store";
import { ArticleListComponent } from "./ArticleListComponent";
import {
  downVote,
  fetchArticleList,
  loadMoreArticles,
  removeArticle,
  upVote,
} from "../../../../business/Article/ArticleEpics";
import { ArticleListItem } from "../../../../client";
import { List } from "immutable";
import {
  getArticleList,
  getHasMoreToLoad,
} from "../../../../business/Article/ArticleSelectors";

interface IArticleListContainerReduxMergedProps {
  articleItems: List<ArticleListItem>;
  hasMoreToLoad: boolean;
}

interface IArticleListContainerOwnProps {
  tagId?: number;
  tagName?: string;
  userId?: number;
  othersArticles?: boolean;
}

interface IArticleListContainerDispatchProps {
  onArticleRemoved: (id: number) => void;
  onUpVote: (statisticsId: number, currentLike: boolean) => void;
  onDownVote: (statisticsId: number, currentDislike: boolean) => void;
  loadArticles: (
    id: number,
    userIdFilter?: number,
    tagIdFilter?: number
  ) => void;
  onFetchMoreData: (userIdFilter?: number, tagIdFilter?: number) => void;
}

interface IArticleListContainerProps
  extends IArticleListContainerOwnProps,
    IArticleListContainerDispatchProps,
    IArticleListContainerReduxMergedProps {}

interface IArticleListComponentState {}

class ArticleListContainer extends React.Component<
  IArticleListContainerProps,
  IArticleListComponentState
> {
  constructor(
    props: IArticleListContainerProps,
    state: IArticleListComponentState
  ) {
    super(props, state);
    this.state = this.getInitialState();
    this.props.loadArticles(0, this.props.userId, this.props.tagId);
  }

  public render(): JSX.Element {
    return (
      <ArticleListComponent
        hasMoreToLoad={this.props.hasMoreToLoad}
        onFetchMoreData={this.props.onFetchMoreData}
        articleList={this.props.articleItems}
        onItemRemoved={this.props.onArticleRemoved}
        onUpVote={this.props.onUpVote}
        onDownVote={this.props.onDownVote}
        loadArticles={this.props.loadArticles}
        tagId={this.props.tagId}
        tagName={this.props.tagName}
        userId={this.props.userId}
        othersArticles={this.props.othersArticles}
      />
    );
  }

  public static mapStateToProps = (
    state: RootState,
    ownProps: IArticleListContainerOwnProps
  ): IArticleListContainerReduxMergedProps => {
    return {
      articleItems: getArticleList(state.article),
      hasMoreToLoad: getHasMoreToLoad(state.article),
    };
  };
  public static mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>
  ): IArticleListContainerDispatchProps => {
    return {
      ...Redux.bindActionCreators(
        {
          onArticleRemoved: removeArticle,
          onUpVote: upVote,
          onDownVote: downVote,
          onFetchMoreData: loadMoreArticles,
          loadArticles: fetchArticleList,
        },
        dispatch
      ),
    };
  };

  private getInitialState = (): IArticleListComponentState => {
    return {} as IArticleListComponentState;
  };
}
export const ArticleList = connect(
  ArticleListContainer.mapStateToProps,
  ArticleListContainer.mapDispatchToProps
)(ArticleListContainer);
