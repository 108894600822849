import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../root-store";
// import { IAuthReduxState } from "./AuthReducer";

// const authStateSelector = (authState: IAuthReduxState) => authState;

export const getUsername = (state: RootState) => state.auth.username;
export const getAuth = (state: RootState) => state.auth;
export const getUsernameLowerCase = createSelector(
  getUsername,
  (username) => username.toLowerCase().trim().split("@")[0]
);
export const getToken = createSelector(getAuth, (auth) => auth.token);

export const isTokenLoaded = createSelector(
  getAuth,
  (auth) => auth.token && auth.token.length > 0
);

export const isSignedIn = createSelector(
  [getUsernameLowerCase, isTokenLoaded],
  (username, tokenState) => username.length > 0 && tokenState
);
