import { CustomFilePond } from '../../CustomFilePond/CustomFilePond';
import { CustomQuill } from '../../CustomQuill/CustomQuill';
import { FilePondFile } from 'filepond';
import React from 'react';
import { User } from '../../../../client';

interface IEditProfileComponentProps {
	userData: User;
	userDataImage: string | FilePondFile | null;
	UserDataImageSaved: (image: string | FilePondFile | null) => void;
	UserDataDescriptionSaved: (description: string) => void;
	UserDataImageRemoved: () => void;
	UserDataImageCounterRemoved: () => void;
	UserDataImageHadInitialSaved: (userDataHadinitial: boolean) => void;
}

interface IEditProfileComponentState {
	datePickerState: boolean;
}

export class EditProfileComponent extends React.Component<
	IEditProfileComponentProps,
	IEditProfileComponentState
> {
	constructor(props: IEditProfileComponentProps, state: IEditProfileComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	componentWillUnmount() {
		this.props.UserDataImageCounterRemoved;
	}

	public render(): JSX.Element {
		var maxDate = new Date();
		maxDate.setDate(maxDate.getDate());
		maxDate.setFullYear(maxDate.getFullYear() - 8);
		var minDate = new Date();
		minDate.setDate(maxDate.getDate());
		minDate.setFullYear(maxDate.getFullYear() - 100);
		let image = this.props.userDataImage;
		if (this.props.userData.avatar != null && this.props.userData.avatar != undefined) {
			this.props.UserDataImageHadInitialSaved(true);
			image = this.props.userData.avatar;
		}
		return (
			<div className="sh-section ">
				<div className="sh-section__content">
					<CustomFilePond
						imageSaved={this.props.UserDataImageSaved}
						description="Wybierz lub przerzuć aby dodać avatar"
						image={image}
						imageRemoved={this.props.UserDataImageRemoved}
					/>
					<CustomQuill
						handleChange={this.props.UserDataDescriptionSaved}
						value={this.props.userData.description}
					/>
					<br />
					<br />
					{/* <TextField
						value={props.editProfile.user.userName}
						label="Login"
						margin="normal"
						InputLabelProps={{
							shrink: true
						}}
						onChange={(e) => {
							props.setEditProfile({
								...props.editProfile.user,
								user: { ...props.editProfile.user, userName: e.target.value },
								image: props.editProfile.image
							});
						}}
					/>
					<br />
					<br />
					<TextField
						value={props.editProfile.user.name}
						label="Imię i nazwisko"
						margin="normal"
						InputLabelProps={{
							shrink: true
						}}
						onChange={(e) => {
							props.setEditProfile({
								user: { ...props.editProfile.user, name: e.target.value },
								image: props.editProfile.image
							});
						}}
					/>
					<br />
					<br />
					<TextField
						value={props.editProfile.user.mail}
						label="Mail"
						margin="normal"
						InputLabelProps={{
							shrink: true
						}}
						onChange={(e) => {
							props.setEditProfile({
								user: { ...props.editProfile.user, mail: e.target.value },
								image: props.editProfile.image
							});
						}}
					/>
					<br />
					<br />
					<MuiPickersUtilsProvider utils={DateMomentUtils}>
						<KeyboardDatePicker
							openTo="year"
							invalidDateMessage="Podaj prawidłową datę"
							maxDateMessage="Podaj prawidłową datę"
							minDateMessage="Podaj prawidłową datę"
							variant="inline"
							// orientation="landscape"
							InputAdornmentProps={{
								id: 'datepickerdob'
							}}
							required
							autoOk
							disableFuture
							fullWidth
							onClick={() => {
								setdatePickerState(true);
							}}
							onClose={() => {
								setdatePickerState(false);
							}}
							open={datePickerState}
							format="DD/MM/yyyy"
							label="Data urodzenia"
							maxDate={maxDate}
							minDate={minDate}
							initialFocusedDate={maxDate}
							value={props.editProfile.user.dateOfBirth}
							onChange={(date: MaterialUiPickersDate) => {
								props.setEditProfile({
									user: {
										...props.editProfile.user,
										dateOfBirth: date?.toJSON()
									},
									image: props.editProfile.image
								});
							}}
						/>
					</MuiPickersUtilsProvider> */}
				</div>
			</div>
		);
	}

	private getInitialState = (): IEditProfileComponentState => {
		return { datePickerState: false } as IEditProfileComponentState;
	};
}
