import React from "react";
import { IAuthReduxState } from "../../../../business/Auth/AuthReducer";
import { LandingMenuItem } from "./LandingMenuItem";

interface ILandingMenuComponentProps {
  auth: IAuthReduxState;
}

interface ILandingMenuComponentState {}

export class LandingMenuComponent extends React.Component<
  ILandingMenuComponentProps,
  ILandingMenuComponentState
> {
  constructor(
    props: ILandingMenuComponentProps,
    state: ILandingMenuComponentState
  ) {
    super(props, state);

    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return <LandingMenuItem auth={this.props.auth} />;
  }

  // private renderArticleSection = (): JSX.Element => {
  //   if (!this.isListVisible() || !this.props.tags) {
  //     return <></>;
  //   }
  //   return <p>dupa</p>;
  // };
  // private isListVisible = (): boolean => {
  //   return this.props.tags && this.props.tags != null;
  // };

  private getInitialState = (): ILandingMenuComponentState => {
    return {} as ILandingMenuComponentState;
  };
}
