import { Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/components/headerLinksStyle";

const useStyles = makeStyles(styles);

export default function HeaderRightLinks(props: any) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Obserwuj nas na facebooku"
          placement={window.innerWidth > 959 ? "top" : "left"}
        >
          <Button
            // color="transparent"
            href="https://www.facebook.com/dramkiofficial?ref=dramkipl"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Obserwuj nas na Instagramie"
          placement={window.innerWidth > 959 ? "top" : "left"}
        >
          <Button
            // color="transparent"
            href="https://www.instagram.com/dramkiofficial/?ref=dramkipl"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="instagram-twitter"
          title="Obserwuj nas na Twitterze"
          placement={window.innerWidth > 959 ? "top" : "left"}
        >
          <Button
            href="https://twitter.com/dramkiofficial?ref=dramkipl"
            target="_blank"
            // color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
