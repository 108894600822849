import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "../../../assets/jss/material-kit-react/views/ArticlePage";
import { Header } from "../../molecules/Header/Header";
const useStyles = makeStyles(styles);

type PaymentsParams = {
  paymentId: string;
};
export default function PaymentsSuccess() {
  const {paymentId} = useParams<PaymentsParams>();
  const classes = useStyles();
  return (
    <>
      <Header>{/* faqmenu */}</Header>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url('/assets/img/bg7.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      ></div>
      <div className={classNames(classes.mainRaised)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="sh-content-head sh-content-head__flex-off">
                <div className="row">
                  <div className="sh-section">
                    <div className="sh-section__head">
                      <h1>Płatność przebiegła pomyślnie, bank pracuje nad przelewem...</h1>
                      <br />
                      {paymentId }
                    </div>
                     </div>
                  </div>
                </div>
                <div className="col-xxl-4 d-none d-xxl-block"></div>
              </div>
            
          </div>
          <div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
        </div>
      </div>
    </>
  );
}
