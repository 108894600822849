import { createMatchSelector } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import * as Redux from 'redux';
import { AnyAction, Dispatch } from 'redux';
import { IAuthReduxState } from '../../../business/Auth/AuthReducer';
import { getAuth } from '../../../business/Auth/AuthSelectors';
import { GetUserData, GetPaymentChannels } from '../../../business/Profile/ProfileEpics';
import { getBecomeAPatron, getPaymentChannels } from '../../../business/Profile/ProfileSelectors';
import { RootState } from '../../../business/root-store';
import { routes } from '../../../business/router/path-definitions';
import { navigateTo } from '../../../business/Static/StaticEpics';
import { BecomeAPatron, PaymentChannel } from '../../../client';
import { PatreonPageComponent } from './PatreonPageComponent';

interface IPatreonPageContainerReduxMergedProps {
	patronDetails: BecomeAPatron;
	auth: IAuthReduxState;
	match: match<{ id?: string }>;
	paymentChannels: PaymentChannel[];
}

interface IPatreonPageContainerOwnProps {}

interface IPatreonPageContainerDispatchProps {
	getUserData: (userId: number) => void;
	getPaymentChannels: () => void;
	navigateTo: (path: string) => void;
}

interface IPatreonPageContainerProps
	extends IPatreonPageContainerOwnProps,
		IPatreonPageContainerDispatchProps,
		IPatreonPageContainerReduxMergedProps {}

interface IPatreonPageComponentState {}

class PatreonPageContainer extends React.Component<
	IPatreonPageContainerProps,
	IPatreonPageComponentState
> {
	constructor(props: IPatreonPageContainerProps, state: IPatreonPageComponentState) {
		super(props, state);
		this.state = this.getInitialState();

		const userIdFromUrl = +this.props.match.params.id!;
		this.props.getUserData(userIdFromUrl);
		this.props.getPaymentChannels();
	}

	public render(): JSX.Element {
		return (
			<PatreonPageComponent
				user={this.props.patronDetails}
				paymentChannels={this.props.paymentChannels}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IPatreonPageContainerOwnProps
	): IPatreonPageContainerReduxMergedProps => {
		const matchSelector = createMatchSelector(routes.profile);
		return {
			auth: getAuth(state),
			patronDetails: getBecomeAPatron(state.profile),
			match: matchSelector(state)!,
			paymentChannels: getPaymentChannels(state.profile)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IPatreonPageContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: navigateTo,
					getPaymentChannels: GetPaymentChannels,
					getUserData: GetUserData
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IPatreonPageComponentState => {
		return {} as IPatreonPageComponentState;
	};
}
export const PatreonPage = connect(
	PatreonPageContainer.mapStateToProps,
	PatreonPageContainer.mapDispatchToProps
)(PatreonPageContainer);
