import React from 'react';
import { List } from 'immutable';
import { AddOrUpdateArticle, Tag } from '../../../../client';
import { AddArticleItem } from './AddArticleItem';
import { FilePondFile } from 'filepond';

interface IAddArticleComponentProps {
	allTags: List<Tag>;
	addArticle: AddOrUpdateArticle;
	AddTag: (tagName: string) => void;
	addArticleImage: string | FilePondFile | null;
	AddArticleImageSaved: (addArticleImage: FilePondFile) => void;
	AddArticleContentSaved: (content: string) => void;
	AddArticleImageRemoved: () => void;
	AddArticleImageHadInitialSaved: (addArticleHadinitial: boolean) => void;
}

interface IAddArticleComponentState {}

export class AddArticleComponent extends React.Component<
	IAddArticleComponentProps,
	IAddArticleComponentState
> {
	constructor(props: IAddArticleComponentProps, state: IAddArticleComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <>{this.isListVisible() && <>{this.renderArticleSection()}</>}</>;
	}

	private renderArticleSection = (): JSX.Element => {
		if (!this.isListVisible() || !this.props.allTags) {
			return <></>;
		}
		return (
			<AddArticleItem
				addArticleImage={this.props.addArticleImage}
				allTags={this.props.allTags}
				addArticle={this.props.addArticle}
				AddTag={this.props.AddTag}
				AddArticleImageSaved={this.props.AddArticleImageSaved}
				AddArticleContentSaved={this.props.AddArticleContentSaved}
				AddArticleImageRemoved={this.props.AddArticleImageRemoved}
				AddArticleImageHadInitialSaved={this.props.AddArticleImageHadInitialSaved}
			/>
		);
	};

	private isListVisible = (): boolean => {
		return this.props.allTags && this.props.allTags != null;
	};

	private getInitialState = (): IAddArticleComponentState => {
		return {} as IAddArticleComponentState;
	};
}
