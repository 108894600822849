import 'react-quill/dist/quill.snow.css';

import { ArticleApi, FileApi } from '../../../client';
import ReactQuill, { Quill } from 'react-quill';

import { GetApiConfig } from '../../../business/api-client-config';
import React from 'react';
import { store } from '../../../business/root-store';
import wcmatch from 'wildcard-match';

const Delta = Quill.import('delta');
const BlockEmbed = Quill.import('blots/block/embed');
const Clipboard = Quill.import('modules/clipboard');

export type ICustomQuillProps = {
	value: string | typeof Delta | undefined;
	handleChange: (content: string) => void;
};

interface OEmbedData {
	html: string;
	height: number;
}
class OEmbedWrapper extends BlockEmbed {
	static create(data: OEmbedData) {
		const node = super.create();
		node.setAttribute('srcdoc', data.html);
		node.setAttribute('height', data.height);
		node.setAttribute('frameborder', '0');
		node.setAttribute('scrolling', 'no');
		node.setAttribute('allowfullscreen', true);
		return node;
	}

	static value(node: any) {
		return {
			html: node.getAttribute('srcdoc'),
			height: node.getAttribute('height')
		};
	}
}
OEmbedWrapper.blotName = 'oembed-wrapper';
OEmbedWrapper.tagName = 'iframe';
Quill.register(OEmbedWrapper, true);

interface IImageCustomBlot {
	src: string;
	alt: number;
}
class ImageCustomBlot extends BlockEmbed {
	static create(data: IImageCustomBlot) {
		const node = super.create();
		node.setAttribute('src', data.src);
		node.setAttribute('alt', data.alt);
		return node;
	}

	static value(node: any) {
		return {
			src: node.getAttribute('src'),
			alt: node.getAttribute('alt')
		};
	}
}
ImageCustomBlot.blotName = 'imageWrapper';
ImageCustomBlot.tagName = 'IMG';
Quill.register(ImageCustomBlot, true);

const postFileFromUrl = (text: string, fileApi: FileApi, range: any, quill: any) => {
	fileApi.postFileFromUrl({ url: text }).then((fileResponse) => {
		var uploaded = fileResponse?.data?.fileInfos?.find(
			(x) => x.fileType?.toString() == 'ModifiedImage'
		);
		var imageUrl = (process.env.REACT_APP_API_URL || '') + '/' + uploaded?.url;
		var alt = uploaded?.label + ' ' + uploaded?.score + '; ' + uploaded?.source;
		if (uploaded != undefined) {
			const deltaToReturn = new Delta()
				.retain(range.index)
				.delete(range.length)
				.insert({
					imageWrapper: { src: imageUrl, alt: alt }
				});
			('user');
			quill.updateContents(deltaToReturn);
			quill.setSelection(deltaToReturn.length() - range.length, 'silent');
		}
	});
};
function isBase64(str: string) {
	str = str.split(',').pop()!;
	const notBase64 = /[^A-Z0-9+\/=]/i;
	const len = str.length;
	if (!len || len % 4 !== 0 || notBase64.test(str)) {
		return false;
	}
	const firstPaddingChar = str.indexOf('=');
	return (
		firstPaddingChar === -1 ||
		firstPaddingChar === len - 1 ||
		(firstPaddingChar === len - 2 && str[len - 1] === '=')
	);
}
export default class CustomClipboard extends Clipboard {
	onPaste(e: any) {
		if (e.defaultPrevented || !this.quill.isEnabled()) return;
		const fileApi = new FileApi(GetApiConfig(store.getState()));
		const quill = quillObj?.getEditor();
		let range = this.quill.getSelection();
		let delta = new Delta().retain(range.index).delete(range.length);
		if (e && e.clipboardData && e.clipboardData.types && e.clipboardData.getData) {
			let text = (e.originalEvent || e).clipboardData.getData('text/plain');
			let html = (e.originalEvent || e).clipboardData.getData('text/html');
			//obrazek kopiuj
			if (html != null && text != undefined && html !== '' && text == '') {
				let cleanedHtml = this.convert(html);
				//gif
				if (typeof cleanedHtml.ops[0].insert === 'string') {
					postFileFromUrl(cleanedHtml.ops[0].insert, fileApi, range, quill);
				}
				//img
				else {
					postFileFromUrl(
						cleanedHtml.ops[0].insert.imageWrapper.src,
						fileApi,
						range,
						quill
					);
				}
			} else if (text != null && text != undefined) {
				if (isValidHttpUrl(text)) {
					testIsImageAsync(text).then(async (x) => {
						//obrazek z neta
						if (x) {
							postFileFromUrl(text, fileApi, range, quill);
						} else {
							let providers = await fetchJson('/providers.json');
							let apiUrl = null;
							for (const prov of providers) {
								for (const endpoint of prov['endpoints']) {
									if (endpoint.schemes && wcmatch(endpoint.schemes)(text)) {
										apiUrl = endpoint.url.replace('{format}', 'json');
										break;
									} else if (
										prov.provider_url &&
										text.startsWith(prov.provider_url)
									) {
										apiUrl = endpoint.provider_url;
										break;
									}
								}
							}
							//embed
							if (apiUrl != null) {
								const params = new URLSearchParams();
								params.set('url', text);
								const formatParam = '&format=json';
								const sizeParam = '&maxwidth=800&maxheight=600';
								apiUrl += '?' + params.toString();
								const articleApi = new ArticleApi(GetApiConfig(store.getState()));
								articleApi.oembed(apiUrl + formatParam + sizeParam).then((x) => {
									if (x.status == 200) {
										const deltaToReturn = new Delta()
											.retain(range.index)
											.delete(range.length)
											.insert({
												'oembed-wrapper': getOEmbedHeight(x.data)
											});
										('user');
										quill.updateContents(deltaToReturn);
										quill.setSelection(
											deltaToReturn.length() - range.length,
											'silent'
										);
									} else {
										delta = delta.concat(this.convert(text));
									}
								});
							}
							//inny link
							else {
								delta = delta.concat(this.convert(text));
							}
						}
					});
				}
				//obrazek base64
				else if (isBase64(text)) {
					postFileFromUrl(text, fileApi, range, quill);
				}
				//zwykly text
				else {
					delta = delta.concat(this.convert(text));
				}
			}
			e.stopPropagation();
			e.preventDefault();
			this.quill.updateContents(delta, 'user');
			this.quill.setSelection(delta.length() - range.length, 'silent');
			return false;
		}
	}
}
Quill.register('modules/clipboard', CustomClipboard);

const addImageToArticle = (imageBase64: string) => {
	const fileApi = new FileApi(GetApiConfig(store.getState()));

	const quill = quillObj.getEditor();
	const range = quill.getSelection(true);
	fileApi
		.postFileCatalog({ name: 'comments' })
		.then((x) => {
			return fileApi.postImageFile({
				catalogId: x.data.catalogId,
				name: 'dupa.jpg',
				resize: true,
				width: 700,
				height: 700,
				imageData: imageBase64,
				generateThumbminal: true,
				keepResizeRatio: true,
				thumbminalHeight: 300,
				thumbminalWidth: 300
			});
		})
		.then((u) => {
			const img = u.data.fileInfos!.find((x) => x.fileType?.toString() == 'ModifiedImage');
			var imageUrl = process.env.REACT_APP_API_URL + '/' + img?.url;
			var alt = img?.label + ' ' + img?.score + '; ' + img?.source;
			if (img != undefined) {
				const deltaToReturn = new Delta()
					.retain(range.index)
					.delete(range.length)
					.insert({
						imageWrapper: { src: imageUrl, alt: alt }
					});
				('user');
				quill.updateContents(deltaToReturn);
				quill.setSelection(deltaToReturn.length() - range.length, 'silent');
			}
		});
};

const imageHandler = (dataUrl: string, type?: string, imageData?: ImageData) => {
	if (imageData) {
		addImageToArticle(dataUrl.substring(dataUrl.indexOf(',') + 1));
	} else {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();
		input.onchange = async function () {
			const file = input.files![0];
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				addImageToArticle(
					(reader.result as string).substring((reader.result as string).indexOf(',') + 1)
				);
			};
		};
	}
};

const getOEmbedHeight = (data: any): OEmbedData => {
	const html = data.html.replace('<iframe', '<iframe style="width:100%;"');
	if (data.height && !data.height.toString().includes('%')) {
		return { html: html, height: data.height };
	} else if (data.html.includes('tiktok')) {
		return { html: html, height: 800 };
	} else if (data.html.includes('twitter')) {
		return { html: html, height: 600 };
	}
	return { html: html, height: 600 };
};

async function fetchJson(url: string) {
	const resp = await fetch(url);
	if (resp.status !== 200) {
		throw new Error('Non 200 response');
	}
	return await resp.json();
}

const isValidHttpUrl = (url: string) => {
	let url2;
	try {
		url2 = new URL(url);
	} catch (_) {
		return false;
	}
	return url2.protocol === 'http:' || url2.protocol === 'https:';
};

const testIsImageAsync = (url: string): Promise<boolean> => {
	return new Promise<boolean>((resolve) => {
		let image = new Image();
		image.onload = (e) => {
			resolve(true);
		};
		image.onerror = (e) => {
			resolve(false);
		};
		image.src = url;
	});
};

const modules = {
	toolbar: {
		container: [
			['bold', 'italic', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			['link', 'image']
		],
		handlers: {
			image: imageHandler
		}
	}
};

var quillObj: any;

export const CustomQuill: React.FC<ICustomQuillProps> = (props) => {
	const formats = [
		'link',
		'bold',
		'italic',
		'strike',
		'list',
		'image',
		'image',
		'video',
		'blockquote',
		'oembed-wrapper',
		'imageWrapper',
		'iframe'
	];
	return (
		<div onDrop={(e) => e.preventDefault()}>
			<ReactQuill
				ref={(el) => {
					quillObj = el;
				}}
				modules={modules}
				formats={formats}
				value={props.value || ''}
				placeholder="Opisz co się stało?"
				onChange={props.handleChange}
				theme="snow"
			/>
		</div>
	);
};
