import { List } from "immutable";
import * as React from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { AnyAction, Dispatch } from "redux";
import { RootState } from "../../../business/root-store";
import { FetchPatreons } from "../../../business/Static/StaticEpics";
import {  Patron } from "../../../client";
import { PatreonListPageComponent } from "./PatreonListPageComponent";
import { getPatreons } from "../../../business/Static/StaticSelectors";

interface IPatreonListPageContainerReduxMergedProps {
  patreons: List<Patron>;
}

interface IPatreonListPageContainerOwnProps {
}

interface IPatreonListPageContainerDispatchProps {
  getPatreons: () => void;
}

interface IPatreonListPageContainerProps
  extends IPatreonListPageContainerOwnProps,
    IPatreonListPageContainerDispatchProps,
    IPatreonListPageContainerReduxMergedProps {}

interface IPatreonListPageComponentState {}

class PatreonListPageContainer extends React.Component<
  IPatreonListPageContainerProps,
  IPatreonListPageComponentState
> {
  constructor(
    props: IPatreonListPageContainerProps,
    state: IPatreonListPageComponentState
  ) {
    super(props, state);
    this.state = this.getInitialState();

    this.props.getPatreons();
  }

  public render(): JSX.Element {
    return <PatreonListPageComponent  patreons={this.props.patreons} />;
  }

  public static mapStateToProps = (
    state: RootState,
    ownProps: IPatreonListPageContainerOwnProps
  ): IPatreonListPageContainerReduxMergedProps => {
    return {
      patreons: getPatreons(state.static)
    };
  };

  public static mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>
  ): IPatreonListPageContainerDispatchProps => {
    return {
      ...Redux.bindActionCreators(
        {
         getPatreons: FetchPatreons
        },
        dispatch
      ),
    };
  };

  private getInitialState = (): IPatreonListPageComponentState => {
    return {} as IPatreonListPageComponentState;
  };
}
export const PatreonListPage = connect(
  PatreonListPageContainer.mapStateToProps,
  PatreonListPageContainer.mapDispatchToProps
)(PatreonListPageContainer);
