import 'react-quill/dist/quill.snow.css';

import { Comment } from '../../../../client';
import { CustomQuill } from '../../CustomQuill/CustomQuill';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { addArticleComment } from '../../../../business/Article/ArticleEpics';
import { useDispatch } from 'react-redux';

export type IAddCommentProps = {
	commentLenghtLimit: number;
	parentCommentId?: number;
	replyState?: {
		reply: boolean;
		userId?: number | undefined;
		userName?: string | undefined;
		change?: boolean | undefined;
		commentToEdit?: Comment | undefined;
	};
	setReplyState?: React.Dispatch<
		React.SetStateAction<{
			reply: boolean;
			userId?: number | undefined;
			userName?: string | undefined;
			change?: boolean | undefined;
			commentToEdit?: Comment | undefined;
		}>
	>;
};

export const AddComment: React.FC<IAddCommentProps> = (props) => {
	const isReply = props.replyState?.userId != undefined ?? false;
	const [comment, setComment] = React.useState<{
		commentContent: string;
		commentLength: number;
		commentToEditId?: number;
	}>({ commentContent: '', commentLength: 1 });
	const dispatch = useDispatch();
	let commentInitial = '';
	let inputRef = React.createRef<HTMLInputElement>();
	React.useEffect(() => {
		if (isReply && props.replyState?.change && props.replyState?.commentToEdit == null) {
			setComment({
				...comment,
				commentContent: (commentInitial = '@' + props.replyState?.userName + '')
			});
			props.setReplyState!({
				reply: props.replyState.reply,
				userId: props.replyState.userId,
				userName: props.replyState.userName,
				change: false
			});
			inputRef.current!.focus();
		} else if (isReply && props.replyState?.change && props.replyState?.commentToEdit != null) {
			setComment({
				...comment,
				commentContent: props.replyState?.commentToEdit?.content!,
				commentToEditId: props.replyState.commentToEdit.id
			});
			props.setReplyState!({
				reply: props.replyState.reply,
				userId: props.replyState.userId,
				userName: props.replyState.userName,
				change: false
			});
			inputRef.current!.focus();
		}
	});

	const createRecord = (e: any) => {
		e.preventDefault();
		dispatch(
			addArticleComment(
				comment.commentContent,
				props.parentCommentId,
				comment.commentToEditId
			)
		);
		if (isReply) {
			props.setReplyState!({
				reply: false,
				userId: undefined,
				userName: undefined
			});
		}
		setComment({ ...comment, commentLength: 1, commentContent: '' });
	};
	const handleOnChange = (content: string) => {
		setComment({ ...comment, commentContent: content });
		// const characters = quillObj.getEditor().getLength();
		// if (characters <= props.commentLenghtLimit) {
		//   setComment({ ...comment, commentContent: e, commentLength: characters });
		// }
	};
	// const checkCharacterCount = (event: any) => {
	// 	const characters = quillObj.getEditor().getLength();
	// 	if (characters >= props.commentLenghtLimit && event.key !== 'Backspace') {
	// 		event.preventDefault();
	// 	}
	// };
	return (
		<div className="sh-add-comment">
			{isReply && (
				<>
					<Tooltip id="closeComment" title="Zamknij" placement="top">
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								props.setReplyState!({
									reply: false,
									userId: undefined,
									userName: undefined
								});
							}}
							className="sh-section__btn-cancel sh-btn-icon"
						>
							<i className="icon-Cancel"></i>
						</a>
					</Tooltip>
					<br />
					<br />
				</>
			)}
			<CustomQuill handleChange={handleOnChange} value={comment.commentContent} />

			<div className="sh-add-comment__content">
				<span>Pamiętaj o zachowaniu kultury 😇</span>
				<input ref={inputRef} className="disabled" />
				<div className="sh-add-comment__btn-save sh-btn-icon">
					<span>
						{comment.commentLength}/{props.commentLenghtLimit}
					</span>
					<i className="icon-Submit_Tick"></i>
				</div>
			</div>
			<a href="#" className="sh-btn" onClick={(e) => createRecord(e)}>
				Dodaj
			</a>
		</div>
	);
};
