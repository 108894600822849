import { Link } from 'react-router-dom';
import { MenuFooter } from '../MenuFooter/MenuFooter';
import React from 'react';
import { routes } from '../../../../business/router/path-definitions';

export const NotLoggedMenu: React.FC = () => {
	return (
		<>
			<Link className="sh-btn" to={routes.login}>
				<i className="icon-Following"></i>Logowanie
			</Link>
			<Link className="sh-btn" to={routes.register}>
				<i className="icon-Followers"></i>Rejestracja
			</Link>
			<Link to={routes.patreonList} className="sh-btn">
				<span className="icon-Cool emoticon">
					<span className="path1"></span>
					<span className="path2"></span>
					<span className="path3"></span>
					<span className="path4"></span>
					<span className="path5"></span>
				</span>
				Patroni
			</Link>
			<MenuFooter />
		</>
	);
};
