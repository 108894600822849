import React from "react";
import { IAuthReduxState } from "../../../../business/Auth/AuthReducer";
import { Comment } from "../../../../client";
import { CommentListItem } from "./CommentListItem";

interface ICommentListComponentProps {
  comments: Comment[];
  onUpVote: (statisticsId: number, currentLike: boolean) => void;
  onDownVote: (statisticsId: number, currentDislike: boolean) => void;
  auth: IAuthReduxState;
}

interface ICommentListComponentState {}

export class CommentListComponent extends React.Component<
  ICommentListComponentProps,
  ICommentListComponentState
> {
  constructor(
    props: ICommentListComponentProps,
    state: ICommentListComponentState
  ) {
    super(props, state);

    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return (
      <>{this.isListVisible() && <>{this.renderCommentListSection()}</>}</>
    );
  }

  private renderCommentListSection = (): JSX.Element => {
    if (!this.isListVisible() || !this.props.comments) {
      return <></>;
    }
    return (
      <div className="sh-comments sh-section">
        <div className="sh-comments__title sh-comments__head">
          {this.props.comments.length} komentarzy
        </div>
        {/* <div className="sh-comments__options">
            <span>Sort by:</span>
            <a href="#" className="sh-comments__btn-image sh-btn-icon">
              <i className="icon-Image"></i>
            </a>
            <a href="#" className="sh-comments__btn-upvote sh-btn-icon">
              <i className="icon-Upvote"></i>
            </a>
            <a href="#" className="sh-comments__btn-down sh-btn-icon">
              <i className="icon-Downvote"></i>
            </a>
            <a href="#" className="sh-comments__btn-expand sh-btn-icon">
              <i className="icon-Expand_all"></i>
            </a>
          </div> */}
        {this.props.comments
          .filter((x) => x.parentCommentId == null)
          .map((comment: Comment) => {
            return (
              <CommentListItem
                comment={comment}
                childsComments={this.props.comments.filter(
                  (x) => x.parentCommentId === comment.id
                )}
                upVote={this.handleUpVote}
                downVote={this.handleDownVote}
                auth={this.props.auth}
              />
            );
          })}
      </div>
    );
  };

  private handleUpVote = (statisticsId: number, currentLike: boolean): void => {
    this.props.onUpVote(statisticsId, currentLike);
  };

  private handleDownVote = (
    statisticsId: number,
    currentDislike: boolean
  ): void => {
    this.props.onDownVote(statisticsId, currentDislike);
  };

  private isListVisible = (): boolean => {
    return this.props.comments && this.props.comments != null;
  };

  private getInitialState = (): ICommentListComponentState => {
    return {} as ICommentListComponentState;
  };
}
