import React from "react";
import { useDispatch } from "react-redux";
import {
  nextArticle,
  previousArticle,
} from "../../../business/Article/ArticleEpics";

export default function PreviousNextArticle() {
  const dispatch = useDispatch();
  const navigateToPreviousArticle = (event: any) => {
    event.preventDefault();
    dispatch(previousArticle());
  };
  const navigateToNextArticle = (event: any) => {
    event.preventDefault();
    dispatch(nextArticle());
  };
  return (
    <div className="sh-content-head__navigation">
      <a
        href="#"
        className="sh-content-head__btn-prev sh-btn-icon"
        onClick={(e) => navigateToPreviousArticle(e)}
      >
        <i className="icon-Arrow_Left"></i>
        <span>Poprzedni post</span>
      </a>
      <a
        href="#"
        className="sh-content-head__btn-next sh-btn-icon"
        onClick={(e) => navigateToNextArticle(e)}
      >
        <span>Następny post</span>
        <i className="icon-Arrow_Right"></i>
      </a>
    </div>
  );
}
