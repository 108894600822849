import { BottomNavigation } from "@material-ui/core";
import { List } from "immutable";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { ArticleListItem } from "../../../../client";
import { StyledBottomNavigationAction } from "../../StyledMaterialUiComponents/StyledMaterialUiComponents";
import { ArticleItem } from "../shared/ArticleItem";

interface IArticleListComponentProps {
  articleList: List<ArticleListItem>;
  hasMoreToLoad: boolean;
  onItemRemoved: (id: number) => void;
  onUpVote: (statisticsId: number, currentLike: boolean) => void;
  onDownVote: (statisticsId: number, currentDislike: boolean) => void;
  loadArticles: (
    id: number,
    userIdFilter?: number,
    tagIdFilter?: number
  ) => void;
  onFetchMoreData: (userIdFilter?: number, tagIdFilter?: number) => void;
  tagId?: number;
  userId?: number;
  tagName?: string;
  othersArticles?: boolean;
}

interface IArticleListComponentState {
  index: number;
}

export class ArticleListComponent extends React.Component<
  IArticleListComponentProps,
  IArticleListComponentState
> {
  constructor(
    props: IArticleListComponentProps,
    state: IArticleListComponentState
  ) {
    super(props, state);

    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return <>{this.isListVisible() && <> {this.renderArticleSection()}</>}</>;
  }
  private renderArticleSection = (): JSX.Element => {
    if (!this.isListVisible() || !this.props.articleList) {
      return <></>;
    }
    const breakpoints = {
      default: 2,
      992: 1,
    };
    return (
      <div>
        <div className="sh-section-menu">
          {this.props.tagId && (
            <h3>{'Przeglądasz tag "' + this.props.tagName + '"'}</h3>
          )}
          <BottomNavigation
            value={this.state.index}
            onChange={(event, newValue) => {
              this.setState({ index: newValue });
              this.props.loadArticles(
                newValue,
                this.props.userId,
                this.props.tagId
              );
            }}
            showLabels
          >
            <StyledBottomNavigationAction label="Najnowsze" />
            <StyledBottomNavigationAction label="Najczęściej oceniane" />
            <StyledBottomNavigationAction label="Najczęściej komentowane" />
          </BottomNavigation>
        </div>
        <InfiniteScroll
          dataLength={this.props.articleList.count()}
          next={() =>
            this.props.onFetchMoreData(this.props.userId, this.props.tagId)
          }
          hasMore={this.props.hasMoreToLoad}
          loader={null}
        >
          <Masonry
            breakpointCols={
              this.props.othersArticles ? { default: 1 } : breakpoints
            }
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {this.props.articleList.map((article: ArticleListItem) => (
              <div key={article.id}>
                <ArticleItem
                  article={article}
                  removeArticle={this.props.onItemRemoved}
                  upVote={this.props.onUpVote}
                  downVote={this.props.onDownVote}
                />
              </div>
            ))}
          </Masonry>
        </InfiniteScroll>
      </div>
    );
  };

  private isListVisible = (): boolean => {
    return this.props.articleList && this.props.articleList.count() > 0;
  };

  private getInitialState = (): IArticleListComponentState => {
    return { index: 0 } as IArticleListComponentState;
  };
}
