import * as React from 'react';
import * as Redux from 'redux';

import { AnyAction, Dispatch } from 'redux';
import {
	UserDataDescriptionSaved,
	UserDataImageCounterRemoved,
	UserDataImageHadInitialSaved,
	UserDataImageRemoved,
	UserDataImageSaved
} from '../../../../business/Profile/ProfileActions';
import {
	getUserDataImageSelector,
	getUserDataSelector
} from '../../../../business/Profile/ProfileSelectors';

import { EditProfileComponent } from './EditProfileComponent';
import { FilePondFile } from 'filepond';
import { RootState } from '../../../../business/root-store';
import { User } from '../../../../client';
import { connect } from 'react-redux';

interface IEditProfileContainerReduxMergedProps {
	userData: User;
	userDataImage: string | FilePondFile | null;
}

interface IEditProfileContainerOwnProps {}

interface IEditProfileContainerDispatchProps {
	UserDataImageSaved: (image: string | FilePondFile | null) => void;
	UserDataDescriptionSaved: (description: string) => void;
	UserDataImageRemoved: () => void;
	UserDataImageCounterRemoved: () => void;
	UserDataImageHadInitialSaved: (userDataHadinitial: boolean) => void;
}

interface IEditProfileContainerProps
	extends IEditProfileContainerOwnProps,
		IEditProfileContainerDispatchProps,
		IEditProfileContainerReduxMergedProps {}

interface IEditProfileComponentState {}

class EditProfileContainer extends React.Component<
	IEditProfileContainerProps,
	IEditProfileComponentState
> {
	constructor(props: IEditProfileContainerProps, state: IEditProfileComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<EditProfileComponent
				userData={this.props.userData}
				userDataImage={this.props.userDataImage}
				UserDataDescriptionSaved={this.props.UserDataDescriptionSaved}
				UserDataImageSaved={this.props.UserDataImageSaved}
				UserDataImageRemoved={this.props.UserDataImageRemoved}
				UserDataImageCounterRemoved={this.props.UserDataImageCounterRemoved}
				UserDataImageHadInitialSaved={this.props.UserDataImageHadInitialSaved}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IEditProfileContainerOwnProps
	): IEditProfileContainerReduxMergedProps => {
		return {
			userData: getUserDataSelector(state.profile),
			userDataImage: getUserDataImageSelector(state.profile)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IEditProfileContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					UserDataDescriptionSaved: UserDataDescriptionSaved,
					UserDataImageSaved: UserDataImageSaved,
					UserDataImageRemoved: UserDataImageRemoved,
					UserDataImageCounterRemoved: UserDataImageCounterRemoved,
					UserDataImageHadInitialSaved: UserDataImageHadInitialSaved
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IEditProfileComponentState => {
		return {} as IEditProfileComponentState;
	};
}
export const EditProfile = connect(
	EditProfileContainer.mapStateToProps,
	EditProfileContainer.mapDispatchToProps
)(EditProfileContainer);
