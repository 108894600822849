import { TextField } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/icons/Menu';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from '../../../assets/jss/material-kit-react/components/headerStyle';
import { routes } from '../../../business/router/path-definitions';
import { navigationAction } from '../../../business/router/router-actions';
import { ArticleApi, Configuration, SearchResult } from '../../../client';
import HeaderRightLinks from './HeaderRightLinks';

const useStyles = makeStyles(styles);
const WhiteTextField = withStyles({
	root: {
		'& .MuiInputBase-input': {
			color: '#fff' // Text color
		},
		'& .MuiInput-underline:before': {
			borderBottomColor: '#fff8' // Semi-transparent underline
		},
		'& .MuiInput-underline:hover:before': {
			borderBottomColor: '#fff' // Solid underline on hover
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#fff' // Solid underline on focus
		}
	}
})(TextField);
export const Header: React.FC = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const headerColorChange = () => {
		const windowsScrollTop = window.scrollY;
		if (windowsScrollTop > 100) {
			document.body.getElementsByTagName('header')[0].classList.add(classes.white);
			document.getElementsByName('searchInput')[0].classList.add(classes.blackInput);
			document
				.getElementsByName('searchInput')[0]
				.parentElement!.classList.add(classes.underlineBlack);
		} else {
			document.body.getElementsByTagName('header')[0].classList.remove(classes.white);
			document.getElementsByName('searchInput')[0].classList.remove(classes.blackInput);
			document
				.getElementsByName('searchInput')[0]
				.parentElement!.classList.remove(classes.underlineBlack);
		}
	};
	window.addEventListener('scroll', headerColorChange);

	const [options, setOptions] = React.useState<SearchResult[]>([]);

	const handleOnInputChange = (event: any, value: string) => {
		if (value.length > 1) {
			let apiClient = new Configuration({
				basePath: process.env.REACT_APP_API_URL
			});
			const api = new ArticleApi(apiClient);
			api.getSearchResultsResponse(value).then((x) => {
				setOptions(x.data.searchResults!);
			});
		}
	};

	const handleOnChange = (event: any, value: SearchResult) => {
		switch (value.typeString) {
			case 'Tagi':
				dispatch(navigationAction.open('/tag/' + value.id + '/' + value.content));
				break;
			case 'Artykuły':
				dispatch(navigationAction.open('/article/' + value.id + '/' + value.content));
				break;

			default:
				break;
		}
	};

	const brandComponent = (
		<>
			<Link className={classes.title} to={routes.home}>
				<Button className={classes.title}>Dramki.pl 😱</Button>
			</Link>
		</>
	);
	const searchComponent = (
		<Autocomplete
			noOptionsText="Brak wyników"
			className={classes.autocomplete}
			options={options.sort((a, b) => -b.typeString!.localeCompare(a.typeString!))}
			groupBy={(option) => option.typeString!}
			getOptionLabel={(option) => option.content!.toLocaleString()}
			onInputChange={handleOnInputChange}
			onChange={(e, value) => {
				handleOnChange(e, value!);
			}}
			renderInput={(params) => (
				<div ref={params.InputProps.ref}>
					<WhiteTextField
						name="searchInput"
						placeholder="Wyszukaj"
						fullWidth
						inputProps={{ ...params.inputProps }}
					/>
				</div>
			)}
		/>
	);

	return (
		<AppBar className={classes.appBar}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
					<Toolbar
						disableGutters
						variant="dense"
						className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12"
					>
						{brandComponent}
						{searchComponent}
						<div className="d-md-none">
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={() => {
									setMobileOpen(!mobileOpen);
								}}
							>
								<Menu />
							</IconButton>
							<Drawer
								variant="temporary"
								anchor={'right'}
								open={mobileOpen}
								classes={{
									paper: classes.drawerPaper
								}}
								onClose={() => {
									setMobileOpen(!mobileOpen);
								}}
							>
								<div className={classes.appResponsive}>
									{props.children}
									<HeaderRightLinks />
								</div>
							</Drawer>
						</div>
					</Toolbar>
					<div className="col-xl-3 col-lg-3 col-md-4 d-none d-md-block">
						<HeaderRightLinks />
					</div>
				</div>
			</div>
		</AppBar>
	);
};
