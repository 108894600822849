import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import appHistory from '../app-history';
import { StateType } from 'typesafe-actions';
import { ArticleListReducer } from '../business/Article/ArticleReducer';
import { StaticReducer } from './Static/StaticReducer';
import thunk from 'redux-thunk';
import { authReducer } from './Auth/AuthReducer';
import { ProfileReducer } from './Profile/ProfileReducer';
// import { settingsReducer } from './customer/customer-settings-reducer';
// import { dietReducer } from './diet/diet-reducer';
// import { dietPreferencesReducer } from './diet/diet-preferences-reducer';

export const rootReducer = combineReducers({
	auth: authReducer,
	profile: ProfileReducer,
	// settings: settingsReducer,
	// dietPreferences: dietPreferencesReducer,
	// diet: dietReducer,
	article: ArticleListReducer,
	static: StaticReducer,
	router: connectRouter(appHistory),
	toastr: toastrReducer
});

export const store = configureStore({
	devTools: true,
	reducer: rootReducer,
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: false
		}),
		routerMiddleware(appHistory),
		thunk
	]
});

export type RootState = StateType<typeof rootReducer>;
