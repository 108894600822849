import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/ArticlePage";
import { Header } from "../../molecules/Header/Header";
const useStyles = makeStyles(styles);

export default function TermsAndConditions() {
  const classes = useStyles();
  return (
    <>
      <Header>{/* faqmenu */}</Header>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url('/assets/img/bg7.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      ></div>
      <div className={classNames(classes.mainRaised)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="sh-content-head sh-content-head__flex-off">
                <div className="row">
                  <div className="sh-section">
                    <div className="sh-section__head">
                      <h1>Regulamin użytkownika konta w serwisie dramki.pl</h1>
                    </div>
                    <div className="sh-section__content">
                      <div className="sh-section__text">
                        <main role="main">
                          <h3>I. Określenia i definicje</h3>
                          <p>
                            Ilekroć w niniejszym Regulaminie stosuje się
                            poniższe określenia i definicje, należy je rozumieć
                            jak następuje:
                          </p>
                          <ul>
                            <li>
                              <span className="fw-bold">Regulamin</span>{" "}
                              „Regulamin użytkownika konta w Serwisie dramki.pl”
                              stanowiący regulamin w rozumieniu w rozumieniu
                              ustawy z dnia 18 lipca 2002 roku o świadczeniu
                              usług drogą elektroniczną (Dz. U. nr 144 poz.
                              1204), określający zasady świadczenia usług drogą
                              elektroniczną przez Tailored Apps na rzecz
                              Użytkowników prowadzonego przez nią Serwisu
                              Dramki, obejmujący zasady udostępniania,
                              zakładania i wykorzystania bezpłatnego
                              indywidualnego konta Użytkownika.
                            </li>
                            <li>
                              <span className="fw-bold">Serwis</span> Dramki.pl
                              Serwis internetowy prowadzony przez Tailored Apps
                              pod aktualnym adresem URL: https://www.dramki.pl
                              oraz pod adresami będącymi przekierowaniami do
                              tego adresu, a także każdym innym będącym jego
                              uzupełnieniem lub kontynuacją, a także każdym,
                              który go zastąpi.
                            </li>
                            <li>
                              <span className="fw-bold">Tailored Apps</span>{" "}
                              Tailored Apps Łukasz Kowalski, z siedzibą w Łodzi
                              przy ulicy Wólczańska 125 prowadzony jest przez
                              Łukasza Kowalskiego prowadzącego działalność
                              gospodarczą pod firmą, wpisaną do Centralnej
                              Ewidencji i Informacji o Działalności Gospodarczej
                              (CEIDG) prowadzonej przez ministra właściwego ds.
                              gospodarki, NIP: 728 261 44 83.
                            </li>
                            <li>
                              <span className="fw-bold">Użytkownik</span> Osoba
                              fizyczna korzystająca z bezpłatnego indywidualnego
                              konta Użytkownika Serwisu Dramki.pl.
                            </li>
                          </ul>
                          <h3>
                            II. Zasady zakładania i korzystania z konta
                            Użytkownika.
                          </h3>
                          <p>Dane osobowe są pozyskiwane poprzez:</p>
                          <ol>
                            <li>
                              Konta w Serwisie Dramki.pl udostępniane są
                              bezpłatnie.
                            </li>
                            <li>
                              Użytkownikiem konta może zostać osoba pełnoletnia.
                              Osoba niepełnoletnia może zostać Użytkownikiem
                              konta za zgodą swoich rodziców lub opiekunów
                              prawnych.
                            </li>
                            <li>
                              Użytkownik uzyskuje możliwość korzystania z
                              bezpłatnego konta w Serwisie Dramki.pl
                              automatycznie po zaakceptowaniu treści Regulaminu
                              oraz wypełnieniu i wysłaniu formularza
                              rejestracyjnego.
                            </li>
                            <li>
                              Powiadomienia dotyczące korzystania z konta będą
                              przesyłane na adres poczty elektronicznej podanej
                              przez Użytkownika w formularzu rejestracyjnym.
                              Użytkownik jest wyłącznie odpowiedzialny za
                              podanie aktualnego i poprawnego adresu e-mail.
                            </li>
                            <li>
                              Tailored Apps nie kontroluje, ani nie weryfikuje
                              danych identyfikacyjnych użytkownika zawartych w
                              formularzu rejestracyjnym.
                            </li>
                            <li>
                              Wysłanie wypełnionego formularza rejestracyjnego
                              przez Użytkownika oznacza jego zgodę na
                              gromadzenie, przetwarzanie i wykorzystywanie
                              zawartych w nim danych osobowych przez Tailored
                              Apps w celu świadczenia dostępnych na stronach
                              Serwisu Dramki.pl usług oraz w celach
                              statystycznych, zgodnie z ustawami z dnia 18 lipca
                              2002 roku o świadczeniu usług drogą elektroniczną
                              (Dz. U. Nr 144 poz.1204) oraz z 29 sierpnia 1997
                              roku o ochronie danych osobowych (Dz. U. nr 133,
                              poz. 883).
                            </li>
                            <li>
                              Tailored Apps zapewnia każdemu Użytkownikowi konta
                              dostęp do dotyczących go danych w celu ich
                              weryfikacji lub modyfikacji. Tailored Apps
                              zobowiązuje się dołożyć wszelkich starań w celu
                              ochrony danych Użytkowników kont.
                            </li>
                            <li>
                              Użytkownik jest zobowiązany do korzystania z konta
                              w sposób zgodny z obowiązującym prawem, normami
                              społecznymi i obyczajowymi oraz postanowieniami
                              Regulaminu.
                            </li>
                            <li>
                              Użytkownik ponosi wyłączną odpowiedzialność za
                              sposób, w jaki wykorzystuje bezpłatne konto w
                              Serwisie Dramki.pl, w tym za zawartość i formę
                              zamieszczanych treści, z uwzględnieniem
                              odpowiedzialności karnej lub cywilnej za działania
                              niezgodne z prawem. Na żądanie uprawnionych
                              organów państwa, na potrzeby prowadzonych przez
                              nie postępowań, Tailored Apps przekazuje
                              pozostające w jego posiadaniu dane Użytkowników
                              kont Serwisu Dramki.pl.
                            </li>
                            <li>
                              Tailored Apps nie ponosi odpowiedzialności za
                              skutki wynikłe z ujawnienia przez Użytkownika
                              hasła do konta osobom trzecim niezależnie od
                              przyczyny ujawnienia.
                            </li>
                            <li>
                              Tailored Apps nie ponosi odpowiedzialności za
                              wszelkie straty i szkody, w tym utratę danych,
                              wynikające z awarii, przerw w funkcjonowaniu
                              systemu z przyczyn technicznych lub z przyczyn
                              niezależnych od Tailored Apps.
                            </li>
                            <li>
                              Tailored Apps zastrzega sobie prawo do zamknięcia
                              konta Użytkownika, gdy:
                              <ul>
                                <li>
                                  Użytkownik wykorzystuje konto niezgodnie z
                                  postanowieniami obowiązującego prawa lub
                                  Regulaminu,
                                </li>
                                <li>
                                  nazwa konta narusza prawo, prawem chronione
                                  dobra osób trzecich, jest niezgodna z dobrymi
                                  obyczajami, w tym jest wyrażeniem powszechnie
                                  uznanym za obraźliwe.
                                </li>
                              </ul>
                            </li>
                            <li>
                              Użytkownikowi przysługuje prawo do zamknięcia
                              założonego w Serwisie Dramki.pl konta w każdym
                              czasie. Żądanie zamknięcia konta należy nadesłać
                              na adres e-mail: contact@tailoredapps.pl
                            </li>
                            <li>
                              Po zamknięciu konta Tailored Apps jest uprawniony
                              do umożliwienia innym Użytkownikom używania loginu
                              należącego do Użytkownika zamkniętego konta.
                            </li>
                            <li>
                              Tailored Apps zastrzega sobie prawo do
                              zaprzestania udostępniania serwisu bezpłatnych
                              kont bez podania przyczyn po uprzednim
                              poinformowaniu Użytkowników o podjęciu takiej
                              decyzji.
                            </li>
                            <li>
                              Tailored Apps nie ponosi odpowiedzialności za
                              niemożność dostępu do Serwisu Dramki.pl
                              spowodowaną okolicznościami, których nie można
                              uniknąć bez istotnych dodatkowych kosztów lub z
                              przyczyn leżących po stronie osób trzecich jak
                              również spowodowanych działaniami siły wyższej.
                            </li>
                            <li>
                              Tailored Apps jest uprawniona do czasowej przerwy
                              w dostępie do Serwisu Dramki.pl z przyczyn
                              technicznych. Tailored Apps dołoży najwyższej
                              staranności, aby wskazane wyżej przerwy miały
                              miejsce w godzinach nocnych i trwały możliwie
                              najkrócej.
                            </li>
                          </ol>
                          <h3>
                            III.Zasady publikacji treści w Serwisie Dramki.pl.
                          </h3>
                          <ol>
                            <li>
                              Użytkownik ma prawo do nieodpłatnego publikowania
                              komentarzy i treści w Serwisie Dramki.pl w celu
                              wymiany informacji i opinii na różnorodne tematy.
                            </li>
                            <li>
                              Użytkownik Serwisu Dramki.pl publikuje swoje
                              komentarze i treści wyłącznie na własną
                              odpowiedzialność. Tailored Apps nie ponosi
                              jakiejkolwiek odpowiedzialności za treści
                              zamieszczane przez Użytkowników na łamach Serwisu
                              Dramki.pl.
                            </li>
                            <li>
                              Niedopuszczalne jest umieszczanie przez
                              Użytkowników na łamach Serwisu Dramki.pl treści
                              sprzecznych z prawem, wzywających do nienawiści
                              rasowej, wyznaniowej, etnicznej, czy też
                              propagujących przemoc, uznanych powszechnie za
                              naganne moralnie, społecznie niewłaściwe.
                            </li>
                            <li>
                              Niedopuszczalne jest również umieszczanie przez
                              Użytkowników na łamach Serwisu Dramki.pl przekazów
                              o charakterze reklamowym.
                            </li>
                            <li>
                              Tailored Apps zastrzega sobie prawo do usuwania
                              treści, o których mowa w ustępie 3 i 4 niniejszego
                              paragrafu, jak również prawo do zamknięcia konta
                              Użytkownika, naruszającego w sposób notoryczny
                              postanowienia ustępu 3 i 4 niniejszego paragrafu.
                              Użytkownik zamieszczając komentarz na stronie
                              Serwisu Dramki.pl przyjmuje do wiadomości, że
                              ewentualne usunięcie przez Tailored Apps
                              zamieszczonego przez niego komentarza jest
                              równoznaczne z powiadomieniem go o zamiarze
                              usunięcia komentarza ze względu na naruszenie
                              postanowień ustępu 3 i 4 niniejszego paragrafu lub
                              ze względu na wpłynięcie urzędowego zawiadomienia
                              lub uzyskanie przez Tailored Apps wiarygodnej
                              wiadomości o bezprawnym charakterze zamieszczonego
                              komentarza.
                            </li>
                            <li>
                              Tailored Apps zastrzega sobie prawo do
                              zaprzestania umożliwiania Użytkownikom
                              publikowania komentarzy w Serwisie Dramki.pl bez
                              podawania przyczyn takiego działania.
                            </li>
                            <li>
                              Użytkownicy poprzez umieszczenie swoich komentarzy
                              i treści w Serwisie Dramki.pl wyrażają zgodę na
                              publikowanie tych treści w Serwisie Dramki.pl oraz
                              w innych serwisach internetowych, a także w innych
                              mediach wydawanych przez Tailored Apps.
                            </li>
                            <li>
                              Użytkownicy przekazujący treść do serwisu
                              Dramki.pl oświadczają że mają prawo do
                              publikowanych przez nich: treści, grafik,
                              materiałów wideo i innych mediów. Oraz przekazują
                              prawa portalowi Dramki.pl do dalszego posługiwania
                              się treścią w serwisie oraz w innych mediach
                              wydawanych przez Tailored Apps.
                            </li>
                            <li>
                              Treści które naruszają prawa własności oraz nie
                              należą do autora bądź są niezgodne z regulaminem
                              można zgłośić do administracji portalu w celu
                              weryfikacji treści.
                            </li>
                            <li>
                              Treści które naruszają prawa własności oraz nie
                               są zgodne z regulaminem portal ma prawo usunąć, a użytkownika który nie stosuje się 
                            </li>
                          </ol>
                          <h3>IV. Reklamacje</h3>
                          <ol>
                            <li>
                              Reklamacje na podstawie Regulaminu powinny być
                              zgłaszane niezwłocznie pisemnie na adres
                              contact@tailoredapps.pl . Reklamacje powinny
                              zawierać co najmniej następujące dane:
                              <ul>
                                <li>
                                  a) oznaczenie podmiotu składającego reklamację
                                  (imię i nazwisko/nazwa, adres, adres poczty
                                  elektronicznej);
                                </li>
                                <li>b) przedmiot reklamacji;</li>
                                <li>
                                  c) wszystkie okoliczności uzasadniające
                                  reklamację.
                                </li>
                              </ul>
                            </li>
                            <li>
                              Reklamacje nie zawierające powyższych danych nie
                              będą rozpatrywane.
                            </li>
                            <li>
                              Reklamacje rozpatrywać będzie Serwis dramki.pl w
                              terminie 30 (trzydziestu) dni od dnia ich
                              otrzymania.
                            </li>
                            <li>
                              Decyzja Serwisu dramki.pl w sprawie reklamacji będzie
                              ostateczna.
                            </li>
                          </ol>
                          <h3>V.Postanowienia końcowe.</h3>
                          <ol>
                            <li>
                              Regulamin obowiązuje od dnia 01.12.2021 roku.
                            </li>
                            <li>
                              Tailored Apps zastrzega sobie prawo do
                              wprowadzania zmian w Regulaminie.
                            </li>
                            <li>
                              Z zastrzeżeniem postanowień ustępu 4 niniejszego
                              paragrafu oraz pod warunkiem nie złożenia przez
                              Użytkownika oświadczenia tam wskazanego, wszelkie
                              zmiany obowiązują od dnia wskazanego w zmienionym
                              Regulaminie, który to dzień nie może jednak
                              przypadać wcześniej niż na dzień opublikowania
                              zmienionego Regulaminu na stronie Serwisu
                              Dramki.pl.
                            </li>
                            <li>
                              W terminie 7 dni od dnia opublikowania zmienionego
                              Regulaminu Użytkownik może złożyć pisemnie na
                              adres: contact@tailoredapps.pl oświadczenie o
                              braku zgody na obowiązywanie w stosunku do niego
                              zmienionej treści Regulaminu - takie oświadczenie
                              traktowane będzie jako wypowiedzenie przez
                              Użytkownika wiążącego Tailored Apps i Użytkownika
                              stosunku prawnego będącego podstawą świadczenia
                              usług, o których mowa w Regulaminie.
                            </li>
                            <li>
                              W przypadku złożenia oświadczenia, o którym mowa w
                              ustępie 4 niniejszego paragrafu Tailored Apps
                              zakończy świadczenie usług w stosunku do danego
                              Użytkownika w terminie 7 dni roboczych od dnia
                              złożenia przez Użytkownika oświadczenia.
                            </li>
                            <li>
                              Rozwiązanie bądź wygaśnięcie wiążącego Tailored
                              Apps i Użytkownika stosunku prawnego pozostaje bez
                              skutku dla praw nabytych przez Tailored Apps w
                              trybie określonym w § 3 ustęp 7 Regulaminu.
                            </li>
                            <li>
                              W sprawach nieuregulowanych w Regulaminie
                              zastosowanie mają odpowiednie przepisy Kodeksu
                              Cywilnego, ustawy z dnia 18 lipca 2002 roku o
                              świadczeniu usług drogą elektroniczną (Dz.U. nr
                              144 poz. 1204) oraz ustawy o prawie autorskim i
                              prawach pokrewnych (Dz.U. 1994 Nr 24 poz. 83).
                            </li>
                            <li>
                              Tailored Apps ma prawo emisji reklam wszelkiego
                              rodzaju produktów i usług w trakcie prezentacji
                              komentarzy zamieszczonych w Serwisie Dramki.pl
                              przez Użytkowników. Użytkownikom nie przysługuje
                              jakiekolwiek wynagrodzenie z tytułu publikacji
                              reklam na stronach Serwisu Dramki.pl.
                            </li>
                          </ol>
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="sh-section">
                    <div className="sh-section__head">
                      <main role="main">
                        <h1>Polityka Prywatności</h1>
                        <p>
                          Poniższy dokument stanowi opis polityki prywatności i
                          wykorzystywania plików cookies przez Tailored Apps.
                          Łukasz Kowalski z siedzibą przy ul. Wólczańska 125
                          90-521 Łódź (Administrator). Określa zasady oraz
                          sposób przetwarzania oraz wykorzystywania danych i
                          informacji pochodzących od klientów i użytkowników
                          serwisu internetowego. Prosimy o dokładne zapoznanie
                          się z treścią niniejszej polityki. Wchodząc na tę
                          stronę lub korzystając z niej i przesyłając nam
                          jakichkolwiek dane osobowe użytkownik akceptuje
                          warunki niniejszej polityki prywatności.
                        </p>
                        <h3>I. Zasady ochrony danych osobowych</h3>
                        <ul>
                          <li>
                            Tailored Apps. Łukasz Kowalski dokłada szczególnej
                            staranności, aby wszelkie dane osobowe były
                            przetwarzane zgodnie z celem, dla których zostały
                            zebrane i wykorzystywane stosownie do zakresu
                            udzielonych zezwoleń (zgód) oraz obszarów
                            przetwarzania dozwolonych prawem.
                          </li>
                          <li>
                            Wszystkie dane osobowe przetwarzane są w sposób
                            zgodny z wymogami powszechnie obowiązującego prawa,
                            w szczególności w zgodzie z Rozporządzeniem
                            Parlamentu Europejskiego i Rady (UE) 216/679 z dnia
                            27 kwietnia 2016 r. w sprawie ochrony osób
                            fizycznych w związku z przetwarzaniem danych
                            osobowych i w sprawie swobodnego przepływu takich
                            danych oraz uchylenia dyrektywy 95/46/WE (RODO) ,
                            ustawą z dnia 10 maja 2018 r. o ochronie danych
                            osobowych (Dz. U. z 2018 r., poz. 1000), ustawą z
                            dnia 18 lipca 2002 r. o świadczeniu usług drogą
                            elektroniczną (t.j. Dz. U. z 2017 r., poz. 1219 ze
                            zm.) oraz ustawą prawo telekomunikacyjne ( t.j. Dz.
                            U. z 2017 r. poz. 1907 ze zm.), w tym zgodnie z
                            zakresem udzielonej przez klienta zgody.
                          </li>
                          <li>
                            Dbamy o to, aby przetwarzanie danych osobowych
                            odbywało się zgodnie z zasadami dotyczącymi
                            merytorycznej poprawności oraz adekwatnie do celów w
                            jakich zostały zebrane.
                          </li>
                          <li>
                            Przetwarzamy dane przez okres niezbędny do
                            osiągnięcia celów, dla których zostały zebrane, a
                            dodatkowo przez okres w jakim jesteśmy do tego
                            zobowiązani na podstawie powszechnie obowiązujących
                            przepisów prawa.
                          </li>
                          <li>
                            Zapewniamy poufność, integralność oraz rozliczalność
                            przetwarzanych przez nas danych osobowych.
                          </li>
                          <li>
                            Przetwarzane dane osobowe nie są udostępniane bez
                            zgody osób, których dane dotyczą, chyba że
                            udostępnia się te dane osobom upoważnionym do
                            przetwarzania danych osobowych, podmiotom którym
                            przekazano dane na podstawie umowy powierzenia (w
                            tym współpracującym z Administratorem: kancelariom
                            prawnym, biurom kadrowo-księgowym, podmiotom
                            świadczącym obsługę IT) oraz podmiotom upoważnionym
                            na podstawie obowiązujących przepisów prawa.
                          </li>
                          <li>
                            Przetwarzane dane osobowe nie są przekazywane do
                            państw spoza Unii Europejskiej i Europejskiego
                            Obszaru Gospodarczego.
                          </li>
                        </ul>
                        <h3>II. Sposoby pozyskiwania danych</h3>
                        <p>Dane osobowe są pozyskiwane poprzez:</p>
                        <ol>
                          <li>
                            formularze wypełniane online - informacje gromadzone
                            są dzięki formularzom dostępnym na naszej stronie,
                            służącym do celów kontaktowych, zgłoszenia pytań
                            oraz wyrażenia uwag;
                          </li>
                          <li>
                            kontakt bez pośrednictwa sieci - na stronie
                            internetowej znajdują się różne numery telefonów i
                            faksów oraz adresy email, pod którymi można się z
                            nami kontaktować;
                          </li>
                          <li>
                            podczas korzystania z zasobów strony gromadzone są
                            informacje o użytkownikach za pośrednictwem plików
                            takich jak "cookies" lub "web beacons".
                            <ul>
                              <li>
                                Cookies - to małe pliki tekstowe nazywane
                                „ciasteczkami”, wysyłane przez nasz serwis
                                internetowy i zapisywane na urządzeniu końcowym
                                użytkownika (komputerze, laptopie, smartfonie).
                                Używamy ich dla dostosowania treści i usług do
                                indywidualnych potrzeb oraz zainteresowań
                                użytkowników serwisu, zrozumienia sposobu, w
                                jaki użytkownicy korzystają z naszego serwisu
                                oraz stałego udoskonalania naszego serwisu
                                internetowego, czyniąc go jeszcze
                                przyjaźniejszymi i lepiej dostosowanymi do
                                potrzeb użytkowników. Użytkownik może w każdej
                                chwili wyłączyć w swojej przeglądarce opcję
                                przyjmowania cookies, jednakże efektem takiej
                                zmiany może być utrata możliwości korzystania ze
                                strony.
                              </li>
                              <li>
                                Web beacons - elektroniczne elementy graficzne
                                (zwane również web bugs, clear gifs albo
                                trackers), które umożliwiają określenie liczby
                                użytkowników, którzy odwiedzili naszą stronę,
                                dzięki kliknięciu na ogłoszenie dotyczące
                                naszych produktów i usług ze strony innej firmy.
                                Web beacons gromadzą informacje w ograniczonym
                                zakresie, należy do nich numer cookie, czas i
                                data otworzenia strony oraz opis strony, na
                                której znajduje się web beacon. Nasze strony nie
                                zawierają web beacons umieszczonych przez innych
                                ogłoszeniodawców, ale korzystamy z usług osób
                                trzecich w celu zbierania dla nas tych danych.W
                                związku z tym że, web beacons są stosowane w
                                połączeniu z cookies, można je dezaktywować
                                poprzez wyłączenie cookies lub zmianę ustawień
                                cookies w przeglądarce.
                              </li>
                            </ul>
                          </li>
                        </ol>
                        <h3>
                          III. W jakim celu są wykorzystywane zebrane przez nas
                          dane
                        </h3>
                        <ol>
                          <li>
                            Dane osobowe są wykorzystywane do celów realizacji
                            usług i dostaw produktów zamówionych przez klientów
                            oraz związanych z tym obowiązków prawnych ciążących
                            na administratorze- na podstawie art. 6 ust. 1 b) i
                            c) RODO, a ponadto do celów archiwalnych (dowodowe)
                            na wypadek prawnej potrzeby wskazania faktów oraz w
                            celu ewentualnego ustalenia, dochodzenia lub obrony
                            przed roszczeniami, co stanowi prawnie uzasadniony
                            interes (art. 6 ust. 1 lit f RODO).
                          </li>
                          <li>
                            W przypadku uzyskania odpowiedniej zgody, dane
                            wykorzystywane są również w celach marketingu
                            bezpośredniego produktów Tailored Apps. Łukasz
                            Kowalski, na podstawie art. 6 ust. 1 a) RODO
                          </li>
                        </ol>
                        <h3>IV. Bezpieczeństwo i przechowywanie informacji</h3>
                        <p>
                          Tailored Apps. Łukasz Kowalski zapewnia bezpieczeństwo
                          danych osobowych dzięki odpowiednim środkom
                          technicznym i organizacyjnym, mającym na celu
                          zapobieżenie bezprawnemu przetwarzaniu danych oraz ich
                          przypadkowej utracie, zniszczeniu oraz uszkodzeniu.
                        </p>
                        <h3>V. Prawa użytkownika</h3>
                        <ol>
                          <li>
                            Podanie danych osobowych w zakresie niezbędnym dla
                            realizacji dostaw produktów i usług oferowanych
                            przez administratora jest obowiązkowe, a w
                            pozostałym zakresie jest dobrowolne.
                          </li>
                          <li>
                            Tailored Apps. Łukasz Kowalski respektuje
                            przysługujące każdej osobie, której dane przetwarza
                            prawa: dostępu do danych, sprostowania danych,
                            usunięcia danych, ograniczenia przetwarzania,
                            przenoszenia danych, prawo do niepodlegania decyzji,
                            która opiera się wyłącznie na zautomatyzowanym
                            przetwarzaniu, w tym profilowaniu i wywołuje wobec
                            tej osoby skutki prawne lub w inny sposób istotnie
                            na nią wpływa.
                          </li>
                          <li>
                            Jeśli przetwarzanie odbywa się na podstawie zgody
                            osoby, której dane dotyczą, osoba ta ma prawo do
                            cofnięcia zgody w dowolnym momencie, z tym
                            zastrzeżeniem, iż nie wpłynie to na zgodność z
                            prawem przetwarzania, którego dokonano na podstawie
                            zgody przed jej cofnięciem.
                          </li>
                          <li>
                            Osoba, której dane przetwarzamy może skorzysta ze
                            swoich uprawnień poprzez skierowanie do Tailored
                            Apps. Łukasz Kowalski pisma, w którym przedstawi
                            swoje żądania, dokument może zostać przesłany w
                            formie elektronicznej na skrzynkę
                            contact@tailoredapps.pl lub w formie tradycyjnej na
                            adres firmy.
                          </li>
                          <li>
                            Osoba, której dane przetwarzamy ma prawo wniesienia
                            skargi do organu nadzorczego, jeśli uzna, że jej
                            prawa zostały naruszone.
                          </li>
                        </ol>

                        <h3>VI. Zmiany dotyczące polityki prywatności</h3>
                        <p>
                          Na zmiany w polityce ochrony prywatności Tailored
                          Apps. Łukasz Kowalski mogą mieć wpływ zmiany w sferze
                          ustawodawczej w zakresie ochrony danych osobowych, a
                          także inne czynniki. O wszelkich zmianach klienci i
                          użytkownicy zostaną bezzwłocznie poinformowani na
                          stronie internetowej.
                        </p>
                      </main>
                    </div>
                  </div>
                  <div className="sh-section__footer"></div>
                </div>
                <div className="col-xxl-4 d-none d-xxl-block"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
        </div>
      </div>
    </>
  );
}
