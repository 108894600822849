import { createSelector } from '@reduxjs/toolkit';
import { FilePondFile } from 'filepond';
import { List } from 'immutable';
import { AddOrUpdateArticle, Article, ArticleListItem, Emoji, Tag } from '../../client';
import { getEmojies } from '../Static/StaticSelectors';
import { IArticleReduxState } from './ArticleReducer';

const articleStateSelector = (articleState: IArticleReduxState) => articleState;

export const getArticleList = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): List<ArticleListItem> => {
		const articles = List(articleState.articleList);
		return articles;
	}
);
export const getHasMoreToLoad = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): boolean => {
		return (
			articleState.articleListResultsCount == undefined ||
			articleState.articleListCurrentPage <=
				articleState.articleListResultsCount / articleState.articleListPageSize
		);
	}
);

export const getAllTags = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): List<Tag> => {
		const allTags = List(articleState.allTags);
		return allTags;
	}
);

export const getAddArticleImage = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): string | FilePondFile | null => {
		return articleState.addArticleImage;
	}
);

export const getAddArticleImageCounter = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): number => {
		return articleState.addArticleImageChangeCounter;
	}
);

export const getArticlePageSelector = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): Article => {
		return articleState.articlePage;
	}
);

export const getAddArticleHadInitialSelector = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): boolean => {
		return articleState.addArticleImageHadInitial;
	}
);

export const getArticleId = createSelector(
	getArticlePageSelector,
	(articleState: Article): number | undefined => {
		return articleState?.id;
	}
);

export const getAddArticle = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): AddOrUpdateArticle => {
		return articleState.addArticle;
	}
);

export type EmojiCount = {
	id?: number | undefined;
	name?: string | null | undefined;
	iconUrl?: string | null | undefined;
	statisticsId?: number | undefined;
	emojiId?: number | undefined;
	emoji?: Emoji | undefined;
	count?: number | undefined;
};

export const getStatiticsEmojies = createSelector(
	getArticlePageSelector,
	getEmojies,
	(articleState: Article, listEmoji: List<Emoji>): List<EmojiCount> => {
		const currentEmojies = articleState?.statistics?.statisticsEmojies ?? [];
		const result = listEmoji.map((x) => ({
			...currentEmojies.find((y) => y.emojiId === x.id),
			...x
		}));
		return result;
	}
);

export const getTagsForArticlePage = createSelector(
	articleStateSelector,
	(articleState: IArticleReduxState): Tag[] => {
		const tags = articleState.articlePage.articleTags ?? [];
		return tags;
	}
);
