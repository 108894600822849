import { AddArticle } from '../../molecules/Article/AddArticle/AddArticleContainer';
import { AddArticleMenu } from '../../molecules/Menu/AddArticleMenu/AddArticleMenuContainer';
import { Header } from '../../molecules/Header/Header';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-kit-react/views/ArticlePage';

const useStyles = makeStyles(styles);

export default function EditArticlePage() {
	const classes = useStyles();
	return (
		<>
			<Header>
				<AddArticleMenu />
			</Header>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url('/assets/img/bg7.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'top center'
				}}
			></div>
			<div className={classes.mainRaised}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
						<div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
							<AddArticle />
							<div className="d-md-none">
								<br />
								<AddArticleMenu />
							</div>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
							<AddArticleMenu />
						</div>
						<div className="col-xl-1 d-none d-xl-block"></div>
					</div>
				</div>
			</div>
		</>
	);
}
