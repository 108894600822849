import { createSelector } from "@reduxjs/toolkit";
import { List } from "immutable";
import { Emoji, Patron } from "../../client";
import { IStaticReduxState } from "./StaticReducer";

const staticStateSelector = (staticState: IStaticReduxState) => staticState;

export const getEmojies = createSelector(
  staticStateSelector,
  (staticState: IStaticReduxState): List<Emoji> => {
    const emojiItems = List(staticState.emojies);
    return emojiItems;
  }
);

export const getPatreons = createSelector(
  staticStateSelector,
  (staticState: IStaticReduxState): List<Patron> => {
    const patreons = List(staticState.patreons);
    return patreons;
  }
);
