import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../assets/jss/material-kit-react/views/VerifyMailPage';
import { routes } from '../../../business/router/path-definitions';
import { Header } from '../../molecules/Header/Header';
import { LandingMenu } from '../../molecules/Menu/LandingMenu/LandingMenuContainer';

const useStyles = makeStyles(styles);

export default function ProblemPage() {
	const classes = useStyles();
	return (
		<div>
			<Header>
				<LandingMenu />
			</Header>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url('/assets/img/bg7.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'top center'
				}}
			></div>
			<div className={classNames(classes.mainRaised)}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
						<div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
							<div className="sh-section">
								<div className={classes.center}>
									<h1>Coś poszło nie tak 🤨</h1>
									<img
										className="sh-section__image max-height-40vh"
										src="https://thumbs.gfycat.com/BruisedOrderlyCassowary-size_restricted.gif"
									/>
									<br />
									<br />
									Brek nie widzi tej strony.
									<br />
									<br />
									<Link to={routes.home} className="sh-btn">
										<i className="icon-Arrow_Up"></i>Strona główna
									</Link>
									<br />
								</div>
							</div>

							<div className="d-md-none">
								<br />
								<LandingMenu />
							</div>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
							<LandingMenu />
						</div>
						<div className="col-xl-1 d-none d-xl-block"></div>
					</div>
				</div>
			</div>
		</div>
	);
}
