import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BuyVipAccountAndBecomeAPatron } from '../../../../business/Profile/ProfileEpics';
import { navigationAction } from '../../../../business/router/router-actions';
import { BecomeAPatron, PaymentChannel } from '../../../../client';

export interface IPatreonMenuProps {
	editProfile: {
		user: BecomeAPatron;
		paymentChannels: PaymentChannel[];
	};
	setEditProfile: React.Dispatch<
		React.SetStateAction<{
			user: BecomeAPatron;
			paymentChannels: PaymentChannel[];
		}>
	>;
}
export const PatreonMenu: React.FC<IPatreonMenuProps> = (props) => {
	const dispatch = useDispatch();
	// const [password, setPassword] = React.useState<string>();

	const handleSubmit = () => {
		dispatch(BuyVipAccountAndBecomeAPatron(props.editProfile.user));
		dispatch(navigationAction.goBack());
	};
	return (
		<div className="sh-section sh-menu sticky">
			<h2>NOWY VIP</h2>

			<a href="#" className="sh-btn" onClick={handleSubmit}>
				Zapłać
			</a>
			<br />
			<Tooltip id="clear" title="Anuluj zmiany" placement="top">
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
						dispatch(navigationAction.goBack());
					}}
					className="sh-section__btn-delete sh-btn-icon"
				>
					<i className="icon-Cancel"></i>
				</a>
			</Tooltip>
		</div>
	);
};
