import { createMatchSelector } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import * as Redux from "redux";
import { AnyAction, Dispatch } from "redux";
import { activateMail } from "../../../business/Auth/AuthEpics";
import { RootState } from "../../../business/root-store";
import { routes } from "../../../business/router/path-definitions";

interface IVerifyMailPageContainerReduxMergedProps {
  match: match<{ guid?: string }>;
}

interface IVerifyMailPageContainerOwnProps {}

interface IVerifyMailPageContainerDispatchProps {
  activateMail: (guid: string) => void;
}

interface IVerifyMailPageContainerProps
  extends IVerifyMailPageContainerOwnProps,
    IVerifyMailPageContainerDispatchProps,
    IVerifyMailPageContainerReduxMergedProps {}

interface IVerifyMailPageComponentState {}

class VerifyMailPageContainer extends React.Component<
  IVerifyMailPageContainerProps,
  IVerifyMailPageComponentState
> {
  constructor(
    props: IVerifyMailPageContainerProps,
    state: IVerifyMailPageComponentState
  ) {
    super(props, state);
    this.state = this.getInitialState();
    const guid = this.props.match.params.guid!;
    props.activateMail(guid);
  }

  public render(): JSX.Element {
    return <></>;
  }

  public static mapStateToProps = (
    state: RootState,
    ownProps: IVerifyMailPageContainerOwnProps
  ): IVerifyMailPageContainerReduxMergedProps => {
    const matchSelector = createMatchSelector(routes.verifyMail);
    return {
      match: matchSelector(state)!,
    };
  };

  public static mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>
  ): IVerifyMailPageContainerDispatchProps => {
    return {
      ...Redux.bindActionCreators(
        {
          activateMail: activateMail,
        },
        dispatch
      ),
    };
  };

  private getInitialState = (): IVerifyMailPageComponentState => {
    return {} as IVerifyMailPageComponentState;
  };
}
export const VerifyMailPageLogic = connect(
  VerifyMailPageContainer.mapStateToProps,
  VerifyMailPageContainer.mapDispatchToProps
)(VerifyMailPageContainer);
