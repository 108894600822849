import { container, title } from "../../material-kit-react";
import { createStyles } from "@material-ui/core/styles";

const landingPageStyle = createStyles({
  container: {
    zIndex: 12,
    color: "#FFFFFF",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "15px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  mainRaised: {
    position: "relative",
    zIndex: 3,
    marginTop: "-60px",
    borderRadius: "6px",
  },
  menu: {
    flex: "1 1 auto",
  },
});

export default landingPageStyle;
