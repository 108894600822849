import { List } from "immutable";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/profilePage";
import { Patron } from "../../../client";
import { Header } from "../../molecules/Header/Header";
import { Link } from "react-router-dom";
export interface IPatreonListPageComponentProps {
  patreons: List<Patron>;
}
const useStyles = makeStyles(styles);

export const PatreonListPageComponent: React.FC<IPatreonListPageComponentProps> =
  (props) => {
    const classes = useStyles();
    return (
      <>
        <Header>menu</Header>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url('/assets/img/bg7.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        ></div>
        <div className={classes.mainRaised}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
                <div className="sh-section ">
                  <div className="sh-section__content">
                    <h3>Po co to wszystko?</h3>
                    <span>
                      Jesteśmy młodym portalem, sami staramy się sfinansować.
                      jednak światem rządzi ekonomia, Aby móc się rozwijać i
                      działać dalej. potrzebujemy waszego wsparcia. Poniżej
                      znajduje się lista sponsorów którzy wsparli nas! bardzo
                      dziękujemy!
                    </span>
                  </div>
                </div>

                <div className="sh-section ">
                  <div className="sh-section__content">
                    Nasi patroni są najlepsi dlatego z przyjemnością dzielimy
                    się z listą osób nas wspierających!
                    <br />
                    <h5>Dziękujemy:</h5>
                    <ul>
                      {props.patreons.map((x) => (
                        <li><Link to={"/profile/" + x.userId} >{x.username}{' '}
                        {(x.active) && <span >💥</span>}</Link></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
                menu
              </div>
              <div className="col-xl-1 d-none d-xl-block"></div>
            </div>
          </div>
        </div>
      </>
    );
  };
