import React from "react";
import { GetUserInfoResponse } from "../../../client";
import { ProfilePageItem } from "./ProfilePageItem";

interface IProfilePageComponentProps {
  userInfo: GetUserInfoResponse;
  userId: number;
}

interface IProfilePageComponentState {}

export class ProfilePageComponent extends React.Component<
  IProfilePageComponentProps,
  IProfilePageComponentState
> {
  constructor(
    props: IProfilePageComponentProps,
    state: IProfilePageComponentState
  ) {
    super(props, state);

    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return (
      <ProfilePageItem
        userInfo={this.props.userInfo}
        userId={this.props.userId}
      />
    );
  }
  private getInitialState = (): IProfilePageComponentState => {
    return {} as IProfilePageComponentState;
  };
}
