import { Emoji, Patron } from "../../client";

export const Emojies_Saved = "@@Dramki/Static/Emojies/SAVED";
export const Patrons_Saved = "@@Dramki/Static/Patrons/SAVED";

//funkcje ktore wykonuja akcje w recuderze
export interface IEmojiesSavedAction {
  type: typeof Emojies_Saved;
  emojies: Emoji[];
}

export interface IPatronsSavedAction {
  type: typeof Patrons_Saved;
  patrons: Patron[];
}

export const EmojiesSaved = (emojies: Emoji[]): IEmojiesSavedAction => ({
  type: Emojies_Saved,
  emojies,
});

export const PatronsSaved = (patrons: Patron[]): IPatronsSavedAction => ({
  type: Patrons_Saved,
  patrons,
});
