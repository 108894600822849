/* tslint:disable */
/* eslint-disable */
/**
 * TailoredApps.Dramki.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivateMailResponse
 */
export interface ActivateMailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ActivateMailResponse
     */
    status?: boolean;
}
/**
 * 
 * @export
 * @interface AddApplicationRole
 */
export interface AddApplicationRole {
    /**
     * 
     * @type {Role}
     * @memberof AddApplicationRole
     */
    role?: Role;
}
/**
 * 
 * @export
 * @interface AddApplicationRoleResponse
 */
export interface AddApplicationRoleResponse {
    /**
     * 
     * @type {Role}
     * @memberof AddApplicationRoleResponse
     */
    role?: Role;
}
/**
 * 
 * @export
 * @interface AddOrUpdateArticle
 */
export interface AddOrUpdateArticle {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateArticle
     */
    articleId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    pictureOriginal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    pictureThumbnail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    pictureModified?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateArticle
     */
    pictureScore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    pictureSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateArticle
     */
    pictureLabel?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof AddOrUpdateArticle
     */
    tags?: Array<Tag> | null;
}
/**
 * 
 * @export
 * @interface AddOrUpdateArticleResponse
 */
export interface AddOrUpdateArticleResponse {
    /**
     * 
     * @type {Article}
     * @memberof AddOrUpdateArticleResponse
     */
    article?: Article;
}
/**
 * 
 * @export
 * @interface AddOrUpdateComment
 */
export interface AddOrUpdateComment {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateComment
     */
    commentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateComment
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateComment
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateComment
     */
    parentCommentId?: number | null;
}
/**
 * 
 * @export
 * @interface AddOrUpdateCommentResponse
 */
export interface AddOrUpdateCommentResponse {
    /**
     * 
     * @type {Comment}
     * @memberof AddOrUpdateCommentResponse
     */
    comment?: Comment;
}
/**
 * 
 * @export
 * @interface AddSecurity
 */
export interface AddSecurity {
    /**
     * 
     * @type {Security}
     * @memberof AddSecurity
     */
    security?: Security;
}
/**
 * 
 * @export
 * @interface AddSecurityResponse
 */
export interface AddSecurityResponse {
    /**
     * 
     * @type {Security}
     * @memberof AddSecurityResponse
     */
    security?: Security;
}
/**
 * 
 * @export
 * @interface AddTagResponse
 */
export interface AddTagResponse {
    /**
     * 
     * @type {Tag}
     * @memberof AddTagResponse
     */
    tag?: Tag;
}
/**
 * 
 * @export
 * @interface AddUserRole
 */
export interface AddUserRole {
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    roleId?: number;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    id?: number;
    /**
     * 
     * @type {User}
     * @memberof Article
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    userId?: number;
    /**
     * 
     * @type {Statistics}
     * @memberof Article
     */
    statistics?: Statistics;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    statisticsId?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    pictureScore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    pictureSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    pictureLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    pictureOriginal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    pictureThumbnail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    pictureModified?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    createdDateUtc?: string;
    /**
     * 
     * @type {Array<ArticleTag>}
     * @memberof Article
     */
    articleTags?: Array<ArticleTag> | null;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Article
     */
    comments?: Array<Comment> | null;
}
/**
 * 
 * @export
 * @interface ArticleListItem
 */
export interface ArticleListItem {
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    authorUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    authorUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    authorAvatar?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    patreon?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    statisticsId?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    dislikes?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    likes?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    averageLikes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleListItem
     */
    userLiked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleListItem
     */
    userDisliked?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    commentsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    pictureOriginal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    pictureThumbnail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    pictureModified?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    pictureScore?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    pictureSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    pictureLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleListItem
     */
    createdDateUtc?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleListItem
     */
    reactionsCount?: number;
}
/**
 * 
 * @export
 * @interface ArticleTag
 */
export interface ArticleTag {
    /**
     * 
     * @type {number}
     * @memberof ArticleTag
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleTag
     */
    articleId?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleTag
     */
    tagId?: number;
    /**
     * 
     * @type {Tag}
     * @memberof ArticleTag
     */
    tag?: Tag;
}
/**
 * 
 * @export
 * @interface AuthData
 */
export interface AuthData {
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    token?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthData
     */
    mailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    entryCreationDateTimeUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    lastCheckDateTimeUtc?: string;
}
/**
 * 
 * @export
 * @interface BecomeAPatron
 */
export interface BecomeAPatron {
    /**
     * 
     * @type {number}
     * @memberof BecomeAPatron
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    postCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    house?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    flat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    ref?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    country?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BecomeAPatron
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatron
     */
    paymentChannelId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BecomeAPatron
     */
    accepted?: boolean;
}
/**
 * 
 * @export
 * @interface BecomeAPatronResponse
 */
export interface BecomeAPatronResponse {
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatronResponse
     */
    paymentProviderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BecomeAPatronResponse
     */
    paymentProviderRedirectUrl?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    mail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    resetGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    oldPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordResponse
 */
export interface ChangePasswordResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof ChangePasswordResponse
     */
    authData?: AuthData;
}
/**
 * 
 * @export
 * @interface CheckAuthToken
 */
export interface CheckAuthToken {
    /**
     * 
     * @type {string}
     * @memberof CheckAuthToken
     */
    authToken?: string | null;
}
/**
 * 
 * @export
 * @interface CheckAuthTokenResponse
 */
export interface CheckAuthTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckAuthTokenResponse
     */
    valid?: boolean;
    /**
     * 
     * @type {AuthData}
     * @memberof CheckAuthTokenResponse
     */
    authData?: AuthData;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    id?: number;
    /**
     * 
     * @type {Article}
     * @memberof Comment
     */
    article?: Article;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    articleId?: number;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    userId?: number;
    /**
     * 
     * @type {Statistics}
     * @memberof Comment
     */
    statistics?: Statistics;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    statisticsId?: number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    parentCommentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    createdDateUtc?: string;
}
/**
 * 
 * @export
 * @interface DeleteArticle
 */
export interface DeleteArticle {
    /**
     * 
     * @type {Article}
     * @memberof DeleteArticle
     */
    article?: Article;
}
/**
 * 
 * @export
 * @interface DeleteComment
 */
export interface DeleteComment {
    /**
     * 
     * @type {Comment}
     * @memberof DeleteComment
     */
    comment?: Comment;
}
/**
 * 
 * @export
 * @interface Emoji
 */
export interface Emoji {
    /**
     * 
     * @type {number}
     * @memberof Emoji
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Emoji
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Emoji
     */
    iconUrl?: string | null;
}
/**
 * 
 * @export
 * @interface ExceptionHandlingResultModel
 */
export interface ExceptionHandlingResultModel {
    /**
     * 
     * @type {string}
     * @memberof ExceptionHandlingResultModel
     */
    message?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionHandlingResultModel
     */
    errorCode?: number;
    /**
     * 
     * @type {Array<ExceptionOrValidationError>}
     * @memberof ExceptionHandlingResultModel
     */
    errors?: Array<ExceptionOrValidationError> | null;
}
/**
 * 
 * @export
 * @interface ExceptionOrValidationError
 */
export interface ExceptionOrValidationError {
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface FileClassification
 */
export interface FileClassification {
    /**
     * 
     * @type {number}
     * @memberof FileClassification
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    modelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    modelVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    modelChecksum?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileClassification
     */
    score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    createdDateUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    modifiedDateUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FileClassification
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    metadaId?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    fileGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    name?: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof FileInfo
     */
    fileType?: FileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    label?: string | null;
    /**
     * 
     * @type {Array<FileClassification>}
     * @memberof FileInfo
     */
    classification?: Array<FileClassification> | null;
}
/**
 * 
 * @export
 * @interface FileMetadata
 */
export interface FileMetadata {
    /**
     * 
     * @type {number}
     * @memberof FileMetadata
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    extension?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileMetadata
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    createdDateUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    modifiedDateUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    lastVersionId?: string;
    /**
     * 
     * @type {any}
     * @memberof FileMetadata
     */
    lastVersion?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FileTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface GetAllApplicationSecuritiesResponse
 */
export interface GetAllApplicationSecuritiesResponse {
    /**
     * 
     * @type {Array<Security>}
     * @memberof GetAllApplicationSecuritiesResponse
     */
    securities?: Array<Security> | null;
}
/**
 * 
 * @export
 * @interface GetAllArticlesResponse
 */
export interface GetAllArticlesResponse {
    /**
     * 
     * @type {Array<ArticleListItem>}
     * @memberof GetAllArticlesResponse
     */
    results?: Array<ArticleListItem> | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllArticlesResponse
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface GetAllEmojiesResponse
 */
export interface GetAllEmojiesResponse {
    /**
     * 
     * @type {Array<Emoji>}
     * @memberof GetAllEmojiesResponse
     */
    emojies?: Array<Emoji> | null;
}
/**
 * 
 * @export
 * @interface GetAllTagsResponse
 */
export interface GetAllTagsResponse {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof GetAllTagsResponse
     */
    tags?: Array<Tag> | null;
}
/**
 * 
 * @export
 * @interface GetApplicationRolesResponse
 */
export interface GetApplicationRolesResponse {
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetApplicationRolesResponse
     */
    roles?: Array<Role> | null;
}
/**
 * 
 * @export
 * @interface GetApplicationsResponse
 */
export interface GetApplicationsResponse {
    /**
     * 
     * @type {Array<Application>}
     * @memberof GetApplicationsResponse
     */
    applications?: Array<Application> | null;
}
/**
 * 
 * @export
 * @interface GetArticleByArticleIdResponse
 */
export interface GetArticleByArticleIdResponse {
    /**
     * 
     * @type {Article}
     * @memberof GetArticleByArticleIdResponse
     */
    article?: Article;
}
/**
 * 
 * @export
 * @interface GetFilesFromCatalogResponse
 */
export interface GetFilesFromCatalogResponse {
    /**
     * 
     * @type {Array<FileMetadata>}
     * @memberof GetFilesFromCatalogResponse
     */
    fileMetadatas?: Array<FileMetadata> | null;
}
/**
 * 
 * @export
 * @interface GetLoggedInUserDataResponse
 */
export interface GetLoggedInUserDataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetLoggedInUserDataResponse
     */
    gravatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLoggedInUserDataResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetLoggedInUserDataResponse
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface GetPatreonListResponse
 */
export interface GetPatreonListResponse {
    /**
     * 
     * @type {Array<Patron>}
     * @memberof GetPatreonListResponse
     */
    patrons?: Array<Patron> | null;
}
/**
 * 
 * @export
 * @interface GetPaymentChannelsResponse
 */
export interface GetPaymentChannelsResponse {
    /**
     * 
     * @type {Array<PaymentChannel>}
     * @memberof GetPaymentChannelsResponse
     */
    paymentChannels?: Array<PaymentChannel> | null;
}
/**
 * 
 * @export
 * @interface GetRolesResponse
 */
export interface GetRolesResponse {
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetRolesResponse
     */
    roles?: Array<Role> | null;
}
/**
 * 
 * @export
 * @interface GetSearchResultsResponse
 */
export interface GetSearchResultsResponse {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof GetSearchResultsResponse
     */
    searchResults?: Array<SearchResult> | null;
}
/**
 * 
 * @export
 * @interface GetUserByIdResponse
 */
export interface GetUserByIdResponse {
    /**
     * 
     * @type {User}
     * @memberof GetUserByIdResponse
     */
    user?: User;
}
/**
 * 
 * @export
 * @interface GetUserInfoResponse
 */
export interface GetUserInfoResponse {
    /**
     * 
     * @type {UserProfile}
     * @memberof GetUserInfoResponse
     */
    userProfile?: UserProfile;
    /**
     * 
     * @type {UserStatistics}
     * @memberof GetUserInfoResponse
     */
    userStatistics?: UserStatistics;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof GetUserInfoResponse
     */
    comments?: Array<Comment> | null;
}
/**
 * 
 * @export
 * @interface GetUserStatisticsResponse
 */
export interface GetUserStatisticsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    growth?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    newUsers?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    users?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    lockedUsers?: number;
}
/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetUsersResponse
     */
    users?: Array<User> | null;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    systemUniqueId?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof LoginResponse
     */
    authData?: AuthData;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    extension?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    exist?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    fileGroupId?: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof ModelFile
     */
    fileType?: FileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    version?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {FileMetadata}
     * @memberof ModelFile
     */
    metadata?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    storagePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    source?: string | null;
}
/**
 * 
 * @export
 * @interface Patron
 */
export interface Patron {
    /**
     * 
     * @type {number}
     * @memberof Patron
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof Patron
     */
    username?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Patron
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentChannel
 */
export interface PaymentChannel {
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    logoUrl?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentChannel
     */
    availableCurrencies?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PostFile
 */
export interface PostFile {
    /**
     * 
     * @type {string}
     * @memberof PostFile
     */
    file?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostFile
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostFile
     */
    catalogId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostFile
     */
    correlationId?: string | null;
}
/**
 * 
 * @export
 * @interface PostFileCatalog
 */
export interface PostFileCatalog {
    /**
     * 
     * @type {string}
     * @memberof PostFileCatalog
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostFileCatalog
     */
    correlationId?: string | null;
}
/**
 * 
 * @export
 * @interface PostFileCatalogResponse
 */
export interface PostFileCatalogResponse {
    /**
     * 
     * @type {string}
     * @memberof PostFileCatalogResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostFileCatalogResponse
     */
    catalogId?: number;
}
/**
 * 
 * @export
 * @interface PostFileFromUrl
 */
export interface PostFileFromUrl {
    /**
     * 
     * @type {string}
     * @memberof PostFileFromUrl
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface PostFileResponse
 */
export interface PostFileResponse {
    /**
     * 
     * @type {string}
     * @memberof PostFileResponse
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostFileResponse
     */
    fileUrl?: string | null;
}
/**
 * 
 * @export
 * @interface PostImageFile
 */
export interface PostImageFile {
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    imageData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    source?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostImageFile
     */
    resize?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostImageFile
     */
    keepResizeRatio?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    height?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostImageFile
     */
    generateThumbminal?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    thumbminalWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    thumbminalHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    catalogId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    correlationId?: string | null;
}
/**
 * 
 * @export
 * @interface PostImageFileResponse
 */
export interface PostImageFileResponse {
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof PostImageFileResponse
     */
    fileInfos?: Array<FileInfo> | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface PutFile
 */
export interface PutFile {
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    file?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutFile
     */
    name?: number;
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    groupGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    correlationId?: string | null;
}
/**
 * 
 * @export
 * @interface PutFileCatalog
 */
export interface PutFileCatalog {
    /**
     * 
     * @type {number}
     * @memberof PutFileCatalog
     */
    catalogId?: number;
    /**
     * 
     * @type {string}
     * @memberof PutFileCatalog
     */
    newName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutFileCatalog
     */
    correlationId?: string | null;
}
/**
 * 
 * @export
 * @interface PutFileCatalogResponse
 */
export interface PutFileCatalogResponse {
    /**
     * 
     * @type {string}
     * @memberof PutFileCatalogResponse
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface PutFileResponse
 */
export interface PutFileResponse {
    /**
     * 
     * @type {string}
     * @memberof PutFileResponse
     */
    fileId?: string;
    /**
     * 
     * @type {number}
     * @memberof PutFileResponse
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof PutFileResponse
     */
    fileUrl?: string | null;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    reTypePassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    systemUniqueId?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterResponse
 */
export interface RegisterResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof RegisterResponse
     */
    authData?: AuthData;
}
/**
 * 
 * @export
 * @interface ReportViolation
 */
export interface ReportViolation {
    /**
     * 
     * @type {number}
     * @memberof ReportViolation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportViolation
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportViolation
     */
    whatsWrong?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportViolation
     */
    reporterMail?: string | null;
}
/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    mail?: string | null;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    description?: string | null;
    /**
     * 
     * @type {Application}
     * @memberof Role
     */
    application?: Application;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    applicationId?: number;
    /**
     * 
     * @type {Array<Security>}
     * @memberof Role
     */
    securities?: Array<Security> | null;
}
/**
 * 
 * @export
 * @interface SaveApplication
 */
export interface SaveApplication {
    /**
     * 
     * @type {Application}
     * @memberof SaveApplication
     */
    application?: Application;
}
/**
 * 
 * @export
 * @interface SaveApplicationResponse
 */
export interface SaveApplicationResponse {
    /**
     * 
     * @type {Application}
     * @memberof SaveApplicationResponse
     */
    application?: Application;
}
/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    typeString?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface Security
 */
export interface Security {
    /**
     * 
     * @type {number}
     * @memberof Security
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Security
     */
    applicationId?: number;
    /**
     * 
     * @type {Application}
     * @memberof Security
     */
    application?: Application;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SortDirection {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics {
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    likes?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    dislikes?: number;
    /**
     * 
     * @type {string}
     * @memberof Statistics
     */
    emotionsAnalysis?: string | null;
    /**
     * 
     * @type {StatisticsUser}
     * @memberof Statistics
     */
    statisticsUser?: StatisticsUser;
    /**
     * 
     * @type {Array<StatisticsEmoji>}
     * @memberof Statistics
     */
    statisticsEmojies?: Array<StatisticsEmoji> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Statistics
     */
    isComment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Statistics
     */
    isArticle?: boolean;
}
/**
 * 
 * @export
 * @interface StatisticsEmoji
 */
export interface StatisticsEmoji {
    /**
     * 
     * @type {number}
     * @memberof StatisticsEmoji
     */
    statisticsId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsEmoji
     */
    emojiId?: number;
    /**
     * 
     * @type {Emoji}
     * @memberof StatisticsEmoji
     */
    emoji?: Emoji;
    /**
     * 
     * @type {number}
     * @memberof StatisticsEmoji
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface StatisticsUser
 */
export interface StatisticsUser {
    /**
     * 
     * @type {number}
     * @memberof StatisticsUser
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsUser
     */
    statisticsId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsUser
     */
    emojiId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsUser
     */
    like?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsUser
     */
    dislike?: boolean;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateApplicationRole
 */
export interface UpdateApplicationRole {
    /**
     * 
     * @type {number}
     * @memberof UpdateApplicationRole
     */
    roleId?: number;
    /**
     * 
     * @type {Role}
     * @memberof UpdateApplicationRole
     */
    role?: Role;
}
/**
 * 
 * @export
 * @interface UpdateApplicationRoleResponse
 */
export interface UpdateApplicationRoleResponse {
    /**
     * 
     * @type {Role}
     * @memberof UpdateApplicationRoleResponse
     */
    role?: Role;
}
/**
 * 
 * @export
 * @interface UpdateUserData
 */
export interface UpdateUserData {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserData
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof UpdateUserData
     */
    user?: User;
}
/**
 * 
 * @export
 * @interface UpdateUserDataResponse
 */
export interface UpdateUserDataResponse {
    /**
     * 
     * @type {User}
     * @memberof UpdateUserDataResponse
     */
    user?: User;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    sex?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    registerDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdDateUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    confirmedMail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    reset?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastInvalidLoginDateUtc?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isLocked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    unlockDateTimeUtc?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    invalidLoginCount?: number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    patreon?: boolean;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    registerDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    userName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isPatreon?: boolean;
}
/**
 * 
 * @export
 * @interface UserStatistics
 */
export interface UserStatistics {
    /**
     * 
     * @type {UserStatisticsMetrics}
     * @memberof UserStatistics
     */
    articlesMetric?: UserStatisticsMetrics;
    /**
     * 
     * @type {UserStatisticsMetrics}
     * @memberof UserStatistics
     */
    commentsMetric?: UserStatisticsMetrics;
}
/**
 * 
 * @export
 * @interface UserStatisticsMetrics
 */
export interface UserStatisticsMetrics {
    /**
     * 
     * @type {number}
     * @memberof UserStatisticsMetrics
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatisticsMetrics
     */
    allLikes?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatisticsMetrics
     */
    allDislikes?: number;
}
/**
 * 
 * @export
 * @interface ViewResult
 */
export interface ViewResult {
    /**
     * 
     * @type {number}
     * @memberof ViewResult
     */
    statusCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViewResult
     */
    viewName?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ViewResult
     */
    model?: any | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ViewResult
     */
    viewData?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ViewResult
     */
    tempData?: { [key: string]: any; } | null;
    /**
     * 
     * @type {object}
     * @memberof ViewResult
     */
    viewEngine?: object;
    /**
     * 
     * @type {string}
     * @memberof ViewResult
     */
    contentType?: string | null;
}

/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOrUpdateArticle} [addOrUpdateArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateArticle: async (addOrUpdateArticle?: AddOrUpdateArticle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOrUpdateComment} [addOrUpdateComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateComment: async (addOrUpdateComment?: AddOrUpdateComment, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} statisticsId 
         * @param {number} emojiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateEmoji: async (statisticsId: number, emojiId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'statisticsId' is not null or undefined
            assertParamExists('addOrUpdateEmoji', 'statisticsId', statisticsId)
            // verify required parameter 'emojiId' is not null or undefined
            assertParamExists('addOrUpdateEmoji', 'emojiId', emojiId)
            const localVarPath = `/Article/statistics/{statisticsId}/{emojiId}`
                .replace(`{${"statisticsId"}}`, encodeURIComponent(String(statisticsId)))
                .replace(`{${"emojiId"}}`, encodeURIComponent(String(emojiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} statisticsId 
         * @param {boolean} like 
         * @param {boolean} dislike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateLikeDislike: async (statisticsId: number, like: boolean, dislike: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'statisticsId' is not null or undefined
            assertParamExists('addOrUpdateLikeDislike', 'statisticsId', statisticsId)
            // verify required parameter 'like' is not null or undefined
            assertParamExists('addOrUpdateLikeDislike', 'like', like)
            // verify required parameter 'dislike' is not null or undefined
            assertParamExists('addOrUpdateLikeDislike', 'dislike', dislike)
            const localVarPath = `/Article/statistics/{statisticsId}/{like}/{dislike}`
                .replace(`{${"statisticsId"}}`, encodeURIComponent(String(statisticsId)))
                .replace(`{${"like"}}`, encodeURIComponent(String(like)))
                .replace(`{${"dislike"}}`, encodeURIComponent(String(dislike)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTag: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addTag', 'name', name)
            const localVarPath = `/Article/tag/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteArticle} [deleteArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle: async (deleteArticle?: DeleteArticle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteComment} [deleteComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (deleteComment?: DeleteComment, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir] 
         * @param {boolean} [isSortingSpecified] 
         * @param {number} [filterUserId] 
         * @param {number} [filterTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllArticles: async (page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterUserId?: number, filterTagId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (isPagingSpecified !== undefined) {
                localVarQueryParameter['IsPagingSpecified'] = isPagingSpecified;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['SortDir'] = sortDir;
            }

            if (isSortingSpecified !== undefined) {
                localVarQueryParameter['IsSortingSpecified'] = isSortingSpecified;
            }

            if (filterUserId !== undefined) {
                localVarQueryParameter['Filter.UserId'] = filterUserId;
            }

            if (filterTagId !== undefined) {
                localVarQueryParameter['Filter.TagId'] = filterTagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmojies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/emoji`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByArticleId: async (articleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticleByArticleId', 'articleId', articleId)
            const localVarPath = `/Article/article/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsResponse: async (searchContent: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchContent' is not null or undefined
            assertParamExists('getSearchResultsResponse', 'searchContent', searchContent)
            const localVarPath = `/Article/search/{searchContent}`
                .replace(`{${"searchContent"}}`, encodeURIComponent(String(searchContent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoById: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserInfoById', 'userId', userId)
            const localVarPath = `/Article/profile/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oembed: async (query?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/Oembed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportViolation} [reportViolation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportViolation: async (reportViolation?: ReportViolation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Article/Violation/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportViolation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateArticle} [addOrUpdateArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateArticle(addOrUpdateArticle?: AddOrUpdateArticle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateArticle(addOrUpdateArticle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddOrUpdateComment} [addOrUpdateComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateComment(addOrUpdateComment?: AddOrUpdateComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateComment(addOrUpdateComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} statisticsId 
         * @param {number} emojiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateEmoji(statisticsId: number, emojiId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateEmoji(statisticsId, emojiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} statisticsId 
         * @param {boolean} like 
         * @param {boolean} dislike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateLikeDislike(statisticsId: number, like: boolean, dislike: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateLikeDislike(statisticsId, like, dislike, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTag(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTag(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteArticle} [deleteArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArticle(deleteArticle?: DeleteArticle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticle(deleteArticle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteComment} [deleteComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(deleteComment?: DeleteComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(deleteComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir] 
         * @param {boolean} [isSortingSpecified] 
         * @param {number} [filterUserId] 
         * @param {number} [filterTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllArticles(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterUserId?: number, filterTagId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllArticles(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterUserId, filterTagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmojies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllEmojiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEmojies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTags(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleByArticleId(articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArticleByArticleIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleByArticleId(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchResultsResponse(searchContent: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSearchResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchResultsResponse(searchContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoById(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oembed(query?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oembed(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportViolation} [reportViolation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportViolation(reportViolation?: ReportViolation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportViolation(reportViolation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateArticle} [addOrUpdateArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateArticle(addOrUpdateArticle?: AddOrUpdateArticle, options?: any): AxiosPromise<AddOrUpdateArticleResponse> {
            return localVarFp.addOrUpdateArticle(addOrUpdateArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOrUpdateComment} [addOrUpdateComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateComment(addOrUpdateComment?: AddOrUpdateComment, options?: any): AxiosPromise<AddOrUpdateCommentResponse> {
            return localVarFp.addOrUpdateComment(addOrUpdateComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} statisticsId 
         * @param {number} emojiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateEmoji(statisticsId: number, emojiId: number, options?: any): AxiosPromise<object> {
            return localVarFp.addOrUpdateEmoji(statisticsId, emojiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} statisticsId 
         * @param {boolean} like 
         * @param {boolean} dislike 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateLikeDislike(statisticsId: number, like: boolean, dislike: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.addOrUpdateLikeDislike(statisticsId, like, dislike, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTag(name: string, options?: any): AxiosPromise<AddTagResponse> {
            return localVarFp.addTag(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteArticle} [deleteArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle(deleteArticle?: DeleteArticle, options?: any): AxiosPromise<object> {
            return localVarFp.deleteArticle(deleteArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteComment} [deleteComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(deleteComment?: DeleteComment, options?: any): AxiosPromise<object> {
            return localVarFp.deleteComment(deleteComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir] 
         * @param {boolean} [isSortingSpecified] 
         * @param {number} [filterUserId] 
         * @param {number} [filterTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllArticles(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterUserId?: number, filterTagId?: number, options?: any): AxiosPromise<GetAllArticlesResponse> {
            return localVarFp.getAllArticles(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterUserId, filterTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmojies(options?: any): AxiosPromise<GetAllEmojiesResponse> {
            return localVarFp.getAllEmojies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags(options?: any): AxiosPromise<GetAllTagsResponse> {
            return localVarFp.getAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleByArticleId(articleId: number, options?: any): AxiosPromise<GetArticleByArticleIdResponse> {
            return localVarFp.getArticleByArticleId(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsResponse(searchContent: string, options?: any): AxiosPromise<GetSearchResultsResponse> {
            return localVarFp.getSearchResultsResponse(searchContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoById(userId: number, options?: any): AxiosPromise<GetUserInfoResponse> {
            return localVarFp.getUserInfoById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oembed(query?: string, options?: any): AxiosPromise<any> {
            return localVarFp.oembed(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportViolation} [reportViolation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportViolation(reportViolation?: ReportViolation, options?: any): AxiosPromise<object> {
            return localVarFp.reportViolation(reportViolation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @param {AddOrUpdateArticle} [addOrUpdateArticle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public addOrUpdateArticle(addOrUpdateArticle?: AddOrUpdateArticle, options?: any) {
        return ArticleApiFp(this.configuration).addOrUpdateArticle(addOrUpdateArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOrUpdateComment} [addOrUpdateComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public addOrUpdateComment(addOrUpdateComment?: AddOrUpdateComment, options?: any) {
        return ArticleApiFp(this.configuration).addOrUpdateComment(addOrUpdateComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} statisticsId 
     * @param {number} emojiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public addOrUpdateEmoji(statisticsId: number, emojiId: number, options?: any) {
        return ArticleApiFp(this.configuration).addOrUpdateEmoji(statisticsId, emojiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} statisticsId 
     * @param {boolean} like 
     * @param {boolean} dislike 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public addOrUpdateLikeDislike(statisticsId: number, like: boolean, dislike: boolean, options?: any) {
        return ArticleApiFp(this.configuration).addOrUpdateLikeDislike(statisticsId, like, dislike, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public addTag(name: string, options?: any) {
        return ArticleApiFp(this.configuration).addTag(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteArticle} [deleteArticle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public deleteArticle(deleteArticle?: DeleteArticle, options?: any) {
        return ArticleApiFp(this.configuration).deleteArticle(deleteArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteComment} [deleteComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public deleteComment(deleteComment?: DeleteComment, options?: any) {
        return ArticleApiFp(this.configuration).deleteComment(deleteComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [isPagingSpecified] 
     * @param {string} [sortField] 
     * @param {SortDirection} [sortDir] 
     * @param {boolean} [isSortingSpecified] 
     * @param {number} [filterUserId] 
     * @param {number} [filterTagId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getAllArticles(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterUserId?: number, filterTagId?: number, options?: any) {
        return ArticleApiFp(this.configuration).getAllArticles(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterUserId, filterTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getAllEmojies(options?: any) {
        return ArticleApiFp(this.configuration).getAllEmojies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getAllTags(options?: any) {
        return ArticleApiFp(this.configuration).getAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticleByArticleId(articleId: number, options?: any) {
        return ArticleApiFp(this.configuration).getArticleByArticleId(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getSearchResultsResponse(searchContent: string, options?: any) {
        return ArticleApiFp(this.configuration).getSearchResultsResponse(searchContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getUserInfoById(userId: number, options?: any) {
        return ArticleApiFp(this.configuration).getUserInfoById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public oembed(query?: string, options?: any) {
        return ArticleApiFp(this.configuration).oembed(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportViolation} [reportViolation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public reportViolation(reportViolation?: ReportViolation, options?: any) {
        return ArticleApiFp(this.configuration).reportViolation(reportViolation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePassword?: ChangePassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckAuthToken} [checkAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthToken: async (checkAuthToken?: CheckAuthToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/CheckAuthToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkAuthToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivateMail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivateMail', 'id', id)
            const localVarPath = `/Authentication/Activate/Mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUserData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/GetLoggedInUserData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login?: Login, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivateMail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postActivateMail', 'id', id)
            const localVarPath = `/Authentication/Activate/Mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (register?: Register, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPassword} [resetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPassword?: ResetPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePassword?: ChangePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckAuthToken} [checkAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAuthToken(checkAuthToken?: CheckAuthToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckAuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAuthToken(checkAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivateMail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivateMail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedInUserData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoggedInUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedInUserData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login?: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivateMail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivateMail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(register?: Register, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(register, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPassword} [resetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPassword?: ResetPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePassword?: ChangePassword, options?: any): AxiosPromise<ChangePasswordResponse> {
            return localVarFp.changePassword(changePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckAuthToken} [checkAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthToken(checkAuthToken?: CheckAuthToken, options?: any): AxiosPromise<CheckAuthTokenResponse> {
            return localVarFp.checkAuthToken(checkAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivateMail(id: string, options?: any): AxiosPromise<ActivateMailResponse> {
            return localVarFp.getActivateMail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUserData(options?: any): AxiosPromise<GetLoggedInUserDataResponse> {
            return localVarFp.getLoggedInUserData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login?: Login, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivateMail(id: string, options?: any): AxiosPromise<ActivateMailResponse> {
            return localVarFp.postActivateMail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(register?: Register, options?: any): AxiosPromise<RegisterResponse> {
            return localVarFp.register(register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPassword} [resetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword?: ResetPassword, options?: any): AxiosPromise<object> {
            return localVarFp.resetPassword(resetPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {ChangePassword} [changePassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public changePassword(changePassword?: ChangePassword, options?: any) {
        return AuthenticationApiFp(this.configuration).changePassword(changePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckAuthToken} [checkAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public checkAuthToken(checkAuthToken?: CheckAuthToken, options?: any) {
        return AuthenticationApiFp(this.configuration).checkAuthToken(checkAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getActivateMail(id: string, options?: any) {
        return AuthenticationApiFp(this.configuration).getActivateMail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getLoggedInUserData(options?: any) {
        return AuthenticationApiFp(this.configuration).getLoggedInUserData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Login} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(login?: Login, options?: any) {
        return AuthenticationApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postActivateMail(id: string, options?: any) {
        return AuthenticationApiFp(this.configuration).postActivateMail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Register} [register] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public register(register?: Register, options?: any) {
        return AuthenticationApiFp(this.configuration).register(register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPassword} [resetPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public resetPassword(resetPassword?: ResetPassword, options?: any) {
        return AuthenticationApiFp(this.configuration).resetPassword(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunicationApi - axios parameter creator
 * @export
 */
export const CommunicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('index', 'guid', guid)
            const localVarPath = `/Communication/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunicationApi - functional programming interface
 * @export
 */
export const CommunicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async index(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.index(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunicationApi - factory interface
 * @export
 */
export const CommunicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunicationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        index(guid: string, options?: any): AxiosPromise<ViewResult> {
            return localVarFp.index(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunicationApi - object-oriented interface
 * @export
 * @class CommunicationApi
 * @extends {BaseAPI}
 */
export class CommunicationApi extends BaseAPI {
    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    public index(guid: string, options?: any) {
        return CommunicationApiFp(this.configuration).index(guid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFile', 'fileId', fileId)
            const localVarPath = `/File/Delete/{FileId}`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByGroupingId: async (groupingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupingId' is not null or undefined
            assertParamExists('deleteFileByGroupingId', 'groupingId', groupingId)
            const localVarPath = `/File/Delete/{GroupingId}/Group`
                .replace(`{${"GroupingId"}}`, encodeURIComponent(String(groupingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFromCatalog: async (catalogId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('getFilesFromCatalog', 'catalogId', catalogId)
            const localVarPath = `/File/Catalog/{CatalogId}`
                .replace(`{${"CatalogId"}}`, encodeURIComponent(String(catalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFile} [postFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFile: async (postFile?: PostFile, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFileCatalog} [postFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileCatalog: async (postFileCatalog?: PostFileCatalog, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFileCatalog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFileFromUrl} [postFileFromUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileFromUrl: async (postFileFromUrl?: PostFileFromUrl, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Upload/FromUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFileFromUrl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostImageFile} [postImageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageFile: async (postImageFile?: PostImageFile, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Upload/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postImageFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {number} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pullFile: async (fileId?: string, version?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Pull`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PutFile} [putFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFile: async (fileId: string, putFile?: PutFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('putFile', 'fileId', fileId)
            const localVarPath = `/File/Edit/{FileId}`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {PutFileCatalog} [putFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFileCatalog: async (catalogId: number, putFileCatalog?: PutFileCatalog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('putFileCatalog', 'catalogId', catalogId)
            const localVarPath = `/File/Catalog/{CatalogId}`
                .replace(`{${"CatalogId"}}`, encodeURIComponent(String(catalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putFileCatalog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileByGroupingId(groupingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileByGroupingId(groupingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesFromCatalog(catalogId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFilesFromCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesFromCatalog(catalogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFile} [postFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFile(postFile?: PostFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFile(postFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFileCatalog} [postFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFileCatalog(postFileCatalog?: PostFileCatalog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostFileCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFileCatalog(postFileCatalog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFileFromUrl} [postFileFromUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFileFromUrl(postFileFromUrl?: PostFileFromUrl, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFileFromUrl(postFileFromUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostImageFile} [postImageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postImageFile(postImageFile?: PostImageFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postImageFile(postImageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {number} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pullFile(fileId?: string, version?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pullFile(fileId, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PutFile} [putFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFile(fileId: string, putFile?: PutFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFile(fileId, putFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {PutFileCatalog} [putFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFileCatalog(catalogId: number, putFileCatalog?: PutFileCatalog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutFileCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFileCatalog(catalogId, putFileCatalog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(fileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByGroupingId(groupingId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFileByGroupingId(groupingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFromCatalog(catalogId: number, options?: any): AxiosPromise<GetFilesFromCatalogResponse> {
            return localVarFp.getFilesFromCatalog(catalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFile} [postFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFile(postFile?: PostFile, options?: any): AxiosPromise<PostFileResponse> {
            return localVarFp.postFile(postFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFileCatalog} [postFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileCatalog(postFileCatalog?: PostFileCatalog, options?: any): AxiosPromise<PostFileCatalogResponse> {
            return localVarFp.postFileCatalog(postFileCatalog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFileFromUrl} [postFileFromUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileFromUrl(postFileFromUrl?: PostFileFromUrl, options?: any): AxiosPromise<PostImageFileResponse> {
            return localVarFp.postFileFromUrl(postFileFromUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostImageFile} [postImageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageFile(postImageFile?: PostImageFile, options?: any): AxiosPromise<PostImageFileResponse> {
            return localVarFp.postImageFile(postImageFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {number} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pullFile(fileId?: string, version?: number, options?: any): AxiosPromise<any> {
            return localVarFp.pullFile(fileId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PutFile} [putFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFile(fileId: string, putFile?: PutFile, options?: any): AxiosPromise<PutFileResponse> {
            return localVarFp.putFile(fileId, putFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {PutFileCatalog} [putFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFileCatalog(catalogId: number, putFileCatalog?: PutFileCatalog, options?: any): AxiosPromise<PutFileCatalogResponse> {
            return localVarFp.putFileCatalog(catalogId, putFileCatalog, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFile(fileId: string, options?: any) {
        return FileApiFp(this.configuration).deleteFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFileByGroupingId(groupingId: string, options?: any) {
        return FileApiFp(this.configuration).deleteFileByGroupingId(groupingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} catalogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFilesFromCatalog(catalogId: number, options?: any) {
        return FileApiFp(this.configuration).getFilesFromCatalog(catalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFile} [postFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postFile(postFile?: PostFile, options?: any) {
        return FileApiFp(this.configuration).postFile(postFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFileCatalog} [postFileCatalog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postFileCatalog(postFileCatalog?: PostFileCatalog, options?: any) {
        return FileApiFp(this.configuration).postFileCatalog(postFileCatalog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFileFromUrl} [postFileFromUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postFileFromUrl(postFileFromUrl?: PostFileFromUrl, options?: any) {
        return FileApiFp(this.configuration).postFileFromUrl(postFileFromUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostImageFile} [postImageFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postImageFile(postImageFile?: PostImageFile, options?: any) {
        return FileApiFp(this.configuration).postImageFile(postImageFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileId] 
     * @param {number} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public pullFile(fileId?: string, version?: number, options?: any) {
        return FileApiFp(this.configuration).pullFile(fileId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {PutFile} [putFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public putFile(fileId: string, putFile?: PutFile, options?: any) {
        return FileApiFp(this.configuration).putFile(fileId, putFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} catalogId 
     * @param {PutFileCatalog} [putFileCatalog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public putFileCatalog(catalogId: number, putFileCatalog?: PutFileCatalog, options?: any) {
        return FileApiFp(this.configuration).putFileCatalog(catalogId, putFileCatalog, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BecomeAPatron} [becomeAPatron] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        becomeAPatron: async (becomeAPatron?: BecomeAPatron, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/Patron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(becomeAPatron, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentChannels: async (currency: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('getPaymentChannels', 'currency', currency)
            const localVarPath = `/Payments/Channels/{currency}`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patreonList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/Patron/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cmd] 
         * @param {string} [args] 
         * @param {string} [sign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatusChanged: async (cmd?: string, args?: string, sign?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/StatusChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (cmd !== undefined) {
                localVarQueryParameter['cmd'] = cmd;
            }

            if (args !== undefined) {
                localVarQueryParameter['args'] = args;
            }

            if (sign !== undefined) {
                localVarQueryParameter['sign'] = sign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BecomeAPatron} [becomeAPatron] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async becomeAPatron(becomeAPatron?: BecomeAPatron, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BecomeAPatronResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.becomeAPatron(becomeAPatron, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentChannels(currency: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentChannelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentChannels(currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patreonList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatreonListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patreonList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [cmd] 
         * @param {string} [args] 
         * @param {string} [sign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionStatusChanged(cmd?: string, args?: string, sign?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionStatusChanged(cmd, args, sign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {BecomeAPatron} [becomeAPatron] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        becomeAPatron(becomeAPatron?: BecomeAPatron, options?: any): AxiosPromise<BecomeAPatronResponse> {
            return localVarFp.becomeAPatron(becomeAPatron, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentChannels(currency: string, options?: any): AxiosPromise<GetPaymentChannelsResponse> {
            return localVarFp.getPaymentChannels(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patreonList(options?: any): AxiosPromise<GetPatreonListResponse> {
            return localVarFp.patreonList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [cmd] 
         * @param {string} [args] 
         * @param {string} [sign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatusChanged(cmd?: string, args?: string, sign?: string, options?: any): AxiosPromise<void> {
            return localVarFp.transactionStatusChanged(cmd, args, sign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {BecomeAPatron} [becomeAPatron] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public becomeAPatron(becomeAPatron?: BecomeAPatron, options?: any) {
        return PaymentsApiFp(this.configuration).becomeAPatron(becomeAPatron, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentChannels(currency: string, options?: any) {
        return PaymentsApiFp(this.configuration).getPaymentChannels(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public patreonList(options?: any) {
        return PaymentsApiFp(this.configuration).patreonList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [cmd] 
     * @param {string} [args] 
     * @param {string} [sign] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public transactionStatusChanged(cmd?: string, args?: string, sign?: string, options?: any) {
        return PaymentsApiFp(this.configuration).transactionStatusChanged(cmd, args, sign, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplication: async (saveApplication?: SaveApplication, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddApplicationRole} [addApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicationRole: async (addApplicationRole?: AddApplicationRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application/Role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addApplicationRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleSecurity: async (roleId: number, securityId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('addRoleSecurity', 'roleId', roleId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('addRoleSecurity', 'securityId', securityId)
            const localVarPath = `/User/Application/Role/{roleId}/RoleSecurity/{securityId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"securityId"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddSecurity} [addSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSecurity: async (addSecurity?: AddSecurity, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application/Role/RoleSecurity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSecurity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRole: async (addUserRole?: AddUserRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('deleteApplication', 'applicationId', applicationId)
            const localVarPath = `/User/Application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationRole: async (roleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteApplicationRole', 'roleId', roleId)
            const localVarPath = `/User/Application/Role/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleSecurity: async (roleId: number, securityId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRoleSecurity', 'roleId', roleId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('deleteRoleSecurity', 'securityId', securityId)
            const localVarPath = `/User/Application/Role/{roleId}/RoleSecurity/{securityId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"securityId"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecurity: async (securityId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('deleteSecurity', 'securityId', securityId)
            const localVarPath = `/User/Application/Role/Security/{securityId}`
                .replace(`{${"securityId"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRole: async (roleId: number, userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteUserRole', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserRole', 'userId', userId)
            const localVarPath = `/User/Role/{RoleId}/User/{UserId}`
                .replace(`{${"RoleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicationSecurities: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getAllApplicationSecurities', 'applicationId', applicationId)
            const localVarPath = `/User/Application/Securities/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationRoles: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationRoles', 'applicationId', applicationId)
            const localVarPath = `/User/Application/Role/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatistics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (applicationId: number, saveApplication?: SaveApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('updateApplication', 'applicationId', applicationId)
            const localVarPath = `/User/Application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {UpdateApplicationRole} [updateApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationRole: async (roleId: number, updateApplicationRole?: UpdateApplicationRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('updateApplicationRole', 'roleId', roleId)
            const localVarPath = `/User/Application/Role/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicationRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserData} [updateUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserData: async (updateUserData?: UpdateUserData, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplication(saveApplication?: SaveApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplication(saveApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddApplicationRole} [addApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplicationRole(addApplicationRole?: AddApplicationRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddApplicationRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplicationRole(addApplicationRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleSecurity(roleId: number, securityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleSecurity(roleId, securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddSecurity} [addSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSecurity(addSecurity?: AddSecurity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddSecurityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSecurity(addSecurity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserRole(addUserRole?: AddUserRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserRole(addUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplication(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplication(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationRole(roleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationRole(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleSecurity(roleId: number, securityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleSecurity(roleId, securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecurity(securityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSecurity(securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRole(roleId: number, userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRole(roleId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApplicationSecurities(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllApplicationSecuritiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllApplicationSecurities(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationRoles(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicationRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationRoles(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStatistics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(applicationId: number, saveApplication?: SaveApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(applicationId, saveApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {UpdateApplicationRole} [updateApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationRole(roleId: number, updateApplicationRole?: UpdateApplicationRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateApplicationRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationRole(roleId, updateApplicationRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserData} [updateUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserData(updateUserData?: UpdateUserData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserData(updateUserData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplication(saveApplication?: SaveApplication, options?: any): AxiosPromise<SaveApplicationResponse> {
            return localVarFp.addApplication(saveApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddApplicationRole} [addApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicationRole(addApplicationRole?: AddApplicationRole, options?: any): AxiosPromise<AddApplicationRoleResponse> {
            return localVarFp.addApplicationRole(addApplicationRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleSecurity(roleId: number, securityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.addRoleSecurity(roleId, securityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddSecurity} [addSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSecurity(addSecurity?: AddSecurity, options?: any): AxiosPromise<AddSecurityResponse> {
            return localVarFp.addSecurity(addSecurity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRole(addUserRole?: AddUserRole, options?: any): AxiosPromise<object> {
            return localVarFp.addUserRole(addUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication(applicationId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationRole(roleId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApplicationRole(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleSecurity(roleId: number, securityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteRoleSecurity(roleId, securityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecurity(securityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteSecurity(securityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRole(roleId: number, userId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserRole(roleId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicationSecurities(applicationId: number, options?: any): AxiosPromise<GetAllApplicationSecuritiesResponse> {
            return localVarFp.getAllApplicationSecurities(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationRoles(applicationId: number, options?: any): AxiosPromise<GetApplicationRolesResponse> {
            return localVarFp.getApplicationRoles(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(options?: any): AxiosPromise<GetApplicationsResponse> {
            return localVarFp.getApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedUser(options?: any): AxiosPromise<GetUserByIdResponse> {
            return localVarFp.getLoggedUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<GetRolesResponse> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any): AxiosPromise<GetUserByIdResponse> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatistics(options?: any): AxiosPromise<GetUserStatisticsResponse> {
            return localVarFp.getUserStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<GetUsersResponse> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(applicationId: number, saveApplication?: SaveApplication, options?: any): AxiosPromise<SaveApplicationResponse> {
            return localVarFp.updateApplication(applicationId, saveApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {UpdateApplicationRole} [updateApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationRole(roleId: number, updateApplicationRole?: UpdateApplicationRole, options?: any): AxiosPromise<UpdateApplicationRoleResponse> {
            return localVarFp.updateApplicationRole(roleId, updateApplicationRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserData} [updateUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserData(updateUserData?: UpdateUserData, options?: any): AxiosPromise<UpdateUserDataResponse> {
            return localVarFp.updateUserData(updateUserData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {SaveApplication} [saveApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addApplication(saveApplication?: SaveApplication, options?: any) {
        return UserApiFp(this.configuration).addApplication(saveApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddApplicationRole} [addApplicationRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addApplicationRole(addApplicationRole?: AddApplicationRole, options?: any) {
        return UserApiFp(this.configuration).addApplicationRole(addApplicationRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} securityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addRoleSecurity(roleId: number, securityId: number, options?: any) {
        return UserApiFp(this.configuration).addRoleSecurity(roleId, securityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddSecurity} [addSecurity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addSecurity(addSecurity?: AddSecurity, options?: any) {
        return UserApiFp(this.configuration).addSecurity(addSecurity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddUserRole} [addUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUserRole(addUserRole?: AddUserRole, options?: any) {
        return UserApiFp(this.configuration).addUserRole(addUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteApplication(applicationId: number, options?: any) {
        return UserApiFp(this.configuration).deleteApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteApplicationRole(roleId: number, options?: any) {
        return UserApiFp(this.configuration).deleteApplicationRole(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} securityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteRoleSecurity(roleId: number, securityId: number, options?: any) {
        return UserApiFp(this.configuration).deleteRoleSecurity(roleId, securityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} securityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteSecurity(securityId: number, options?: any) {
        return UserApiFp(this.configuration).deleteSecurity(securityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserRole(roleId: number, userId: number, options?: any) {
        return UserApiFp(this.configuration).deleteUserRole(roleId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllApplicationSecurities(applicationId: number, options?: any) {
        return UserApiFp(this.configuration).getAllApplicationSecurities(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getApplicationRoles(applicationId: number, options?: any) {
        return UserApiFp(this.configuration).getApplicationRoles(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getApplications(options?: any) {
        return UserApiFp(this.configuration).getApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getLoggedUser(options?: any) {
        return UserApiFp(this.configuration).getLoggedUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getRoles(options?: any) {
        return UserApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserStatistics(options?: any) {
        return UserApiFp(this.configuration).getUserStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(options?: any) {
        return UserApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {SaveApplication} [saveApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateApplication(applicationId: number, saveApplication?: SaveApplication, options?: any) {
        return UserApiFp(this.configuration).updateApplication(applicationId, saveApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {UpdateApplicationRole} [updateApplicationRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateApplicationRole(roleId: number, updateApplicationRole?: UpdateApplicationRole, options?: any) {
        return UserApiFp(this.configuration).updateApplicationRole(roleId, updateApplicationRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserData} [updateUserData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserData(updateUserData?: UpdateUserData, options?: any) {
        return UserApiFp(this.configuration).updateUserData(updateUserData, options).then((request) => request(this.axios, this.basePath));
    }
}


