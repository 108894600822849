import React from "react";
import { IAuthReduxState } from "../../../../business/Auth/AuthReducer";
import { Tag } from "../../../../client";
import { ArticleMenuItem } from "./ArticleMenuItem";

interface IArticleMenuComponentProps {
  auth: IAuthReduxState;
  tags: Tag[];
}

interface IArticleMenuComponentState {}

export class ArticleMenuComponent extends React.Component<
  IArticleMenuComponentProps,
  IArticleMenuComponentState
> {
  constructor(
    props: IArticleMenuComponentProps,
    state: IArticleMenuComponentState
  ) {
    super(props, state);

    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return <ArticleMenuItem tags={this.props.tags} auth={this.props.auth} />;
  }

  // private renderArticleSection = (): JSX.Element => {
  //   if (!this.isListVisible() || !this.props.tags) {
  //     return <></>;
  //   }
  //   return <ArticleMenuItem tags={this.props.tags} />;
  // };
  // private isListVisible = (): boolean => {
  //   return this.props.tags && this.props.tags != null;
  // };

  private getInitialState = (): IArticleMenuComponentState => {
    return {} as IArticleMenuComponentState;
  };
}
