import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { UpdateUserInfo } from '../../../../business/Profile/ProfileEpics';
import { navigationAction } from '../../../../business/router/router-actions';

export interface IEditProfileMenuProps {}
export const EditProfileMenu: React.FC<IEditProfileMenuProps> = (props) => {
	const dispatch = useDispatch();
	// const [password, setPassword] = React.useState<string>();

	const handleSubmit = () => {
		dispatch(UpdateUserInfo());
		dispatch(navigationAction.goBack());
	};

	const handleBack = (e: any) => {
		e.preventDefault();
		dispatch(navigationAction.goBack());
	};
	return (
		<div className="sh-section sh-menu sticky">
			<h2>UWAGA</h2>
			<p>Edytujesz profil</p>
			{/* <br />
      <TextField
        type="password"
        label="Wpisz hasło, aby zapisać"
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <br /> */}
			<br />
			<a href="#" className="sh-btn" onClick={handleSubmit}>
				Zapisz profil
			</a>
			<br />
			<Tooltip id="clear" title="Anuluj zmiany" placement="top">
				<a href="#" onClick={handleBack} className="sh-section__btn-delete sh-btn-icon">
					<i className="icon-Cancel"></i>
				</a>
			</Tooltip>
		</div>
	);
};
