import { Modal } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { authenticationActions } from '../../../../business/Auth/AuthActions';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { routes } from '../../../../business/router/path-definitions';
import { GetUserInfoResponse } from '../../../../client';
import { NotLoggedMenu } from '../NotLoggedMenu/NotLoggedMenu';

import { ReportViolation } from '../../Violations/ReportViolation';
export interface ILandingMenuItemProps {
	auth: IAuthReduxState;
	userInfo: GetUserInfoResponse;
}
export const ProfileMenuComponent: React.FC<ILandingMenuItemProps> = (props) => {
	const dispatch = useDispatch();
	const handleLogout = (event: any) => {
		event.preventDefault();
		dispatch(authenticationActions.logOutAction());
	};
	const [open, setOpen] = React.useState(false);

	const handleOpenModal = () => {
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};
	return (
		<div className="sh-menu sticky">
			{props.auth.token ?? false ? (
				<>
					<p>Cześć {props.auth.username} </p>
					{(Number(props.auth.userId) == props.userInfo.userProfile?.id && (
						<>
							{' '}
							<Link to={'/profile/' + props.auth.userId + '/edit'} className="sh-btn">
								<i className="icon-Tile_View"></i>Edytuj profil
							</Link>
							<Link
								to={'/profile/' + props.auth.userId + '/patreon'}
								className="sh-btn"
							>
								<i className="icon-Tongue_Out"></i>Zostań VIP'em
							</Link>
						</>
					)) || (
						<>
							<a href="#" className="sh-btn">
								Obserwuj tego użytkownika
							</a>

							<Link
								to={'/profile/' + props.userInfo.userProfile?.id + '/patreon'}
								className="sh-btn"
							>
								<i className="icon-Tongue_Out"></i>Podaruj VIP'a
							</Link>
							<a href="#" onClick={handleOpenModal} className="sh-btn">
								Zgłoś tego użytkownika
							</a>

							<Modal
								open={open}
								onClose={handleCloseModal}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
							>
								<ReportViolation
									userId={props.userInfo.userProfile?.id}
									userName={props.userInfo.userProfile?.userName ?? ''}
									close={handleCloseModal}
								></ReportViolation>
							</Modal>
						</>
					)}
					<Link to={routes.addArticle} className="sh-btn">
						<i className="icon-Post"></i>Nowa dramka
					</Link>
					<a href="#" className="sh-btn" onClick={(e) => handleLogout(e)}>
						<i className="icon-Arrow_Down"></i>Wyloguj
					</a>
					<Link to={routes.patreonList} className="sh-btn">
						<span className="icon-Cool emoticon">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
							<span className="path4"></span>
							<span className="path5"></span>
						</span>
						Patroni
					</Link>
				</>
			) : (
				<NotLoggedMenu />
			)}
		</div>
	);
};
