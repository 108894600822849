import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { authenticationActions } from '../../../../business/Auth/AuthActions';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { routes } from '../../../../business/router/path-definitions';
import { navigationAction } from '../../../../business/router/router-actions';
import { ArticleTag, Tag } from '../../../../client';
import { StyledChip } from '../../StyledMaterialUiComponents/StyledMaterialUiComponents';
import { MenuFooter } from '../MenuFooter/MenuFooter';
import { NotLoggedMenu } from '../NotLoggedMenu/NotLoggedMenu';

export interface IArticleMenuItemProps {
	auth: IAuthReduxState;
	tags: Tag[];
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			'& > *': {
				margin: theme.spacing(0.5)
			},
			paddingBottom: '5px'
		}
	})
);

export const ArticleMenuItem: React.FC<IArticleMenuItemProps> = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	return (
		<div className="sh-section sh-menu sticky">
			{props.auth.token ?? false ? (
				<>
					<p>Cześć {props.auth.username} </p>
					<h6>Tagi w tej dramce</h6>
					<div className={classes.root}>
						{props.tags.map((articleTag: ArticleTag) => {
							return (
								<Tooltip id="tag" title="Przejdź do tagu" placement="bottom">
									<StyledChip
										className="sh-btn"
										key="e"
										onClick={(e) => {
											dispatch(
												navigationAction.open(
													'/tag/' +
														articleTag.tag?.id +
														'/' +
														articleTag.tag?.name
												)
											);
										}}
										size="medium"
										variant="outlined"
										label={'# ' + articleTag.tag?.name}
									/>
								</Tooltip>
							);
						})}
					</div>

					<Link to={'/profile/' + props.auth.userId} className="sh-btn">
						<i className="icon-Followers"></i>Zobacz swój profil
					</Link>
					<Link to={routes.addArticle} className="sh-btn">
						<i className="icon-Arrow_Up"></i>Nowa dramka
					</Link>
					<Link to={routes.patreonList} className="sh-btn">
						<span className="icon-Cool emoticon">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
							<span className="path4"></span>
							<span className="path5"></span>
						</span>
						Patroni
					</Link>
					<a
						href="#"
						className="sh-btn"
						onClick={(e) => {
							e.preventDefault();
							dispatch(authenticationActions.logOutAction());
						}}
					>
						<i className="icon-Arrow_Down"></i>Wyloguj
					</a>
					<MenuFooter />
				</>
			) : (
				<>
					<h6>Tagi w tej dramce</h6>
					<div className={classes.root}>
						{props.tags.map((articleTag: ArticleTag) => {
							return (
								<Tooltip id="tag" title="Przejdź do tagu" placement="bottom">
									<StyledChip
										className="sh-btn"
										key="e"
										onClick={(e) => {
											dispatch(
												navigationAction.open(
													'/tag/' +
														articleTag.tag?.id +
														'/' +
														articleTag.tag?.name
												)
											);
										}}
										size="medium"
										variant="outlined"
										label={'# ' + articleTag.tag?.name}
									/>
								</Tooltip>
							);
						})}
					</div>
					<NotLoggedMenu />
				</>
			)}
		</div>
	);
};
