import * as React from 'react';
import * as Redux from 'redux';

import { AnyAction, Dispatch } from 'redux';

import { AddArticleMenuComponent } from './AddArticleMenuComponent';
import { AddArticleRemoved } from '../../../../business/Article/ArticleActions';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { RootState } from '../../../../business/root-store';
import { addOrUpdateArticle } from '../../../../business/Article/ArticleEpics';
import { authenticationActions } from '../../../../business/Auth/AuthActions';
import { connect } from 'react-redux';
import { getAuth } from '../../../../business/Auth/AuthSelectors';

interface IAddArticleMenuContainerReduxMergedProps {
	auth: IAuthReduxState;
}

interface IAddArticleMenuContainerOwnProps {}

interface IAddArticleMenuContainerDispatchProps {
	addOrUpdateArticle: () => void;
	AddArticleRemoved: () => void;
	logOutAction: () => void;
}

interface IAddArticleMenuContainerProps
	extends IAddArticleMenuContainerOwnProps,
		IAddArticleMenuContainerDispatchProps,
		IAddArticleMenuContainerReduxMergedProps {}

interface IAddArticleMenuComponentState {}

class AddArticleMenuContainer extends React.Component<
	IAddArticleMenuContainerProps,
	IAddArticleMenuComponentState
> {
	constructor(props: IAddArticleMenuContainerProps, state: IAddArticleMenuComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<AddArticleMenuComponent
				logOutAction={this.props.logOutAction}
				addOrUpdateArticle={this.props.addOrUpdateArticle}
				AddArticleRemoved={this.props.AddArticleRemoved}
				auth={this.props.auth}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IAddArticleMenuContainerOwnProps
	): IAddArticleMenuContainerReduxMergedProps => {
		return {
			auth: getAuth(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IAddArticleMenuContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					addOrUpdateArticle: addOrUpdateArticle,
					AddArticleRemoved: AddArticleRemoved,
					logOutAction: authenticationActions.logOutAction
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IAddArticleMenuComponentState => {
		return {} as IAddArticleMenuComponentState;
	};
}
export const AddArticleMenu = connect(
	AddArticleMenuContainer.mapStateToProps,
	AddArticleMenuContainer.mapDispatchToProps
)(AddArticleMenuContainer);
