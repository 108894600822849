import { FilePondErrorDescription, FilePondFile } from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';

export type ICustomFilePondProps = {
	description: string;
	image: string | FilePondFile | null;
	imageSaved: (image: FilePondFile) => void;
	imageRemoved: () => void;
};

registerPlugin(
	FilePondPluginImagePreview,
	FilePondPluginImageExifOrientation,
	FilePondPluginFileEncode
);

export const CustomFilePond: React.FC<ICustomFilePondProps> = (props) => {
	let files = undefined;
	if (typeof props.image === 'string') {
		files = [process.env.REACT_APP_API_URL + '/' + props.image];
	} else if (props.image?.file != null && props.image?.file != undefined) {
		files = [props.image?.file];
	}
	const handleRemove = () => {
		props.imageRemoved();
	};
	const handleAdd = (error: FilePondErrorDescription | null, file: FilePondFile) => {
		props.imageSaved(file);
	};
	return (
		<FilePond
			allowPaste={false}
			allowFileEncode={true}
			files={files}
			allowMultiple={false}
			onaddfile={handleAdd}
			onremovefile={handleRemove}
			name="files"
			labelIdle={props.description}
		/>
	);
};
