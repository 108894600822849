import {
	BottomNavigationAction,
	Checkbox,
	Chip,
	createStyles,
	createTheme,
	Fab,
	Tab,
	Tabs,
	TextField,
	Theme,
	withStyles
} from '@material-ui/core';

export const CustomTheme = createTheme({
	typography: {
		fontFamily: '"Rubik", sans-serif',
		fontSize: 18,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500
	},
	overrides: {
		MuiBackdrop: {
			root: {
				zIndex: 100,
				color: '#fff'
			}
		},
		MuiTooltip: {
			tooltip: {
				padding: '10px 15px',
				minWidth: '130px',
				color: '#555555',
				lineHeight: '1.7em',
				backgroundColor: 'white',
				border: 'none',
				borderRadius: '6px',
				boxShadow:
					'0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
				maxWidth: '200px',
				textAlign: 'center',
				fontFamily: '"Rubik", sans-serif',
				fontSize: '0.875em',
				fontStyle: 'normal',
				fontWeight: 400,
				textShadow: 'none',
				textTransform: 'none',
				letterSpacing: 'normal',
				wordBreak: 'normal',
				wordSpacing: 'normal',
				wordWrap: 'normal',
				whiteSpace: 'normal',
				lineBreak: 'auto'
			}
		}
	}
});

export const StyledBottomNavigationAction = withStyles({
	root: {
		paddingLeft: 0,
		paddingRight: 0
	},
	label: {
		fontFamily: '"Rubik", sans-serif',
		fontSize: '0.75rem',
		lineHeight: 'normal'
	},
	selected: {
		color: '#754668'
	}
})(BottomNavigationAction);

export const CustomColorCheckbox = withStyles({
	root: {
		color: '#754668',
		'&$checked': {
			color: '#754668'
		}
	},
	checked: {}
})(Checkbox);

export const CustomTextField = withStyles({
	root: {
		'& .MuiInputBase-input': {
			color: 'black' // Text color
		},
		'& .MuiInput-underline:before': {
			borderBottomColor: '#754668' // Semi-transparent underline
		},
		'& .MuiInput-underline:hover:before': {
			borderBottomColor: '#754668' // Solid underline on hover
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#754668' // Solid underline on focus
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: '#754668'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#754668'
			}
		}
	}
})(TextField);

export const CustomTextFieldBlack = withStyles({
	root: {
		'& .MuiInputBase-input': {
			color: 'black' // Text color
		},
		'& .MuiInput-underline:before': {
			borderBottomColor: 'black' // Semi-transparent underline
		},
		'& .MuiInput-underline:hover:before': {
			borderBottomColor: 'black' // Solid underline on hover
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'black' // Solid underline on focus
		}
	}
})(TextField);

export const StyledChip = withStyles({
	root: {
		'&&:hover': {
			backgroundColor: '#754668',
			filter: 'brightness(120%)'
		}
	},
	label: {
		fontSize: 20,
		'&:hover': {
			color: 'white'
		}
	},
	outlined: {
		'&:hover': {
			border: `1px solid #754668`
		}
	}
})(Chip);

export const CustomFab = withStyles({
	root: {
		backgroundColor: '#754668',
		margin: CustomTheme.spacing(1),
		position: 'fixed',
		bottom: CustomTheme.spacing(4),
		right: CustomTheme.spacing(4),
		zIndex: 1000,
		'&:hover': {
			backgroundColor: '#995c88'
		}
	}
})(Fab);

export const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
		backgroundColor: '#734566',
		color: 'white',
		borderRadius: '6px'
	},
	indicator: {
		backgroundColor: 'white'
	}
})(Tabs);

export const CustomTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: 'none',
			fontFamily: '"Rubik", sans-serif',
			'&:hover': {
				color: '#white',
				opacity: 1
			},
			'&$selected': {
				color: '#white',
				fontWeight: theme.typography.fontWeightMedium
			},
			'&:focus': {
				color: '#white'
			}
		},
		selected: {}
	})
)(Tab);
