import { Reducer } from "redux";
import { List } from "immutable";
import { Emoji, Patron } from "../../client";
import { Emojies_Saved, IEmojiesSavedAction, IPatronsSavedAction, Patrons_Saved } from "./StaticActions";

type ReducerActions = IEmojiesSavedAction | IPatronsSavedAction;

export interface IStaticReduxState {
  emojies: List<Emoji>;
  patreons: List<Patron>;
}

const INITIAL_STATE: IStaticReduxState = {
  emojies: List<Emoji>(),
  patreons: List<Patron>(),
};

export const StaticReducer: Reducer<IStaticReduxState, ReducerActions> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case Emojies_Saved:
      return handleEmojiesSaved(state, action);
      case Patrons_Saved:
        return handlePatronsSaved(state, action);
    default:
      return state;
  }
};

const handleEmojiesSaved = (
  state: IStaticReduxState,
  action: IEmojiesSavedAction
): IStaticReduxState => {
  return {
    ...state,
    emojies: List<Emoji>(action.emojies),
  };
};

const handlePatronsSaved = (
  state: IStaticReduxState,
  action: IPatronsSavedAction
): IStaticReduxState => {
  return {
    ...state,
    patreons: List<Patron>(action.patrons),
  };
};
