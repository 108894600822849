import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from '../../../assets/jss/material-kit-react/views/ArticlePage';
import { ArticleList } from '../../molecules/Article/ArticleList/ArticleListContainer';
import { ArticleSingle } from '../../molecules/Article/SingleArticle/ArticleSingleContainer';
import { AddComment } from '../../molecules/Comment/AddComment/AddComment';
import { CommentList } from '../../molecules/Comment/CommentList/CommentListContainer';
import { Header } from '../../molecules/Header/Header';
import { ArticleMenu } from '../../molecules/Menu/ArticleMenu/ArticleMenuContainer';
import PreviousNextArticle from '../../molecules/PreviousNextArticle/PreviousNextArticle';

const useStyles = makeStyles(styles);

export default function ArticlePage() {
	const classes = useStyles();
	return (
		<>
			<Header>
				<ArticleMenu />
			</Header>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url('/assets/img/bg7.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'top center'
				}}
			></div>
			<div className={classes.mainRaised}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
						<div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 col-12 sh-content-head">
							<PreviousNextArticle />
						</div>
						<div className="col-xl-3 d-none d-xxl-block"></div>
						<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block"></div>
					</div>

					<div className="row">
						<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
						<div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
							<ArticleSingle />
							<AddComment commentLenghtLimit={300} />
							<CommentList />
							<div className="d-xxl-none">
								<div className="sh-content-head-right">
									<h6>Inne ciekawe dramki</h6>
								</div>
								<ArticleList othersArticles />
							</div>
						</div>
						<div className="col-xl-3 d-none d-xxl-block">
							<ArticleList othersArticles />
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
							<ArticleMenu />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
