import * as React from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { AnyAction, Dispatch } from 'redux';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { getAuth } from '../../../../business/Auth/AuthSelectors';
import { RootState } from '../../../../business/root-store';
import { LandingMenuComponent } from './LandingMenuComponent';

interface ILandingMenuContainerReduxMergedProps {
	auth: IAuthReduxState;
}

interface ILandingMenuContainerOwnProps {}

interface ILandingMenuContainerDispatchProps {}

interface ILandingMenuContainerProps
	extends ILandingMenuContainerOwnProps,
		ILandingMenuContainerDispatchProps,
		ILandingMenuContainerReduxMergedProps {}

interface ILandingMenuComponentState {}

class LandingMenuContainer extends React.Component<
	ILandingMenuContainerProps,
	ILandingMenuComponentState
> {
	constructor(props: ILandingMenuContainerProps, state: ILandingMenuComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <LandingMenuComponent auth={this.props.auth} />;
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ILandingMenuContainerOwnProps
	): ILandingMenuContainerReduxMergedProps => {
		return {
			auth: getAuth(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ILandingMenuContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({}, dispatch)
		};
	};

	private getInitialState = (): ILandingMenuComponentState => {
		return {} as ILandingMenuComponentState;
	};
}
export const LandingMenu = connect(
	LandingMenuContainer.mapStateToProps,
	LandingMenuContainer.mapDispatchToProps
)(LandingMenuContainer);
