import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from '../../../assets/jss/material-kit-react/views/profilePage';
import { Header } from '../../molecules/Header/Header';
import { EditProfileMenu } from '../../molecules/Menu/EditProfileMenu/EditProfileMenu';
import { EditProfile } from '../../molecules/Profile/EditProfile/EditProfileContainer';

export interface IEditProfilePageItemProps {}
const useStyles = makeStyles(styles);

export const EditProfilePageComponent: React.FC<IEditProfilePageItemProps> = (props) => {
	const classes = useStyles();
	return (
		<>
			<Header>
				<EditProfileMenu />
			</Header>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url('/assets/img/bg7.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'top center'
				}}
			></div>
			<div className={classes.mainRaised}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
						<div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
							<EditProfile />
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
							<EditProfileMenu />
						</div>
						<div className="col-xl-1 d-none d-xl-block"></div>
					</div>
				</div>
			</div>
		</>
	);
};
