import {
	AddArticleRemoved,
	AddTagSaved,
	AllTagsSaved,
	ArticleItemRemoved,
	ArticleListLoadedMore,
	ArticleListSaved,
	ArticlePageCommentRemoved,
	ArticlePageRemoved,
	ArticlePageSaved,
	LikeDislike
} from './ArticleActions';
import { ArticleApi, Comment, DeleteArticle, FileApi } from '../../client';
import {
	getAddArticle,
	getAddArticleHadInitialSelector,
	getAddArticleImage,
	getAddArticleImageCounter,
	getArticleId
} from './ArticleSelectors';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { GetApiConfig } from '../api-client-config';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import { navigationAction } from '../router/router-actions';
import { registerPlugin } from 'react-filepond';

registerPlugin(FilePondPluginFileEncode);
//wykonujemy akcje do api oraz zapisujemy rezultat w storze przez wykonanie action

export const removeArticle =
	(articleItemId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		alert('usunieto w api');
		dispatch(ArticleItemRemoved(articleItemId));
		// api
		//   .articleGet() //article delete
		//   .then((x) => {
		//     dispatch(ArticleItemRemoved(articleItemId));
		//   })
		//   .catch((c) => {
		//     console.log(
		//       actions.add({
		//         message: "Cannot delete shopping list.",
		//         type: "error",
		//       })
		//     );
		//   });
	};

export const fetchArticleList =
	(
		id: number,
		userIdFilter?: number,
		tagIdFilter?: number
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		let sortBy = id === 0 ? 'CreatedDateUtc' : id === 1 ? 'ReactionsCount' : 'CommentsCount';
		api.getAllArticles(
			1,
			store.article.articleListPageSize,
			undefined,
			sortBy,
			store.article.articleListSortDirection,
			undefined,
			userIdFilter,
			tagIdFilter
		)
			.then((x) => {
				dispatch(
					ArticleListSaved(
						x.data.results!,
						x.data.count!,
						1,
						sortBy,
						x.data.results!.length
					)
				);
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};

export const fetchArticle =
	(id: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));

		api.getArticleByArticleId(id)
			.then((x) => {
				dispatch(ArticlePageSaved(x.data.article!));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};

export const deleteArticle =
	(deleteArticle: DeleteArticle): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));

		api.deleteArticle(deleteArticle)
			.then((x) => {
				dispatch(ArticlePageRemoved());
				dispatch(fetchArticleList(0));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};

export const deleteComment =
	(comment: Comment): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));

		api.deleteComment({ comment: comment })
			.then((x) => {
				dispatch(ArticlePageCommentRemoved(comment.id!));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};

export const addOrUpdateArticle =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const articleApi = new ArticleApi(GetApiConfig(store));
		const fileApi = new FileApi(GetApiConfig(store));
		const addArticle = { ...getAddArticle(store.article) };
		const addArticleImage = getAddArticleImage(store.article);
		let imageCounter = getAddArticleImageCounter(store.article);
		const hadInitial = getAddArticleHadInitialSelector(store.article);
		if (hadInitial) {
			imageCounter = imageCounter - 1;
		}
		if (addArticleImage != null && imageCounter > 0) {
			const imageBase64 = (addArticleImage as any).getFileEncodeBase64String();
			fileApi
				.postFileCatalog({ name: addArticle.topic })
				.then((x) => {
					return fileApi.postImageFile({
						catalogId: x.data.catalogId,
						name: addArticle.topic + '.jpg',
						resize: true,
						width: 700,
						height: 700,
						imageData: imageBase64,
						generateThumbminal: true,
						keepResizeRatio: true,
						thumbminalHeight: 300,
						thumbminalWidth: 300
					});
				})
				.then((u) => {
					var original = u.data.fileInfos?.find(
						(u) => u.fileType?.toString() == 'OriginalImage'
					);
					addArticle.pictureOriginal = original?.url;
					addArticle.pictureThumbnail = u.data.fileInfos?.find(
						(u) => u.fileType?.toString() == 'ThumbnailImage'
					)?.url;
					addArticle.pictureModified = u.data.fileInfos?.find(
						(u) => u.fileType?.toString() == 'ModifiedImage'
					)?.url;
					addArticle.pictureLabel = original?.label;
					addArticle.pictureScore = original?.score;
					addArticle.pictureSource = original?.source;
				})
				.then(() => {
					return articleApi.addOrUpdateArticle(addArticle).then((s) => {
						dispatch(fetchArticle(s.data.article?.id!));
						dispatch(fetchArticleList(0));
						dispatch(AddArticleRemoved());
						return s;
					});
				})
				.then((x) => {
					dispatch(
						navigationAction.open(
							'/article/' + x.data.article?.id + '/' + x.data.article?.topic
						)
					);
				});
		} else {
			articleApi
				.addOrUpdateArticle(addArticle)
				.then((x) => {
					dispatch(fetchArticle(x.data.article?.id!));
					dispatch(fetchArticleList(0));
					dispatch(AddArticleRemoved());
					return x;
				})
				.then((x) => {
					dispatch(
						navigationAction.open(
							'/article/' + x.data.article?.id + '/' + x.data.article?.topic
						)
					);
				});
		}
	};

export const addTag =
	(tagName: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		api.addTag(tagName)
			.then((x) => {
				dispatch(AddTagSaved(x.data.tag!));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};

export const previousArticle =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		let index = store.article.articleList.findIndex(
			(x) => x.id === store.article.articlePage.id
		);
		if (index >= 1) {
			index = index - 1;
		}
		const id = store.article.articleList.toArray()[index].id!;
		api.getArticleByArticleId(id)
			.then((x) => {
				dispatch(ArticlePageSaved(x.data.article!));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};

export const nextArticle = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
	const store: RootState = getState();
	const api = new ArticleApi(GetApiConfig(store));
	let index = store.article.articleList.findIndex((x) => x.id === store.article.articlePage.id);
	if (index < store.article.articleList.size) {
		index = index + 1;
	}
	const id = store.article.articleList.toArray()[index].id!;
	api.getArticleByArticleId(id)
		.then((x) => {
			dispatch(ArticlePageSaved(x.data.article!));
		})
		.catch((c) => {
			console.log(
				actions.add({
					message: 'Cannot fetch shopping list.',
					type: 'error'
				})
			);
		});
};

export const fetchAllTags = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
	const store: RootState = getState();
	const api = new ArticleApi(GetApiConfig(store));

	api.getAllTags()
		.then((x) => {
			dispatch(AllTagsSaved(x.data.tags!));
		})
		.catch((c) => {
			console.log(
				actions.add({
					message: 'Cannot fetch shopping list.',
					type: 'error'
				})
			);
		});
};

export const addArticleComment =
	(
		content: string,
		parentCommentId?: number,
		commentToEditId?: number
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		const articleId = getArticleId(store.article);
		api.addOrUpdateComment({
			articleId: articleId,
			content: content,
			parentCommentId: parentCommentId,
			commentId: commentToEditId
		})
			.then((x) => {
				dispatch(fetchArticle(articleId!));
				dispatch(fetchArticleList(0));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch comments list.',
						type: 'error'
					})
				);
			});
	};

export const loadMoreArticles =
	(userIdFilter?: number, tagIdFilter?: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		var pageToFetch = store.article.articleListCurrentPage + 1;
		api.getAllArticles(
			pageToFetch,
			store.article.articleListPageSize,
			undefined,
			store.article.articleListSortField,
			store.article.articleListSortDirection,
			undefined,
			userIdFilter,
			tagIdFilter
		)
			.then((x) => {
				dispatch(
					ArticleListLoadedMore(
						x.data.results!,
						x.data.count!,
						pageToFetch,
						x.data.results!.length
					)
				);
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch article list.',
						type: 'error'
					})
				);
			});
	};

export const upVote =
	(statisticsId: number, currentLike: boolean): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		api.addOrUpdateLikeDislike(statisticsId, !currentLike, false)
			.then(() => {
				dispatch(LikeDislike(statisticsId, !currentLike, false));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch comments list.',
						type: 'error'
					})
				);
			});
	};

export const downVote =
	(statisticsId: number, currentDislike: boolean): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		api.addOrUpdateLikeDislike(statisticsId, false, !currentDislike)
			.then(() => {
				dispatch(LikeDislike(statisticsId, false, !currentDislike));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch comments list.',
						type: 'error'
					})
				);
			});
	};

export const reportUser =
	(
		reporter: string,
		whatsWrong: string,
		userId?: number,
		userName?: string,
		articleId?: number,
		commentId?: number
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		var id = commentId ?? articleId ?? userId;
		var type = commentId ? 'Comment' : articleId ? 'Article' : userId ? 'User' : 'BadRequest';
		api.reportViolation({
			reporterMail: reporter,
			whatsWrong: whatsWrong,
			id: id,
			type: type
		}).catch((c) => {
			console.log(
				actions.add({
					message: 'Cannot fetch comments list.',
					type: 'error'
				})
			);
		});
	};
