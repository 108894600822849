import {
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth,
} from "../../material-kit-react";
import { createStyles, Theme } from "@material-ui/core/styles";

const headerStyle = (theme: Theme) =>
  createStyles({
    list: {
      ...defaultFont,
      fontSize: "14px",
      margin: 0,
      paddingLeft: "0",
      listStyle: "none",
      paddingTop: "0",
      paddingBottom: "0",
      color: "inherit",
    },
    listItem: {
      float: "left",
      color: "inherit",
      position: "relative",
      display: "block",
      width: "auto",
      margin: "0",
      padding: "0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: "#e5e5e5",
        },
      },
    },

    white: {
      backgroundColor: "#fff !important",
      boxShadow:
        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15) !important",
      color: "black !important",
    },
    appBar: {
      position: "fixed",
      zIndex: 1100,
      display: "flex",
      paddingTop: "1.5vh",
      paddingBottom: "1vh",
      color: "white",
      width: "100%",
      backgroundColor: "transparent",
      boxShadow: "none",
      transition: "all 150ms ease 0s",
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
    },
    absolute: {
      position: "absolute",
      zIndex: 1100,
    },
    fixed: {
      position: "fixed",
      zIndex: 1100,
    },
    container: {
      minHeight: "50px",
      flex: "1",
      display: "flex",
      flexWrap: "nowrap",
    },
    flex: {
      flex: 1,
    },
    title: {
      ...defaultFont,
      paddingRight: 1,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 0,
      fontWeight: 300,
      whiteSpace: "nowrap",
      borderRadius: "6px",
      textTransform: "none",
      color: "inherit",
      letterSpacing: "unset",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent",
      },
    },
    underlineBlack: {
      "&:before": {
        borderBottomColor: "grey !important",
      },
      "&:hover:before": {
        borderBottomColor: "black !important",
      },
      "&:after": {
        borderBottomColor: "black !important",
      },
    },
    appResponsive: {
      margin: "20px 10px",
    },
    primary: {
      backgroundColor: primaryColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
    },
    info: {
      backgroundColor: infoColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
    },
    success: {
      backgroundColor: successColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
    },
    warning: {
      backgroundColor: warningColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
    },
    danger: {
      backgroundColor: dangerColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
    },
    rose: {
      backgroundColor: roseColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)",
    },
    dark: {
      color: "#FFFFFF",
      backgroundColor: "#212121 !important",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
    },
    blackInput: {
      color: "black !important",
    },
    drawerPaper: {
      border: "none",
      bottom: "0",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      ...transition,
    },
    autocomplete: {
      width: "80%",
      margin: "0 auto",
      paddingLeft: 10,
      paddingRight: 10,
    },
  });

export default headerStyle;
