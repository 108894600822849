import * as React from 'react';
import * as Redux from 'redux';

import {
	AddArticleContentSaved,
	AddArticleImageHadInitialSaved,
	AddArticleImageRemoved,
	AddArticleImageSaved,
	AddArticleRemoved
} from '../../../../business/Article/ArticleActions';
import { AddOrUpdateArticle, Tag } from '../../../../client';
import { AnyAction, Dispatch } from 'redux';
import { addTag, fetchAllTags } from '../../../../business/Article/ArticleEpics';
import {
	getAddArticle,
	getAddArticleImage,
	getAllTags
} from '../../../../business/Article/ArticleSelectors';

import { AddArticleComponent } from './AddArticleComponent';
import { FilePondFile } from 'filepond';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';

interface IAddArticleContainerReduxMergedProps {
	allTags: List<Tag>;
	addArticle: AddOrUpdateArticle;
	addArticleImage: string | FilePondFile | null;
}

interface IAddArticleContainerOwnProps {}

interface IAddArticleContainerDispatchProps {
	GetAllTags: () => void;
	AddTag: (tagName: string) => void;
	AddArticleImageSaved: (addArticleImage: FilePondFile) => void;
	AddArticleContentSaved: (content: string) => void;
	AddArticleImageRemoved: () => void;
	AddArticleRemoved: () => void;
	AddArticleImageHadInitialSaved: (addArticleHadinitial: boolean) => void;
}

interface IAddArticleContainerProps
	extends IAddArticleContainerOwnProps,
		IAddArticleContainerDispatchProps,
		IAddArticleContainerReduxMergedProps {}

interface IAddArticleComponentState {}

class AddArticleContainer extends React.Component<
	IAddArticleContainerProps,
	IAddArticleComponentState
> {
	constructor(props: IAddArticleContainerProps, state: IAddArticleComponentState) {
		super(props, state);

		this.state = this.getInitialState();
		this.props.GetAllTags();
	}

	componentWillUnmount() {
		this.props.AddArticleRemoved();
	}

	public render(): JSX.Element {
		return (
			<AddArticleComponent
				addArticleImage={this.props.addArticleImage}
				addArticle={this.props.addArticle}
				allTags={this.props.allTags}
				AddTag={this.props.AddTag}
				AddArticleImageSaved={this.props.AddArticleImageSaved}
				AddArticleContentSaved={this.props.AddArticleContentSaved}
				AddArticleImageRemoved={this.props.AddArticleImageRemoved}
				AddArticleImageHadInitialSaved={this.props.AddArticleImageHadInitialSaved}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IAddArticleContainerOwnProps
	): IAddArticleContainerReduxMergedProps => {
		return {
			allTags: getAllTags(state.article),
			addArticle: getAddArticle(state.article),
			addArticleImage: getAddArticleImage(state.article)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IAddArticleContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					GetAllTags: fetchAllTags,
					AddTag: addTag,
					AddArticleImageSaved: AddArticleImageSaved,
					AddArticleContentSaved: AddArticleContentSaved,
					AddArticleImageRemoved: AddArticleImageRemoved,
					AddArticleRemoved: AddArticleRemoved,
					AddArticleImageHadInitialSaved: AddArticleImageHadInitialSaved
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IAddArticleComponentState => {
		return {} as IAddArticleComponentState;
	};
}
export const AddArticle = connect(
	AddArticleContainer.mapStateToProps,
	AddArticleContainer.mapDispatchToProps
)(AddArticleContainer);
