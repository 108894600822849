import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { Link } from 'react-router-dom';
import { ArticleListItem } from '../../../../client';
import { Dropshare } from '../../Dropshare/Dropshare';

export interface IArticleItemProps {
	article: ArticleListItem;
	removeArticle: (articleId: number) => void;
	upVote: (statisticsId: number, currentLike: boolean) => void;
	downVote: (statisticsId: number, currentDislike: boolean) => void;
}

export const ArticleItem: React.FC<IArticleItemProps> = (props) => {
	const createdDate = moment.utc(props.article.createdDateUtc!).fromNow();
	return (
		<div className="sh-section">
			<div className="sh-section__head">
				<div className="sh-avatar">
					<img
						src={
							props.article.authorAvatar
								? process.env.REACT_APP_API_URL + '/' + props.article.authorAvatar
								: '/assets/img/screamingfear.gif'
						}
						alt=""
					/>
				</div>
				<div>
					<Tooltip id="user" title="Przejdź do profilu użytkownika" placement="top">
						<Link
							to={'/profile/' + props.article.authorUserId}
							className="sh-section__name"
						>
							{props.article.authorUserName} {props.article.patreon! > 0 && <>💥</>}
						</Link>
					</Tooltip>
					<Tooltip
						id="date"
						title={moment.utc(props.article.createdDateUtc!).local().format('LLL')}
						placement="bottom"
					>
						<span className="sh-section__passed">{createdDate}</span>
					</Tooltip>
				</div>
				<Dropshare
					linkToCopy={'/article/' + props.article.id + '/' + props.article.topic}
				/>
			</div>
			<div className="sh-section__content">
				<Tooltip id="article" title="Przejdź do dramki" placement="top-start">
					<Link to={'/article/' + props.article.id + '/' + props.article.topic}>
						<p className="sh-section__text">{props.article.topic}</p>
					</Link>
				</Tooltip>
				{props.article.pictureModified != null && (
					<div className="sh-section__image">
						<Link to={'/article/' + props.article.id + '/' + props.article.topic}>
							<img
								className="img-fluid"
								src={
									process.env.REACT_APP_API_URL +
									'/' +
									props.article?.pictureModified
								}
								alt={
									props?.article?.pictureLabel +
									' ' +
									props?.article?.pictureScore +
									'; ' +
									props?.article?.pictureSource
								}
							/>
						</Link>
					</div>
				)}
			</div>
			<div className="sh-section__footer">
				<Tooltip id="upVote" title="Daj lub usuń lajka" placement="top">
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							props.upVote(props.article.statisticsId!, props.article.userLiked!);
						}}
						className={
							props.article.userLiked
								? 'sh-section__btn-upvote sh-btn-icon active'
								: 'sh-section__btn-upvote sh-btn-icon'
						}
					>
						<i className="icon-Upvote"></i>
					</a>
				</Tooltip>

				<Tooltip id="downVote" title="Daj lub usuń dislajka" placement="top">
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							props.downVote(
								props.article.statisticsId!,
								props.article.userDisliked!
							);
						}}
						className={
							props.article.userDisliked
								? 'sh-section__btn-downvote sh-btn-icon active'
								: 'sh-section__btn-downvote sh-btn-icon'
						}
					>
						<i className="icon-Downvote"></i>
					</a>
				</Tooltip>

				<Tooltip
					id="averageLikes"
					title={
						'Średnia ilość lajków. ' +
						props.article.likes +
						' lajki, ' +
						props.article.dislikes +
						' dislajki.'
					}
					placement="top"
				>
					<div className="sh-section__btn-like sh-btn-icon">
						<i className="icon-Favorite_Full"></i>
						<span>{(props.article.likes ?? 0) - (props.article.dislikes ?? 0)}</span>
					</div>
				</Tooltip>

				<Tooltip id="comments" title="Ilość komentarzy" placement="top">
					<Link
						to={'/article/' + props.article.id + '/' + props.article.topic}
						className="sh-section__btn-comment sh-btn-icon"
					>
						<i className="icon-Comment_Full"></i>
						<span>{props.article.commentsCount}</span>
					</Link>
				</Tooltip>
				{/* <Tooltip
          id="options"
          title="Wyświetl opcje"
          placement="top"
          
        >
          <a href="#" className="sh-section__btn-options sh-btn-icon">
            <i className="icon-Options"></i>
          </a>
        </Tooltip> */}
			</div>
		</div>
	);
};
