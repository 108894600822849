import { FilePondFile } from 'filepond';
import { AddOrUpdateArticle, Article, ArticleListItem, Tag } from '../../client';

export const AddArticleTags_Saved = '@@Dramki/AddArticleTags/SAVED';
export const AddArticleTag_Saved = '@@Dramki/AddArticleTag/SAVED';
export const AddArticleContent_Saved = '@@Dramki/AddArticleContent/SAVED';
export const AddArticleImageHadInitial_Saved = '@@Dramki/AddArticleImageHadInitial/SAVED';
export const AddArticleTopic_Saved = '@@Dramki/AddArticleTopic/SAVED';
export const AddArticleImage_Saved = '@@Dramki/AddArticleImage/SAVED';
export const AddArticleImage_Removed = '@@Dramki/AddArticleImage/REMOVED';
export const AddArticleImageCounter_Removed = '@@Dramki/AddArticleImageCounter/REMOVED';
export const AllTags_Saved = '@@Dramki/Tags/SAVED';
export const AddTag_Saved = '@@Dramki/Tag/SAVED';
export const ArticlePage_Saved = '@@Dramki/ArticlePage/SAVED';
export const ArticleList_Saved = '@@Dramki/ArticleList/SAVED';
export const ArticleList_Removed = '@@Dramki/ArticleList/REMOVED';
export const ArticleListLoadMore_Saved = '@@Dramki/ArticleList/LOADMORE/SAVED';
export const ArticleItem_Removed = '@@Dramki/ArticleItem/REMOVED';
export const AddArticle_Saved = '@@Dramki/AddArticle/SAVED';
export const AddArticle_Removed = '@@Dramki/AddArticle/REMOVED';
export const ArticlePage_Removed = '@@Dramki/ArticlePage/REMOVED';
export const ArticlePageComment_Removed = '@@Dramki/ArticlePageComment/REMOVED';
export const LikeDislike_Saved = '@@Dramki/LikeDislike/SAVED';
export const AddArticleId_Saved = '@@Dramki/AddArticleId/SAVED';

export interface ILikeDislikeSavedAction {
	type: typeof LikeDislike_Saved;
	statisticsId: number;
	like: boolean;
	dislike: boolean;
}

export const LikeDislike = (
	statisticsId: number,
	like: boolean,
	dislike: boolean
): ILikeDislikeSavedAction => ({
	type: LikeDislike_Saved,
	statisticsId,
	like,
	dislike
});

export interface IArticleListSavedAction {
	type: typeof ArticleList_Saved;
	articles: ArticleListItem[];
	count: number;
	page: number;
	sortBy: string;
	pageItemsCount?: number;
}

export const ArticleListSaved = (
	articles: ArticleListItem[],
	count: number,
	page: number,
	sortBy: string,
	pageItemsCount?: number
): IArticleListSavedAction => ({
	type: ArticleList_Saved,
	articles: articles,
	count: count,
	page: page,
	pageItemsCount,
	sortBy
});

export interface IArticleListLoadedMoreAction {
	type: typeof ArticleListLoadMore_Saved;
	articles: ArticleListItem[];
	count: number;
	page: number;
	pageItemsCount?: number;
}

export const ArticleListLoadedMore = (
	articles: ArticleListItem[],
	count: number,
	page: number,
	pageItemsCount?: number
): IArticleListLoadedMoreAction => ({
	type: ArticleListLoadMore_Saved,
	articles,
	count,
	page,
	pageItemsCount
});

export interface IArticleListSavedAction {
	type: typeof ArticleList_Saved;
	articles: ArticleListItem[];
	count: number;
	page: number;
	sortBy: string;
	pageItemsCount?: number;
}

export interface IAddArticleTopicSavedAction {
	type: typeof AddArticleTopic_Saved;
	addArticleTopic: string;
}

export const AddArticleTopicSaved = (addArticleTopic: string): IAddArticleTopicSavedAction => ({
	type: AddArticleTopic_Saved,
	addArticleTopic
});

export interface IAddArticleContentSavedAction {
	type: typeof AddArticleContent_Saved;
	addArticleContent: string;
}

export const AddArticleContentSaved = (
	addArticleContent: string
): IAddArticleContentSavedAction => ({
	type: AddArticleContent_Saved,
	addArticleContent
});

export interface IAddArticleImageHadInitialSavedAction {
	type: typeof AddArticleImageHadInitial_Saved;
	addArticleImageHadInitial: boolean;
}

export const AddArticleImageHadInitialSaved = (
	addArticleImageHadInitial: boolean
): IAddArticleImageHadInitialSavedAction => ({
	type: AddArticleImageHadInitial_Saved,
	addArticleImageHadInitial
});

export interface IAddArticleTagsSavedAction {
	type: typeof AddArticleTags_Saved;
	addArticleTags: Array<Tag>;
}

export const AddArticleTagsSaved = (addArticleTags: Array<Tag>): IAddArticleTagsSavedAction => ({
	type: AddArticleTags_Saved,
	addArticleTags
});

export interface IAddArticleImageSavedAction {
	type: typeof AddArticleImage_Saved;
	addArticleImage: FilePondFile | string | null;
}

export const AddArticleImageSaved = (
	addArticleImage: FilePondFile | string | null
): IAddArticleImageSavedAction => ({
	type: AddArticleImage_Saved,
	addArticleImage: addArticleImage
});

export interface IAddArticleImageRemovedAction {
	type: typeof AddArticleImage_Removed;
}

export const AddArticleImageRemoved = (): IAddArticleImageRemovedAction => ({
	type: AddArticleImage_Removed
});

export interface IAddArticleImageCounterRemovedAction {
	type: typeof AddArticleImageCounter_Removed;
}

export const AddArticleImageCounterRemoved = (): IAddArticleImageCounterRemovedAction => ({
	type: AddArticleImageCounter_Removed
});

export interface IAddArticleTagSavedAction {
	type: typeof AddArticleTag_Saved;
	addArticleTag: Tag;
}

export const AddArticleTagSaved = (addArticleTag: Tag): IAddArticleTagSavedAction => ({
	type: AddArticleTag_Saved,
	addArticleTag
});

export interface IAddArticleSavedAction {
	type: typeof AddArticle_Saved;
	addArticle: AddOrUpdateArticle;
}

export const AddArticleSaved = (addArticle: AddOrUpdateArticle): IAddArticleSavedAction => ({
	type: AddArticle_Saved,
	addArticle
});

export interface IAddTagSavedAction {
	type: typeof AddTag_Saved;
	addTag: Tag;
}

export const AddTagSaved = (addTag: Tag): IAddTagSavedAction => ({
	type: AddTag_Saved,
	addTag
});

export interface IArticleItemRemovedAction {
	type: typeof ArticleItem_Removed;
	articleItemId: number;
}

export const ArticleItemRemoved = (articleItemId: number): IArticleItemRemovedAction => ({
	type: ArticleItem_Removed,
	articleItemId
});

export interface IAddArticleRemovedAction {
	type: typeof AddArticle_Removed;
}

export const AddArticleRemoved = (): IAddArticleRemovedAction => ({
	type: AddArticle_Removed
});

export interface IArticlePageSavedAction {
	type: typeof ArticlePage_Saved;
	articlePage: Article;
}

export const ArticlePageSaved = (articlePage: Article): IArticlePageSavedAction => ({
	type: ArticlePage_Saved,
	articlePage
});

export interface IArticlePageRemovedAction {
	type: typeof ArticlePage_Removed;
}

export const ArticlePageRemoved = (): IArticlePageRemovedAction => ({
	type: ArticlePage_Removed
});

export interface IArticlePageCommentRemovedAction {
	type: typeof ArticlePageComment_Removed;
	commentId: number;
}

export const ArticlePageCommentRemoved = (commentId: number): IArticlePageCommentRemovedAction => ({
	type: ArticlePageComment_Removed,
	commentId
});

export interface IAddArticleIdSavedAction {
	type: typeof AddArticleId_Saved;
	articleId: number;
}

export const AddArticleIdSaved = (articleId: number): IAddArticleIdSavedAction => ({
	type: AddArticleId_Saved,
	articleId
});

export interface IAllTagsSaveAction {
	type: typeof AllTags_Saved;
	allTags: Tag[];
}

export const AllTagsSaved = (allTags: Tag[]): IAllTagsSaveAction => ({
	type: AllTags_Saved,
	allTags
});
