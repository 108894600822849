import { Configuration } from "../client";
import { RootState } from "./root-store";

export const GetApiConfig = (state: RootState) => {
  const token = state.auth.token || "";

  if (token != undefined) {
    return new Configuration({
      basePath: process.env.REACT_APP_API_URL,
      apiKey: "Bearer " + token,
    });
  } else {
    return new Configuration({
      basePath: process.env.REACT_APP_API_URL,
    });
  }
};
