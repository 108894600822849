import DateMomentUtils from '@date-io/moment';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/Https';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import classNames from 'classnames';
import React from 'react';
import 'react-datetime/css/react-datetime.css';
import { useDispatch } from 'react-redux';
import styles from '../../../assets/jss/material-kit-react/views/loginPage';
import { logIn, registerNewUserApi } from '../../../business/Auth/AuthEpics';
import CardBody from '../../molecules/Card/CardBody';
import CardFooter from '../../molecules/Card/CardFooter';
import CustomTabs from '../../molecules/CustomTabs/CustomTabs';
import { Header } from '../../molecules/Header/Header';
import { routes } from '../../../business/router/path-definitions';

const useStyles = makeStyles(styles);
export default function LoginRegisterPage(index?: number) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [registerState, setRegisterState] = React.useState<{
		login?: string;
		mail?: string;
		password?: string;
		repeatPassword?: string;
		birthDate?: MaterialUiPickersDate;
		terms?: boolean;
	}>({ birthDate: null });
	const [loginState, setLoginState] = React.useState<{
		login?: string;
		password?: string;
	}>();
	const [datePickerState, setdatePickerState] = React.useState<boolean>(false);
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRegisterState({
			...registerState,
			terms: event.target.checked
		});
	};
	const handleRegister = (event: any) => {
		event.preventDefault();
		dispatch(
			registerNewUserApi(
				registerState?.login!,
				registerState?.mail!,
				registerState?.password!,
				registerState?.repeatPassword!,
				registerState?.birthDate!
			)
		);
	};
	const handleLogin = (event: any) => {
		event.preventDefault();
		dispatch(logIn(loginState?.login!, loginState?.password!));
	};
	var maxDate = new Date();
	maxDate.setDate(maxDate.getDate());
	maxDate.setFullYear(maxDate.getFullYear() - 8);
	var minDate = new Date();
	minDate.setDate(maxDate.getDate());
	minDate.setFullYear(maxDate.getFullYear() - 100);
	return (
		<>
			<Header>{/* LoginregisterMenu */}</Header>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url('/assets/img/bg7.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'top center'
				}}
			></div>
			<div className={classNames(classes.mainRaised)}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-3 col-sm-3"></div>
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div>
								<CustomTabs
									startIndex={index}
									headerColor="dramki"
									tabs={[
										{
											tabName: 'Logowanie',
											tabContent: (
												<form
													className={classes.form}
													onSubmit={(e) => handleLogin(e)}
												>
													<CardBody>
														<TextField
															required
															onChange={(e: any) =>
																setLoginState({
																	...loginState,
																	login: e.target.value
																})
															}
															label="Login"
															id="login"
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<AccountBoxOutlinedIcon />
																	</InputAdornment>
																)
															}}
														/>
														<TextField
															required
															type="password"
															onChange={(e: any) =>
																setLoginState({
																	...loginState,
																	password: e.target.value
																})
															}
															label="Hasło"
															id="password"
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<LockOutlinedIcon />
																	</InputAdornment>
																)
															}}
														/>
													</CardBody>
													<CardFooter className={classes.cardFooter}>
														<Button
															type="submit"
															// type="submit"
															// simple
															// color="primary"
															// size="large"
														>
															Zaloguj
														</Button>
													</CardFooter>
												</form>
											)
										},
										{
											tabName: 'Rejestracja',
											tabContent: (
												<form
													className={classes.form}
													onSubmit={(e) => handleRegister(e)}
												>
													<CardBody>
														<TextField
															required
															onChange={(e: any) =>
																setRegisterState({
																	...registerState,
																	login: e.target.value
																})
															}
															label="Login"
															id="login"
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<AccountBoxOutlinedIcon />
																	</InputAdornment>
																)
															}}
														/>
														<TextField
															required
															onChange={(e: any) =>
																setRegisterState({
																	...registerState,
																	mail: e.target.value
																})
															}
															label="Mail"
															id="email"
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<MailOutlineIcon />
																	</InputAdornment>
																)
															}}
														/>
														<TextField
															type="password"
															onChange={(e: any) =>
																setRegisterState({
																	...registerState,
																	password: e.target.value
																})
															}
															label="Hasło"
															required
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<LockOutlinedIcon />
																	</InputAdornment>
																)
															}}
														/>
														<TextField
															type="password"
															onChange={(e: any) =>
																setRegisterState({
																	...registerState,
																	repeatPassword: e.target.value
																})
															}
															label="Powtórz hasło"
															required
															fullWidth
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<LockOpenIcon />
																	</InputAdornment>
																)
															}}
														/>
														<br />
														<MuiPickersUtilsProvider
															utils={DateMomentUtils}
														>
															<KeyboardDatePicker
																openTo="year"
																invalidDateMessage="Podaj prawidłową datę"
																maxDateMessage="Wróć do nas jak podrośniesz :)"
																minDateMessage="Aż tak stary jesteś?"
																variant="inline"
																// orientation="landscape"
																InputAdornmentProps={{
																	id: 'datepickerdob'
																}}
																required
																autoOk
																disableFuture
																fullWidth
																onClick={() => {
																	setdatePickerState(true);
																}}
																onClose={() => {
																	setdatePickerState(false);
																}}
																open={datePickerState}
																format="DD/MM/yyyy"
																label="Data urodzenia"
																maxDate={maxDate}
																minDate={minDate}
																initialFocusedDate={maxDate}
																value={registerState?.birthDate}
																onChange={(
																	date: MaterialUiPickersDate
																) => {
																	setRegisterState({
																		...registerState,
																		birthDate: date
																	});
																}}
															/>
														</MuiPickersUtilsProvider>
														<br />
														<br />
														<FormControlLabel
															control={
																<>
																	<Checkbox
																		required
																		checked={
																			registerState.terms
																		}
																		onChange={onChange}
																		icon={<FavoriteBorder />}
																		checkedIcon={<Favorite />}
																	/>
																</>
															}
															label={
																<>
																	Akceptuję{' '}
																	<Link to={routes.regulamin}>
																		regulamin
																	</Link>
																</>
															}
														/>
													</CardBody>
													<CardFooter className={classes.cardFooter}>
														<Button
															type="submit"
															// simple
															// color="primary"
															// size="lg"
														>
															Zarejestruj
														</Button>
													</CardFooter>
												</form>
											)
										}
									]}
								/>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-3 col-sm-3"></div>
					</div>
				</div>
			</div>
		</>
	);
}
