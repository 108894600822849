import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/VerifyMailPage";
import { Header } from "../../molecules/Header/Header";
import VerifyMailMenu from "../../molecules/Menu/VerifyMailMenu/VerifyMailMenu";

const useStyles = makeStyles(styles);

export default function VerifyMailPage() {
  const classes = useStyles();
  return (
    <div>
      <Header>
        <VerifyMailMenu />
      </Header>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url('/assets/img/bg7.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      ></div>
      <div className={classNames(classes.mainRaised)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
              <div className="sh-section">
                <div className={classes.center}>
                  <h1>Witamy na pokładzie!</h1>
                  <img
                    className="sh-section__image"
                    src="https://i.giphy.com/media/xULW8v7LtZrgcaGvC0/giphy.webp"
                  />
                  <br />
                  <br />
                  Serdecznie dziękujemy za rejestrację na naszej stronie. <br />
                  Proszę potwierdź Twój adres email aby móc w pełni cieszyć się
                  funkcjonalnością portalu.
                  <br />
                  <br />
                  <img src="https://64.media.tumblr.com/tumblr_lta61d2BgL1r4ghkoo1_500.gifv" />
                  <br />
                  <br />
                </div>
              </div>

              <div className="d-md-none">
                <br />
                <VerifyMailMenu />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
              <VerifyMailMenu />
            </div>
            <div className="col-xl-1 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
