import { Box, Typography } from '@material-ui/core';
import { List } from 'immutable';
import React from 'react';
import { Comment, GetUserInfoResponse } from '../../../../client';
import { ArticleList } from '../../Article/ArticleList/ArticleListContainer';
import { CommentListProfile } from '../../Comment/CommentListProfile/CommentListProfileContainer';
import { CustomTab, CustomTabs } from '../../StyledMaterialUiComponents/StyledMaterialUiComponents';

export interface IProfileActivitesProps {
	userInfo: GetUserInfoResponse;
	userId: number;
}
interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} paddingY="20px" paddingX="0px">
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
export const ProfileActivites: React.FC<IProfileActivitesProps> = (props) => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};
	return (
		<div className="sh-section">
			<CustomTabs
				value={value}
				onChange={handleChange}
				aria-label="simple tabs example"
				centered
			>
				<CustomTab label="Dramki" {...a11yProps(0)} />
				<CustomTab label="Komentarze" {...a11yProps(1)} />
			</CustomTabs>

			<TabPanel value={value} index={0}>
				<ArticleList userId={props.userId} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<CommentListProfile comments={List<Comment>(props.userInfo.comments ?? [])} />
			</TabPanel>
			{/* <TabPanel value={value} index={2}>
        Wkrótce
      </TabPanel> */}
		</div>
	);
};
