import { BecomeAPatron, Comment, GetUserInfoResponse, PaymentChannel, User } from '../../client';

import { FilePondFile } from 'filepond';
import { IProfileReduxState } from './ProfileReducer';
import { createSelector } from '@reduxjs/toolkit';

const ProfileStateSelector = (ProfileState: IProfileReduxState) => ProfileState;

export const getUserInfoSelector = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): GetUserInfoResponse => {
		return state.userInfo;
	}
);

export const getUserDataSelector = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): User => {
		return state.userData;
	}
);

export const getUserDataImageSelector = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): string | FilePondFile | null => {
		return state.userDataImage;
	}
);

export const getUserDataImageHadInitialSelector = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): boolean => {
		return state.userDataImageHadInitial;
	}
);

export const getUserDataImageChangeCounter = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): number => {
		return state.userDataImageChangeCounter;
	}
);

export const getUserInfoCommentsSelector = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): Comment[] => {
		return state.userInfo.comments ?? [];
	}
);

export const getPaymentChannels = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): PaymentChannel[] => {
		return state.paymentChannels;
	}
);

export const getBecomeAPatron = createSelector(
	ProfileStateSelector,
	(state: IProfileReduxState): BecomeAPatron => {
		return {
			email: state.userData.mail,
			userId: state.userInfo.userProfile?.id,
			amount: 50,
			ref: 'dramki.pl',
			country: 'Polska'
		} as BecomeAPatron;
	}
);
