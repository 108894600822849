import * as React from 'react';
import * as Redux from 'redux';

import { AddOrUpdateArticle, Article } from '../../../../client';
import { AddOrUpdateEmoji, fetchEmojies } from '../../../../business/Static/StaticEpics';
import { AnyAction, Dispatch } from 'redux';
import {
	EmojiCount,
	getArticlePageSelector,
	getStatiticsEmojies
} from '../../../../business/Article/ArticleSelectors';
import { downVote, fetchArticle, upVote } from '../../../../business/Article/ArticleEpics';

import { AddArticleSaved } from '../../../../business/Article/ArticleActions';
import { ArticleSingleComponent } from './ArticleSingleComponent';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { getAuth } from '../../../../business/Auth/AuthSelectors';
import { match } from 'react-router-dom';
import { routes } from '../../../../business/router/path-definitions';

interface IArticleSingleContainerReduxMergedProps {
	emojies: List<EmojiCount>;
	articlePage: Article;
	match: match<{ id?: string }>;
	auth: IAuthReduxState;
}

interface IArticleSingleContainerOwnProps {}

interface IArticleSingleContainerDispatchProps {
	getArticlePage: (id: number) => void;
	getEmojies: () => void;
	onUpVote: (statisticsId: number, currentLike: boolean) => void;
	onDownVote: (statisticsId: number, currentDislike: boolean) => void;
	addOrUpdateEmoji: (statisticsId: number, emojiId: number) => void;
	AddArticleSaved: (addArticle: AddOrUpdateArticle) => void;
}

interface IArticleSingleContainerProps
	extends IArticleSingleContainerOwnProps,
		IArticleSingleContainerDispatchProps,
		IArticleSingleContainerReduxMergedProps {}

interface IArticleSingleComponentState {
	id: number;
}

class ArticleSingleContainer extends React.Component<
	IArticleSingleContainerProps,
	IArticleSingleComponentState
> {
	constructor(props: IArticleSingleContainerProps, state: IArticleSingleComponentState) {
		super(props, state);
		this.state = this.getInitialState();

		this.state = {
			id: +props.match.params.id!
		};
		this.props.getArticlePage(this.state.id);
		this.props.getEmojies();
	}

	static getDerivedStateFromProps(
		nextProps: IArticleSingleContainerProps,
		prevState: IArticleSingleComponentState
	) {
		if (nextProps?.match?.params?.id && prevState.id !== +nextProps.match.params.id!) {
			nextProps.getArticlePage(+nextProps.match.params.id!);
			return { id: +nextProps.match.params.id! };
		}
		return null;
	}

	public render(): JSX.Element {
		return (
			<ArticleSingleComponent
				articleSingle={this.props.articlePage}
				emojies={this.props.emojies}
				onUpVote={this.props.onUpVote}
				onDownVote={this.props.onDownVote}
				currentArticlePageId={+(this?.props?.match?.params?.id || '0') || 0}
				addOrUpdateEmoji={this.props.addOrUpdateEmoji}
				AddArticleSaved={this.props.AddArticleSaved}
				auth={this.props.auth}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IArticleSingleContainerOwnProps
	): IArticleSingleContainerReduxMergedProps => {
		const matchSelector = createMatchSelector(routes.article);
		const mergedType = { ...state.article!, ...state.static! };
		return {
			articlePage: getArticlePageSelector(state.article),
			emojies: getStatiticsEmojies(mergedType),
			match: matchSelector(state)!,
			auth: getAuth(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IArticleSingleContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					getArticlePage: fetchArticle,
					onUpVote: upVote,
					onDownVote: downVote,
					getEmojies: fetchEmojies,
					addOrUpdateEmoji: AddOrUpdateEmoji,
					AddArticleSaved: AddArticleSaved
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IArticleSingleComponentState => {
		return {} as IArticleSingleComponentState;
	};
}
export const ArticleSingle = connect(
	ArticleSingleContainer.mapStateToProps,
	ArticleSingleContainer.mapDispatchToProps
)(ArticleSingleContainer);
