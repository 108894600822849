import { makeStyles } from '@material-ui/core/styles';
import { createMatchSelector } from 'connected-react-router';
import React from 'react';
import { match } from 'react-router-dom';
import styles from '../../../assets/jss/material-kit-react/views/ArticlesByTagPage';
import { store } from '../../../business/root-store';
import { routes } from '../../../business/router/path-definitions';
import { ArticleList } from '../../molecules/Article/ArticleList/ArticleListContainer';
import { Header } from '../../molecules/Header/Header';
import { LandingMenu } from '../../molecules/Menu/LandingMenu/LandingMenuContainer';
const useStyles = makeStyles(styles);

export const ArticlesByTagPage: React.FC = () => {
	const classes = useStyles();
	const matchSelector = createMatchSelector(routes.articlesByTag);
	const match: match<{ tagId?: string; tagName?: string }> = matchSelector(store.getState())!;

	return (
		<>
			<Header>
				<LandingMenu />
			</Header>
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url('/assets/img/bg7.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'top center'
				}}
			></div>
			<div className={classes.mainRaised}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
						<div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
							<ArticleList
								tagName={match.params.tagName}
								tagId={Number(match.params.tagId)}
							/>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
							<LandingMenu />
						</div>
						<div className="col-xl-1 d-none d-xl-block"></div>
					</div>
				</div>
			</div>
		</>
	);
};
