import { ThunkAction } from "redux-thunk";
import { actions } from "react-redux-toastr";
import { RootState } from "../../business/root-store";
import { GetApiConfig } from "../../business/api-client-config";
import { ArticleApi, PaymentsApi } from "../../client";
import { EmojiesSaved, PatronsSaved } from "./StaticActions";
import { fetchArticle } from "../Article/ArticleEpics";
import { navigationAction } from "../router/router-actions";
//wykonujemy akcje do api oraz zapisujemy rezultat w storze przez wykonanie action

export const navigateTo =
  (path: string): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const store: RootState = getState();
    dispatch(navigationAction.open(path));
  };

export const fetchEmojies =
  (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
    const store: RootState = getState();
    const api = new ArticleApi(GetApiConfig(store));
    api
      .getAllEmojies()
      .then((x) => {
        dispatch(EmojiesSaved(x.data.emojies!));
      })
      .catch((c) => {
        console.log(
          actions.add({
            message: "Cannot fetch shopping list.",
            type: "error",
          })
        );
      });
  };

  export const AddOrUpdateEmoji =
  (
    statisticsId: number,
    emojiId: number
  ): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const store: RootState = getState();
    const api = new ArticleApi(GetApiConfig(store));
    api
      .addOrUpdateEmoji(statisticsId, emojiId)
      .then(() => {
        dispatch(fetchArticle(store.article.articlePage.id!));
      })
      .catch((c) => {
        console.log(
          actions.add({
            message: "Cannot fetch shopping list.",
            type: "error",
          })
        );
      });
  };

  export const FetchPatreons =
  (
  ): ThunkAction<any, RootState, any, any> =>
  (dispatch, getState) => {
    const store: RootState = getState();
    const api = new PaymentsApi(GetApiConfig(store));
    api
      .patreonList()
      .then((x) => {
        dispatch(PatronsSaved(x.data.patrons!));
      })
      .catch((c) => {
        console.log(
          actions.add({
            message: "Cannot fetch shopping list.",
            type: "error",
          })
        );
      });
  };
