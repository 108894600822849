import { makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import styles from '../../../../assets/jss/material-kit-react/views/profilePage';
import { GetUserInfoResponse } from '../../../../client';

export interface IProfileInfoProps {
	userInfo: GetUserInfoResponse;
}
const useStyles = makeStyles(styles);

export const ProfileInfo: React.FC<IProfileInfoProps> = (props) => {
	const classes = useStyles();
	const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid);
	let description = 'Brak opisu, ale zdecydowanie lubi dramy';
	if (props.userInfo.userProfile?.description) {
		description = props.userInfo.userProfile?.description;
	}
	return (
		<div className="sh-section">
			<div className={classes.profile}>
				<div>
					<img
						src={
							props.userInfo.userProfile?.avatar
								? process.env.REACT_APP_API_URL +
								  '/' +
								  props.userInfo.userProfile?.avatar
								: '/assets/img/screamingfear.gif'
						}
						alt="..."
						className={imageClasses}
					/>
				</div>
			</div>

			<div className={classes.center}>
				<div className="margintop-80px">
					<h3 className={classes.title}>{props.userInfo.userProfile?.userName}</h3>{' '}
					{props.userInfo.userProfile?.isPatreon! && (
						<span style={{ fontSize: '2em' }}>💥</span>
					)}
				</div>
				<br />
				<div className="sh-head-user__info-statistic">
					<Tooltip id="options" title="Dodane dramki" placement="bottom">
						<div className="sh-btn-icon">
							<i className="icon-Post"></i>
							<span>{props.userInfo.userStatistics?.articlesMetric?.count}</span>
						</div>
					</Tooltip>
					{/* <div className="sh-btn-icon">
                <i className="icon-Followers"></i>
                <span>33</span>
              </div>
              <div className="sh-btn-icon">
                <i className="icon-Following"></i>
                <span>14</span>
              </div> */}
					<Tooltip id="options" title="Dodane komentarze" placement="bottom">
						<div className="sh-btn-icon">
							<i className="icon-Comment"></i>
							<span>{props.userInfo.userStatistics?.commentsMetric?.count}</span>
						</div>
					</Tooltip>
					<Tooltip id="options" title="Średnia ocen w artykułach" placement="bottom">
						<div className="sh-btn-icon">
							<i className="icon-Upvote"></i>
							<span>
								{(props.userInfo.userStatistics?.articlesMetric?.allLikes ?? 0) -
									(props.userInfo.userStatistics?.articlesMetric?.allDislikes ??
										0)}
							</span>
						</div>
					</Tooltip>
					<br />
					<Tooltip
						title={moment
							.utc(props.userInfo.userProfile?.registerDate ?? null)
							.local()
							.format('LLL')}
						placement="right"
					>
						<span className="sh-comments__passed">
							{'Zarejestrowany ' +
								moment
									.utc(props.userInfo.userProfile?.registerDate ?? null)
									.fromNow()}
						</span>
					</Tooltip>
				</div>
				<br />
				<div
					className="profileDescription"
					dangerouslySetInnerHTML={{
						__html: description
					}}
				></div>
			</div>

			<br />
		</div>
	);
};
