import { ThunkAction } from '@reduxjs/toolkit';
import { AuthenticationApi } from '../../client';
import { GetApiConfig } from '../api-client-config';
import { RootState } from '../root-store';
import { authenticationActions } from './AuthActions';
import { actions } from 'react-redux-toastr';
import { navigationAction } from '../router/router-actions';
import { routes } from '../router/path-definitions';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GetUserData } from '../Profile/ProfileEpics';

export const logIn =
	(username: string, password: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const api = new AuthenticationApi(GetApiConfig(getState()));
		api.login({ username: username, password: password })
			.then((data) => {
				if (data.data.authData != undefined && data.data.authData.token != undefined) {
					dispatch(
						authenticationActions.logInAction({
							login: data.data.authData.userName!,
							userId: data.data.authData.userId!,
							token: data.data.authData.token || ''
						})
					);
					dispatch(actions.add({ message: 'Zalogowano', type: 'success' }));
					dispatch(GetUserData(Number(data.data.authData.userId)));
					dispatch(navigationAction.open(routes.home));
				}
			})
			.catch((c) => {
				dispatch(
					actions.add({
						message: 'Nie można zalogować, proszę spróbować później',
						type: 'error',
						options: { timeOut: 10000 }
					})
				);
			});
	};

export const activateMail =
	(guid: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const api = new AuthenticationApi(GetApiConfig(getState()));
		api.postActivateMail(guid)
			.then((data) => {
				if (data.data.status) {
					dispatch(
						actions.add({
							message: 'Pomyślnie potwierdzono konto, możesz się teraz zalogować',
							type: 'success'
						})
					);
					dispatch(navigationAction.open(routes.login));
				} else {
					dispatch(
						actions.add({
							message: 'Aktywowałeś już swoje konto, możesz się zalogować',
							type: 'success'
						})
					);
					dispatch(navigationAction.open(routes.login));
				}
			})
			.catch((c) => {
				dispatch(
					actions.add({
						message: 'Błędny link aktywacyjny',
						type: 'error'
					})
				);
				dispatch(navigationAction.open(routes.home));
			});
	};

export const registerNewUserApi =
	(
		username: string,
		mail: string,
		password: string,
		repeatPassword: string,
		birthDate: MaterialUiPickersDate
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const authenticationApi = new AuthenticationApi(GetApiConfig(getState()));
		authenticationApi
			.register({
				email: mail,
				password: password,
				username: username,
				reTypePassword: repeatPassword,
				birthDate: birthDate?.toJSON()
			})
			.then((data) => {
				dispatch(navigationAction.open(routes.verifyMailPlease));
			})
			.catch((c) => {
				dispatch(
					actions.add({
						message: 'Nie można zarejestrować, proszę spróbować później',
						type: 'error'
					})
				);
			});
	};
