import { ActionType } from "typesafe-actions";

export const Auth_Login = "@@Auth/Login";
export const Auth_Logout = "@@Auth/Logout";
export const Auth_LoadCustomerId = "@@Auth/CustomerId/Load";
export const Auth_LoadTokenStatus = "@@Auth/TokenStatus/Load";

export interface IAuthLoginAction {
  type: typeof Auth_Login;
  payload: { login: string; userId: string; token?: string };
}

export interface IAuthLogoutAction {
  type: typeof Auth_Logout;
  payload: {};
}

export interface ICustomerIdAction {
  type: typeof Auth_LoadCustomerId;
  payload: { customerId: number };
}

export interface ILoadTokenAction {
  type: typeof Auth_LoadTokenStatus;
  payload: { status?: string };
}

export const authenticationActions = {
  logInAction: (payload: IAuthLoginAction["payload"]): IAuthLoginAction => ({
    type: Auth_Login,
    payload,
  }),
  logOutAction: (): IAuthLogoutAction => ({ type: Auth_Logout, payload: {} }),
  loadCustomerIdAction: (
    payload: ICustomerIdAction["payload"]
  ): ICustomerIdAction => ({
    type: Auth_LoadCustomerId,
    payload,
  }),
  loadTokenStatusAction: (
    payload: ILoadTokenAction["payload"]
  ): ILoadTokenAction => ({
    type: Auth_LoadTokenStatus,
    payload,
  }),
};

export type AuthenticationAction = ActionType<typeof authenticationActions>;
