import { List } from 'immutable';
import React from 'react';
import { EmojiCount } from '../../../../business/Article/ArticleSelectors';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { AddOrUpdateArticle, Article } from '../../../../client';
import { ArticleSingleItem } from './ArticleSingleItem';

interface IArticleSingleComponentProps {
	articleSingle: Article;
	emojies: List<EmojiCount>;
	currentArticlePageId: number;
	onUpVote: (statisticsId: number, currentLike: boolean) => void;
	onDownVote: (statisticsId: number, currentDislike: boolean) => void;
	addOrUpdateEmoji: (statisticsId: number, emojiId: number) => void;
	AddArticleSaved: (addArticle: AddOrUpdateArticle) => void;
	auth: IAuthReduxState;
}

interface IArticleSingleComponentState {}

export class ArticleSingleComponent extends React.Component<
	IArticleSingleComponentProps,
	IArticleSingleComponentState
> {
	constructor(props: IArticleSingleComponentProps, state: IArticleSingleComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<>
				{this.isListVisible() && (
					<div className="ArticleSingle_main">{this.renderArticleSection()}</div>
				)}
			</>
		);
	}

	private renderArticleSection = (): JSX.Element => {
		if (!this.isListVisible() || !this.props.articleSingle) {
			return <></>;
		}

		return (
			<ArticleSingleItem
				article={this.props.articleSingle}
				emojies={this.props.emojies}
				upVote={this.handleUpVote}
				downVote={this.handleDownVote}
				addOrUpdateEmoji={this.handleAddOrUpdateEmoji}
				AddArticleSaved={this.props.AddArticleSaved}
				auth={this.props.auth}
			/>
		);
	};

	private handleUpVote = (statisticsId: number, currentLike: boolean): void => {
		this.props.onUpVote(statisticsId, currentLike);
	};

	private handleDownVote = (statisticsId: number, currentDislike: boolean): void => {
		this.props.onDownVote(statisticsId, currentDislike);
	};

	private handleAddOrUpdateEmoji = (statisticsId: number, emojiId: number): void => {
		this.props.addOrUpdateEmoji(statisticsId, emojiId);
	};

	private isListVisible = (): boolean => {
		return this.props.articleSingle && this.props.articleSingle != null;
	};

	private getInitialState = (): IArticleSingleComponentState => {
		return {} as IArticleSingleComponentState;
	};
}
