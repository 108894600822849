import { createMatchSelector } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import * as Redux from "redux";
import { AnyAction, Dispatch } from "redux";
import { GetUserInfo } from "../../../business/Profile/ProfileEpics";
import { getUserInfoSelector } from "../../../business/Profile/ProfileSelectors";
import { RootState } from "../../../business/root-store";
import { routes } from "../../../business/router/path-definitions";
import { GetUserInfoResponse } from "../../../client";
import { ProfilePageComponent } from "./ProfilePageComponent";

interface IProfilePageContainerReduxMergedProps {
  userInfo: GetUserInfoResponse;
  match: match<{ id?: string }>;
}

interface IProfilePageContainerOwnProps {}

interface IProfilePageContainerDispatchProps {
  getUserInfo: (userId: number) => void;
  // onDownVote: (statisticsId: number, currentDislike: boolean) => void;
}

interface IProfilePageContainerProps
  extends IProfilePageContainerOwnProps,
    IProfilePageContainerDispatchProps,
    IProfilePageContainerReduxMergedProps {}

interface IProfilePageComponentState {}

class ProfilePageContainer extends React.Component<
  IProfilePageContainerProps,
  IProfilePageComponentState
> {
  constructor(
    props: IProfilePageContainerProps,
    state: IProfilePageComponentState
  ) {
    super(props, state);
    this.state = this.getInitialState();
    const userIdFromUrl = Number(this.props.match.params.id);
    this.props.getUserInfo(userIdFromUrl);
  }

  public render(): JSX.Element {
    return (
      <ProfilePageComponent
        userInfo={this.props.userInfo}
        userId={Number(this.props.match?.params?.id ?? -1)}
      />
    );
  }

  public static mapStateToProps = (
    state: RootState,
    ownProps: IProfilePageContainerOwnProps
  ): IProfilePageContainerReduxMergedProps => {
    const matchSelector = createMatchSelector(routes.profile);
    return {
      userInfo: getUserInfoSelector(state.profile),
      match: matchSelector(state)!,
    };
  };

  public static mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>
  ): IProfilePageContainerDispatchProps => {
    return {
      ...Redux.bindActionCreators(
        {
          getUserInfo: GetUserInfo,
        },
        dispatch
      ),
    };
  };

  private getInitialState = (): IProfilePageComponentState => {
    return {} as IProfilePageComponentState;
  };
}
export const ProfilePage = connect(
  ProfilePageContainer.mapStateToProps,
  ProfilePageContainer.mapDispatchToProps
)(ProfilePageContainer);
