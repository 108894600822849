import { GetUserInfoResponse, PaymentChannel, User } from '../../client';

import { FilePondFile } from 'filepond';

export const UserInfo_Saved = '@@Dramki/Profile/UserInfo/SAVED';
export const UserData_Saved = '@@Dramki/Profile/UserData/SAVED';
export const UserDataDescription_Saved = '@@Dramki/Profile/UserDataDescription/SAVED';
export const UserDataImage_Saved = '@@Dramki/Profile/UserDataImage/SAVED';
export const UserDataImage_Removed = '@@Dramki/Profile/UserDataImage/REMOVED';
export const UserDataImageCounter_Removed = '@@Dramki/Profile/UserDataImageCounter/REMOVED';
export const PaymentChannels_Saved = '@@Dramki/Profile/PaymentChannels/SAVED';
export const UserDataImageHadInitial_Saved = '@@Dramki/Profile/UserDataImageHadInitial/SAVED';

//funkcje ktore wykonuja akcje w recuderze
export interface IUserInfoSavedAction {
	type: typeof UserInfo_Saved;
	userInfo: GetUserInfoResponse;
}

export const UserInfoSaved = (userInfo: GetUserInfoResponse): IUserInfoSavedAction => ({
	type: UserInfo_Saved,
	userInfo: userInfo
});

export interface IUserDataSavedAction {
	type: typeof UserData_Saved;
	user: User;
}
export const UserDataSaved = (user: User): IUserDataSavedAction => ({
	type: UserData_Saved,
	user: user
});

export interface IUserDataDescriptionSavedAction {
	type: typeof UserDataDescription_Saved;
	description: string;
}
export const UserDataDescriptionSaved = (description: string): IUserDataDescriptionSavedAction => ({
	type: UserDataDescription_Saved,
	description: description
});

export interface IUserDataImageSavedAction {
	type: typeof UserDataImage_Saved;
	image: string | FilePondFile | null;
}
export const UserDataImageSaved = (
	image: string | FilePondFile | null
): IUserDataImageSavedAction => ({
	type: UserDataImage_Saved,
	image: image
});

export interface IUserDataImageRemovedAction {
	type: typeof UserDataImage_Removed;
}
export const UserDataImageRemoved = (): IUserDataImageRemovedAction => ({
	type: UserDataImage_Removed
});

export interface IUserDataImageCounterRemovedAction {
	type: typeof UserDataImageCounter_Removed;
}
export const UserDataImageCounterRemoved = (): IUserDataImageCounterRemovedAction => ({
	type: UserDataImageCounter_Removed
});

export interface IUserDataImageHadInitialSavedAction {
	type: typeof UserDataImageHadInitial_Saved;
	userDataImageHadInitial: boolean;
}

export const UserDataImageHadInitialSaved = (
	userDataImageHadInitial: boolean
): IUserDataImageHadInitialSavedAction => ({
	type: UserDataImageHadInitial_Saved,
	userDataImageHadInitial
});

export interface IPaymentChannelsSavedAction {
	type: typeof PaymentChannels_Saved;
	paymentChannels: PaymentChannel[];
}
export const PaymentChannelsSaved = (
	paymentChannels: PaymentChannel[]
): IPaymentChannelsSavedAction => ({
	type: PaymentChannels_Saved,
	paymentChannels: paymentChannels
});
