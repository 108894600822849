import { createStyles, makeStyles, Menu, MenuItem, Modal, Theme, Tooltip } from '@material-ui/core';
import { List } from 'immutable';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteArticle } from '../../../../business/Article/ArticleEpics';
import { EmojiCount } from '../../../../business/Article/ArticleSelectors';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { routes } from '../../../../business/router/path-definitions';
import { navigationAction } from '../../../../business/router/router-actions';
import { AddOrUpdateArticle, Article, ArticleTag, DeleteArticle, Tag } from '../../../../client';
import { Dropshare } from '../../Dropshare/Dropshare';
import { StyledChip } from '../../StyledMaterialUiComponents/StyledMaterialUiComponents';
import { ReportViolation } from '../../Violations/ReportViolation';

export interface IArticleSingleItemProps {
	article: Article;
	emojies: List<EmojiCount>;
	upVote: (statisticsId: number, currentLike: boolean) => void;
	downVote: (statisticsId: number, currentDislike: boolean) => void;
	addOrUpdateEmoji: (statisticsId: number, emojiId: number) => void;
	AddArticleSaved: (addArticle: AddOrUpdateArticle) => void;
	auth: IAuthReduxState;
}

const useStyles2 = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			'& > *': {
				margin: theme.spacing(0.5)
			},
			paddingBottom: '5px'
		}
	})
);
export const ArticleSingleItem: React.FC<IArticleSingleItemProps> = (props) => {
	const classes2 = useStyles2();

	const dispatch = useDispatch();
	const createdDate = moment.utc(props.article.createdDateUtc!).fromNow();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [open, setOpen] = React.useState(false);

	const handleOpenModal = () => {
		handleClose();
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		setAnchorEl(null);
		let addArticleTags = new Array<Tag>();
		props.article.articleTags!.map((articleTag: ArticleTag) => {
			let tag: Tag = { id: articleTag.tag?.id, name: articleTag.tag?.name };
			addArticleTags.push(tag);
		});
		props.AddArticleSaved({
			topic: props.article.topic,
			content: props.article.content,
			tags: addArticleTags,
			articleId: props.article.id,
			pictureLabel: props.article.pictureLabel,
			pictureThumbnail: props.article.pictureThumbnail,
			pictureSource: props.article.pictureSource,
			pictureScore: props.article.pictureScore,
			pictureOriginal: props.article.pictureOriginal,
			pictureModified: props.article.pictureModified
		});
		dispatch(navigationAction.open(routes.editArticle));
	};

	const handleDelete = () => {
		setAnchorEl(null);
		let deleteArticleModel: DeleteArticle = {};
		deleteArticleModel.article = props.article;
		dispatch(deleteArticle(deleteArticleModel));
		dispatch(navigationAction.open(routes.home));
	};

	return (
		<>
			<div className="sh-section">
				<div className="sh-section__head">
					<div className="sh-avatar">
						<img
							src={
								props.article.user?.avatar
									? process.env.REACT_APP_API_URL +
									  '/' +
									  props.article.user?.avatar
									: '/assets/img/screamingfear.gif'
							}
							alt=""
						/>
					</div>
					<div>
						<Tooltip id="user" title="Przejdź do profilu użytkownika" placement="top">
							<Link
								to={'/profile/' + props.article.user?.id}
								className="sh-section__name"
							>
								{props.article.user?.userName}
							</Link>
						</Tooltip>
						<Tooltip
							id="date"
							title={moment.utc(props.article.createdDateUtc!).local().format('LLL')}
							placement="bottom"
						>
							<span className="sh-section__passed">{createdDate}</span>
						</Tooltip>
					</div>
					<Dropshare
						linkToCopy={'/article/' + props.article.id + '/' + props.article.topic}
					/>
				</div>
				<div className="smallBorderBottom">
					<h2>{props.article.topic}</h2>
					{props.article.pictureModified && (
						<div className="sh-section__image">
							<a href="#">
								<img
									src={
										process.env.REACT_APP_API_URL +
										'/' +
										props.article.pictureModified!
									}
									alt={
										props?.article?.pictureLabel +
										' ' +
										props?.article?.pictureScore +
										' ' +
										props?.article?.pictureSource
									}
								/>
							</a>
						</div>
					)}

					<br />
					<div dangerouslySetInnerHTML={{ __html: props.article.content! }}></div>
				</div>
				<div className="sh-section__footer">
					<Tooltip id="upVote" title="Daj lub usuń lajka" placement="top">
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								props.upVote(
									props.article.statisticsId!,
									props.article.statistics?.statisticsUser?.like!
								);
							}}
							className={
								props.article.statistics?.statisticsUser?.like
									? 'sh-section__btn-upvote sh-btn-icon active'
									: 'sh-section__btn-upvote sh-btn-icon'
							}
						>
							<i className="icon-Upvote"></i>
						</a>
					</Tooltip>

					<Tooltip id="downVote" title="Daj lub usuń dislajka" placement="top">
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								props.downVote(
									props.article.statisticsId!,
									props.article.statistics?.statisticsUser?.dislike!
								);
							}}
							className={
								props.article.statistics?.statisticsUser?.dislike
									? 'sh-section__btn-downvote sh-btn-icon active'
									: 'sh-section__btn-downvote sh-btn-icon'
							}
						>
							<i className="icon-Downvote"></i>
						</a>
					</Tooltip>

					<Tooltip
						id="averageLikes"
						title={
							'Średnia ilość lajków. ' +
							props.article.statistics?.likes +
							' lajki, ' +
							props.article.statistics?.dislikes +
							' dislajki.'
						}
						placement="top"
					>
						<div className="sh-section__btn-like sh-btn-icon">
							<i className="icon-Favorite_Full"></i>
							<span>
								{(props.article.statistics?.likes ?? 0) -
									(props.article.statistics?.dislikes ?? 0)}
							</span>
						</div>
					</Tooltip>

					<Tooltip id="comments" title="Ilość komentarzy" placement="top">
						<a href="#" className="sh-section__btn-comment sh-btn-icon">
							<i className="icon-Comment_Full"></i>
							<span>{props.article.comments?.length}</span>
						</a>
					</Tooltip>

					<Tooltip id="refresh" title="Odśwież statystyki" placement="top">
						<a href="#" className="sh-section__btn-repost sh-btn-icon">
							<i className="icon-Repost"></i>
						</a>
					</Tooltip>
					<Tooltip id="options" title="Wyświetl opcje" placement="top">
						<a
							href="#"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={(e: any) => handleClick(e)}
							className="sh-section__btn-options sh-btn-icon"
						>
							<i className="icon-Options"></i>
						</a>
					</Tooltip>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={handleOpenModal}>Zgłoś</MenuItem>
						<Modal
							open={open}
							onClose={handleCloseModal}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<ReportViolation
								userId={props.article.userId}
								userName={props.article.user?.userName ?? ''}
								articleId={props.article.id}
								close={handleCloseModal}
							></ReportViolation>
						</Modal>
						{props.auth.userId == props.article.userId && (
							<>
								<MenuItem onClick={handleEdit}>Edytuj</MenuItem>
								<MenuItem onClick={handleDelete}>Usuń</MenuItem>
							</>
						)}
					</Menu>
				</div>
				<div className="sh-feeling">
					{props.emojies.map((emoji: EmojiCount) => {
						return (
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									props.addOrUpdateEmoji(props.article.statisticsId!, emoji.id!);
								}}
								data-original-title={emoji.name}
							>
								<img src={'/assets/icons/svg/' + emoji.iconUrl} alt="" />
								<span>{emoji.count ?? 0}</span>
							</a>
						);
					})}
				</div>
			</div>
			<div className="d-md-none sh-section pb-3">
				<h6 className="text-center">Tagi w tej dramce</h6>
				<br />
				<div className={classes2.root}>
					{props.article.articleTags?.map((articleTag: ArticleTag) => {
						return (
							<StyledChip
								className="sh-btn"
								key="e"
								onClick={(e) => {
									dispatch(
										navigationAction.open(
											'/tag/' +
												articleTag.tag?.id +
												'/' +
												articleTag.tag?.name
										)
									);
								}}
								size="medium"
								variant="outlined"
								label={'# ' + articleTag.tag?.name}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};
