import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/landingPage";
import Parallax from "../../../components/molecules/Parallax/Parallax";
import { ArticleList } from "../../molecules/Article/ArticleList/ArticleListContainer";
import { Header } from "../../molecules/Header/Header";
import { LandingMenu } from "../../molecules/Menu/LandingMenu/LandingMenuContainer";
const useStyles = makeStyles(styles);

export default function LandingPage() {
  const classes = useStyles();
  return (
    <>
      <Header>
        <LandingMenu />
      </Header>
      <Parallax filter image="/assets/img/wersow.jpg">
        <div className="container-fluid align-items-center">
          <div
            style={{
              color: "#AAA",
              fontSize: "0.8em",
              position: "absolute",
              right: 0,
              bottom: "8vh",
            }}
          >
            Źródło: instagram.com
          </div>
          <div className="row parallaxHeight">
            <div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12">
              <h1 className={classes.title}>Influencerzy strzeżcie się.</h1>
              <h4 className="parallaxText">
                To koniec waszych niejasnych historii.
              </h4>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block"></div>
            <div className="col-xl-1 d-none d-xl-block"></div>
          </div>
        </div>
      </Parallax>

      <div className={classes.mainRaised}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
              <ArticleList />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
              <LandingMenu />
            </div>
            <div className="col-xl-1 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </>
  );
}
