import { Reducer } from "redux";
import { setCookie, getCookie } from "./cookie";
import * as actions from "./AuthActions";
import { ActionType } from "typesafe-actions";

export type ReducerActions = ActionType<typeof actions>;

export interface IAuthReduxState {
  username: string;
  userId?: string;
  token?: string;
}

const INITIAL_STATE = {
  username: getCookie("username"),
  token: getCookie("sessionId"),
  userId: getCookie("userId"),
};

export const authReducer: Reducer<IAuthReduxState, ReducerActions> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actions.Auth_Login:
      return onLogin(state, action);
    case actions.Auth_Logout:
      return onLogout(state, action);
    default:
      return state;
  }
};

const onLogin = (state: IAuthReduxState, action: any) => {
  setCookie("sessionId", action.payload.token || "");
  setCookie("username", action.payload.login || "");
  setCookie("userId", action.payload.userId || "");
  return {
    ...state,
    username: action.payload.login,
    token: action.payload.token,
    userId: action.payload.userId,
  };
};

const onLogout = (state: IAuthReduxState, action: any) => {
  setCookie("sessionId", "");
  setCookie("username", "");
  setCookie("userId", "");
  return {
    ...state,
    username: null,
    token: null,
    userId: null,
  };
};
