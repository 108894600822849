import { createStyles, FormControl, makeStyles, TextField, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataSelector } from '../../../business/Profile/ProfileSelectors';
import { reportUser } from '../../../business/Article/ArticleEpics';

export interface IReportViolationProps {
	userId?: number;
	userName?: string;
	articleId?: number;
	commentId?: number;
	close(): void;
}
export const ReportViolation: React.FC<IReportViolationProps> = (props) => {
	const user = useSelector(getUserDataSelector);
	const dispatch = useDispatch();
	useEffect(() => {
		setViolation({ ...violation, mail: user?.mail ?? '' });
	}, [user]);
	const [violation, setViolation] = useState({
		...props,
		mail: '',
		whatsWrong: ''
	});
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			paper: {
				position: 'absolute',
				width: 400,
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[5],
				padding: theme.spacing(2, 4, 3),
				top: '20%',
				left: '50%',
				transform: 'translate(-50%, 0)'
			}
		})
	);

	const classes = useStyles();
	return (
		<div className={classes.paper}>
			<h2 id="simple-modal-title">Zgłoś naruszenie {violation.userName}:</h2>
			<p id="simple-modal-description">
				<form
					onSubmit={(e) => {
						e.preventDefault();

						dispatch(
							reportUser(
								violation.mail,
								violation.whatsWrong,
								violation.userId,
								violation.userName,
								violation.articleId,
								violation.commentId
							)
						);
						props.close();
					}}
				>
					<FormControl fullWidth>
						<TextField
							value={user?.mail}
							label="Mail"
							margin="normal"
							InputLabelProps={{
								shrink: true
							}}
							required
							type="email"
							onChange={(e) => {
								setViolation({ ...violation, mail: e.target.value });
							}}
						/>
					</FormControl>
					<br />
					<br />

					<FormControl fullWidth>
						<TextField
							value={user?.mail}
							label="Powód zgłoszenia"
							margin="normal"
							InputLabelProps={{
								shrink: true
							}}
							required
							multiline
							type="text"
							rows={4}
							variant="filled"
							onChange={(e) => {
								setViolation({ ...violation, whatsWrong: e.target.value });
							}}
						/>
					</FormControl>

					<br />
					<br />
					<input
						type="submit"
						className="btn MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSecondary"
						value="Zgłoś"
					/>
				</form>
			</p>
		</div>
	);
};
