import * as React from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../../../../business/root-store';
import { Tag } from '../../../../client';
import { ArticleMenuComponent } from './ArticleMenuComponent';
import { getTagsForArticlePage } from '../../../../business/Article/ArticleSelectors';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { getAuth } from '../../../../business/Auth/AuthSelectors';

interface IArticleMenuContainerReduxMergedProps {
	tags: Tag[];
	auth: IAuthReduxState;
}

interface IArticleMenuContainerOwnProps {}

interface IArticleMenuContainerDispatchProps {}

interface IArticleMenuContainerProps
	extends IArticleMenuContainerOwnProps,
		IArticleMenuContainerDispatchProps,
		IArticleMenuContainerReduxMergedProps {}

interface IArticleMenuComponentState {}

class ArticleMenuContainer extends React.Component<
	IArticleMenuContainerProps,
	IArticleMenuComponentState
> {
	constructor(props: IArticleMenuContainerProps, state: IArticleMenuComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <ArticleMenuComponent tags={this.props.tags} auth={this.props.auth} />;
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IArticleMenuContainerOwnProps
	): IArticleMenuContainerReduxMergedProps => {
		return {
			auth: getAuth(state),
			tags: getTagsForArticlePage(state.article)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IArticleMenuContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({}, dispatch)
		};
	};

	private getInitialState = (): IArticleMenuComponentState => {
		return {} as IArticleMenuComponentState;
	};
}
export const ArticleMenu = connect(
	ArticleMenuContainer.mapStateToProps,
	ArticleMenuContainer.mapDispatchToProps
)(ArticleMenuContainer);
