import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "react-redux-toastr";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";

export type IDropshareProps = {
  linkToCopy: string;
};

export const Dropshare: React.FC<IDropshareProps> = (props) => {
  var dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_API_URL + props.linkToCopy.replaceAll(" ", "%20")
    );
    dispatch(
      actions.add({ message: "Skopiowano do schowka", type: "success" })
    );
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip id="share" title="Udostępnij dramkę" placement="top">
        <a
          href="#"
          className="sh-section__btn-share sh-btn-icon"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(e: any) => handleClick(e)}
        >
          <i className="icon-Share"></i>
        </a>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCopyLink}>Skopiuj link</MenuItem>
      </Menu>
    </>
  );
};
