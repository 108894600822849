import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { Link } from 'react-router-dom';
import { MenuFooter } from '../MenuFooter/MenuFooter';
import { NotLoggedMenu } from '../NotLoggedMenu/NotLoggedMenu';
import React from 'react';
import { routes } from '../../../../business/router/path-definitions';

export interface IAddArticleMenuComponentProps {
	auth: IAuthReduxState;
	addOrUpdateArticle: () => void;
	AddArticleRemoved: () => void;
	logOutAction: () => void;
}

export const AddArticleMenuComponent: React.FC<IAddArticleMenuComponentProps> = (props) => {
	const handleSubmit = () => {
		props.addOrUpdateArticle();
	};
	return (
		<div className="sh-section sh-menu sticky">
			{props.auth.token ?? false ? (
				<>
					<p>Cześć {props.auth.username} </p>
					<a href="#" className="sh-btn" onClick={handleSubmit}>
						<i className="icon-Arrow_Right"></i> Wyślij dramkę
					</a>
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							props.AddArticleRemoved();
						}}
						className="sh-btn"
					>
						<i className="icon-Delete"></i> <span>Usuń zawartość</span>
					</a>

					<Link to={'/profile/' + props.auth.userId} className="sh-btn">
						<i className="icon-Followers"></i>Zobacz swój profil
					</Link>
					<Link to={routes.patreonList} className="sh-btn">
						<span className="icon-Cool emoticon">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
							<span className="path4"></span>
							<span className="path5"></span>
						</span>
						Patroni
					</Link>
					<a
						href="#"
						className="sh-btn"
						onClick={(e) => {
							e.preventDefault();
							props.logOutAction();
						}}
					>
						<i className="icon-Arrow_Down"></i>Wyloguj
					</a>
					<MenuFooter />
				</>
			) : (
				<NotLoggedMenu />
			)}
		</div>
	);
};
