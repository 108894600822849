import {
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BuyVipAccountAndBecomeAPatron } from "../../../../business/Profile/ProfileEpics";
import { routes } from "../../../../business/router/path-definitions";
import { BecomeAPatron, PaymentChannel } from "../../../../client";

export interface IPatreonProps {
  editProfile: {
    user: BecomeAPatron;
    paymentChannels: PaymentChannel[];
  };
  setEditProfile: React.Dispatch<
    React.SetStateAction<{
      user: BecomeAPatron;
      paymentChannels: PaymentChannel[];
    }>
  >;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      paddingBottom: "5px",
    },
  })
);

export const Patreon: React.FC<IPatreonProps> = (props) => {
  const classes = useStyles();
  const [datePickerState, setdatePickerState] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate());
  maxDate.setFullYear(maxDate.getFullYear() - 8);
  var minDate = new Date();
  minDate.setDate(maxDate.getDate());
  minDate.setFullYear(maxDate.getFullYear() - 100);
  return (
    <>
      <div className="sh-section ">
        <div className="sh-section__content">
          <h3>Wybierz operatora płatności</h3>
          <div className={classes.root}>
            {props.editProfile.paymentChannels.map((x) => (
              <div style={{ margin: "10px", textAlign: "center", border: "1px solid #AAA",borderRadius:"3px", padding:"5px", width:"150px" ,display:"flex" ,justifyContent:"space-between",  flexDirection:"column" }}>
                <h4 style={{fontSize:"20px", marginBottom:"5px" ,lineHeight:"1em"}}>{x.name}</h4>
                <img
                  src={x.logoUrl!}
                  alt={x.name!}
                  style={{maxWidth:"100px",marginTop:"0px",paddingTop:"0px", margin: "0 auto"}}
                />
               <br />
                <button
                style={{bottom:"0", position:"relative" }}
                  className={props.editProfile.user.paymentChannelId == x.id ? "btn btn-primary" : "btn btn-outline-primary"}
                  onClick={(e) => {
                    props.setEditProfile({
                      ...props.editProfile.user,
                      user: {
                        ...props.editProfile.user,
                        paymentChannelId: x.id,
                      },
                      paymentChannels: props.editProfile.paymentChannels,
                    });
                  }}
                >
                  Wybieram
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="sh-section ">
        <div className="sh-section__content">
          Nie lubimuy biurokracji, ale urząd skarbowy wymaga od nas abyśmy
          przedstawiali wszelkie zyski, aby to było legalne i prawdziwe zeznanie
          podatkowe potrzebujemy trochę danych od was, aby móc wystawić wam
          dokument potwierdzający tą płatność. 🤑 <br />
          <h5>Proszę podaj swoje dane osobowe:</h5>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(BuyVipAccountAndBecomeAPatron(props.editProfile.user));
            }}
          >
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.firstName}
                label="Imię"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    ...props.editProfile.user,
                    user: {
                      ...props.editProfile.user,
                      firstName: e.target.value,
                    },
                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <br />
            <br />

            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.surname}
                label="Nazwisko"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: {
                      ...props.editProfile.user,
                      surname: e.target.value,
                    },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.email}
                label="Mail"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: { ...props.editProfile.user, email: e.target.value },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.amount}
                label="Kwota wsparcia"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: {
                      ...props.editProfile.user,
                      amount: +e.target.value,
                    },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.street}
                label="Ulica"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: { ...props.editProfile.user, street: e.target.value },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.house}
                label="Nr Budynku"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: { ...props.editProfile.user, house: e.target.value },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.flat}
                label="Nr lokalu"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: { ...props.editProfile.user, flat: e.target.value },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.city}
                label="Miasto"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: { ...props.editProfile.user, city: e.target.value },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.postCode}
                label="Kod Pocztowy"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: {
                      ...props.editProfile.user,
                      postCode: e.target.value,
                    },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={props.editProfile.user.country}
                label="Kraj"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  props.setEditProfile({
                    user: {
                      ...props.editProfile.user,
                      country: e.target.value,
                    },

                    paymentChannels: props.editProfile.paymentChannels,
                  });
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <>
                    <Checkbox
                      required
                      checked={props.editProfile.user.accepted}
                      onChange={(e) => {
                        props.setEditProfile({
                          user: {
                            ...props.editProfile.user,
                            accepted: e.target.checked,
                          },

                          paymentChannels: props.editProfile.paymentChannels,
                        });
                      }}
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite />}
                    />
                  </>
                }
                label={
                  <>
                    Akceptuję{" "}
                    <Link to={routes.regulaminPatreon}>regulamin</Link>
                  </>
                }
              />
            </FormControl>
            <br />
            <br />
            <input
              type="submit"
              className="btn MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSecondary"
              value="Kup VIP'a"
            />
          </form>
        </div>
      </div>
    </>
  );
};
