import { ArticleApi, BecomeAPatron, FileApi, PaymentsApi, UserApi } from '../../client';
import {
	PaymentChannelsSaved,
	UserDataImageCounterRemoved,
	UserDataSaved,
	UserInfoSaved
} from './ProfileActions';
import {
	getUserDataImageChangeCounter,
	getUserDataImageHadInitialSelector,
	getUserDataImageSelector,
	getUserDataSelector
} from './ProfileSelectors';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { GetApiConfig } from '../api-client-config';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import { registerPlugin } from 'react-filepond';

registerPlugin(FilePondPluginFileEncode);
//wykonujemy akcje do api oraz zapisujemy rezultat w storze przez wykonanie action

export const GetUserInfo =
	(userId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new ArticleApi(GetApiConfig(store));
		api.getUserInfoById(userId).then((x) => {
			dispatch(UserInfoSaved(x.data));
		});
	};

export const GetUserData =
	(userId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new UserApi(GetApiConfig(store));
		api.getLoggedUser(userId).then((x) => {
			dispatch(UserDataSaved(x.data.user!));
		});
	};

export const UpdateUserInfo = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
	const store: RootState = getState();
	const userApi = new UserApi(GetApiConfig(store));
	const fileApi = new FileApi(GetApiConfig(store));
	const userData = { ...getUserDataSelector(store.profile) };
	const userDataImage = getUserDataImageSelector(store.profile);
	let imageCounter = getUserDataImageChangeCounter(store.profile);
	const hadInitial = getUserDataImageHadInitialSelector(store.profile);

	if (hadInitial) {
		imageCounter = imageCounter - 1;
	}
	if (userDataImage != null && imageCounter > 0) {
		const base64 = (userDataImage as any).getFileEncodeBase64String();
		fileApi
			.postFileCatalog({ name: 'avatars' })
			.then((x) => {
				return fileApi.postImageFile({
					catalogId: x.data.catalogId,
					name: 'dupa.jpg',
					resize: true,
					width: 700,
					height: 700,
					imageData: base64,
					generateThumbminal: true,
					keepResizeRatio: false,
					thumbminalHeight: 300,
					thumbminalWidth: 300
				});
			})
			.then((u) => {
				const url = u.data.fileInfos!.find((x) => x.fileType?.toString() == 'ModifiedImage')
					?.url!;
				userData.avatar = url;
			})
			.finally(() => {
				userApi.updateUserData({ user: userData, userId: userData.id }).then((x) => {
					dispatch(
						UserInfoSaved({
							...store.profile.userInfo,
							userProfile: x.data.user!
						})
					);
					dispatch(UserDataSaved(x.data.user!));
					dispatch(UserDataImageCounterRemoved());
				});
			});
	} else {
		userApi.updateUserData({ user: userData, userId: userData.id }).then((x) => {
			dispatch(
				UserInfoSaved({
					...store.profile.userInfo,
					userProfile: x.data.user!
				})
			);
			dispatch(UserDataSaved(x.data.user!));
			dispatch(UserDataImageCounterRemoved());
		});
	}
};

export const GetPaymentChannels =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentsApi(GetApiConfig(store));
		api.getPaymentChannels('PLN')
			.then((x) => {
				dispatch(PaymentChannelsSaved(x.data.paymentChannels!));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch Payment Channels list.',
						type: 'error'
					})
				);
			});
	};

export const BuyVipAccountAndBecomeAPatron =
	(becomeAPatron: BecomeAPatron): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentsApi(GetApiConfig(store));
		api.becomeAPatron(becomeAPatron)
			.then((x) => {
				window.location.href = x.data.paymentProviderRedirectUrl!;
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot become a vip sad..',
						type: 'error'
					})
				);
			});
	};
