import { GetUserInfoResponse, PaymentChannel, User } from '../../client';
import {
	IPaymentChannelsSavedAction,
	IUserDataDescriptionSavedAction,
	IUserDataImageCounterRemovedAction,
	IUserDataImageHadInitialSavedAction,
	IUserDataImageRemovedAction,
	IUserDataImageSavedAction,
	IUserDataSavedAction,
	IUserInfoSavedAction,
	PaymentChannels_Saved,
	UserDataDescription_Saved,
	UserDataImageCounter_Removed,
	UserDataImageHadInitial_Saved,
	UserDataImage_Removed,
	UserDataImage_Saved,
	UserData_Saved,
	UserInfo_Saved
} from './ProfileActions';

import { FilePondFile } from 'filepond';
import { Reducer } from 'redux';

type ReducerActions =
	| IUserInfoSavedAction
	| IUserDataSavedAction
	| IPaymentChannelsSavedAction
	| IUserDataDescriptionSavedAction
	| IUserDataImageSavedAction
	| IUserDataImageRemovedAction
	| IUserDataImageCounterRemovedAction
	| IUserDataImageHadInitialSavedAction;

export interface IProfileReduxState {
	userData: User;
	userDataImageHadInitial: boolean;
	userDataImage: string | FilePondFile | null;
	userDataImageChangeCounter: number;
	userInfo: GetUserInfoResponse;
	paymentChannels: PaymentChannel[];
}

const INITIAL_STATE: IProfileReduxState = {
	userDataImageHadInitial: false,
	userData: {},
	userDataImage: null,
	userDataImageChangeCounter: 0,
	userInfo: {},
	paymentChannels: []
};

export const ProfileReducer: Reducer<IProfileReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case UserDataImageHadInitial_Saved:
			return handleUserDataImageHadInitialSaved(state, action);
		case UserInfo_Saved:
			return handleUserInfoSaved(state, action);
		case UserData_Saved:
			return handleUserDataSaved(state, action);
		case UserDataDescription_Saved:
			return handleUserDataDescriptionSaved(state, action);
		case UserDataImage_Saved:
			return handleUserDataImageSaved(state, action);
		case UserDataImage_Removed:
			return handleUserDataImageRemoved(state, action);
		case UserDataImageCounter_Removed:
			return handleUserDataImageCounterRemoved(state, action);
		case PaymentChannels_Saved:
			return handlePaymentChannelsSaved(state, action);
		default:
			return state;
	}
};

const handleUserDataImageHadInitialSaved = (
	state: IProfileReduxState,
	action: IUserDataImageHadInitialSavedAction
): IProfileReduxState => {
	return {
		...state,
		userDataImageHadInitial: action.userDataImageHadInitial
	};
};

const handleUserInfoSaved = (
	state: IProfileReduxState,
	action: IUserInfoSavedAction
): IProfileReduxState => {
	return {
		...state,
		userInfo: action.userInfo
	};
};

const handleUserDataSaved = (
	state: IProfileReduxState,
	action: IUserDataSavedAction
): IProfileReduxState => {
	return {
		...state,
		userData: action.user
	};
};

const handleUserDataDescriptionSaved = (
	state: IProfileReduxState,
	action: IUserDataDescriptionSavedAction
): IProfileReduxState => {
	return {
		...state,
		userData: { ...state.userData, description: action.description }
	};
};

const handleUserDataImageSaved = (
	state: IProfileReduxState,
	action: IUserDataImageSavedAction
): IProfileReduxState => {
	return {
		...state,
		userDataImage: action.image,
		userDataImageChangeCounter: state.userDataImageChangeCounter + 1
	};
};

const handleUserDataImageRemoved = (
	state: IProfileReduxState,
	action: IUserDataImageRemovedAction
): IProfileReduxState => {
	return {
		...state,
		userDataImage: null,
		userData: { ...state.userData, avatar: null }
	};
};

const handleUserDataImageCounterRemoved = (
	state: IProfileReduxState,
	action: IUserDataImageCounterRemovedAction
): IProfileReduxState => {
	return {
		...state,
		userData: {},
		userDataImage: null,
		userDataImageChangeCounter: 0,
		userDataImageHadInitial: false
	};
};

const handlePaymentChannelsSaved = (
	state: IProfileReduxState,
	action: IPaymentChannelsSavedAction
): IProfileReduxState => {
	return {
		...state,
		paymentChannels: action.paymentChannels
	};
};
