import {
	AddArticleContent_Saved,
	AddArticleId_Saved,
	AddArticleImageCounter_Removed,
	AddArticleImageHadInitial_Saved,
	AddArticleImage_Removed,
	AddArticleImage_Saved,
	AddArticleTag_Saved,
	AddArticleTags_Saved,
	AddArticleTopic_Saved,
	AddArticle_Removed,
	AddArticle_Saved,
	AddTag_Saved,
	AllTags_Saved,
	ArticleItem_Removed,
	ArticleListLoadMore_Saved,
	ArticleList_Saved,
	ArticlePageComment_Removed,
	ArticlePage_Removed,
	ArticlePage_Saved,
	IAddArticleContentSavedAction,
	IAddArticleIdSavedAction,
	IAddArticleImageCounterRemovedAction,
	IAddArticleImageHadInitialSavedAction,
	IAddArticleImageRemovedAction,
	IAddArticleImageSavedAction,
	IAddArticleRemovedAction,
	IAddArticleSavedAction,
	IAddArticleTagSavedAction,
	IAddArticleTagsSavedAction,
	IAddArticleTopicSavedAction,
	IAddTagSavedAction,
	IAllTagsSaveAction,
	IArticleItemRemovedAction,
	IArticleListLoadedMoreAction,
	IArticleListSavedAction,
	IArticlePageCommentRemovedAction,
	IArticlePageRemovedAction,
	IArticlePageSavedAction,
	ILikeDislikeSavedAction,
	LikeDislike_Saved
} from './ArticleActions';
import {
	AddOrUpdateArticle,
	Article,
	ArticleListItem,
	SortDirection,
	StatisticsUser,
	Tag
} from '../../client';

import { FilePondFile } from 'filepond';
import { List } from 'immutable';
import { Reducer } from 'redux';

type ReducerActions =
	| IArticleListSavedAction
	| IArticleItemRemovedAction
	| IAddArticleSavedAction
	| IArticlePageSavedAction
	| IArticleListLoadedMoreAction
	| IAllTagsSaveAction
	| IAddArticleImageHadInitialSavedAction
	| IAddArticleTopicSavedAction
	| IAddArticleContentSavedAction
	| IAddArticleTagsSavedAction
	| IAddTagSavedAction
	| IAddArticleTagSavedAction
	| IAddArticleRemovedAction
	| IAddArticleImageSavedAction
	| IAddArticleIdSavedAction
	| IArticlePageRemovedAction
	| IArticlePageCommentRemovedAction
	| ILikeDislikeSavedAction
	| IAddArticleImageRemovedAction
	| IAddArticleImageCounterRemovedAction;

export interface IArticleReduxState {
	articleList: List<ArticleListItem>;
	articlePage: Article;
	allTags: List<Tag>;
	addArticle: AddOrUpdateArticle;

	addArticleImage: string | FilePondFile | null;
	addArticleImageChangeCounter: number;
	addArticleImageHadInitial: boolean;

	articleListCurrentPage: number;
	articleListResultsCount?: number;
	articleListPageSize: number;
	articleListSortField: string;
	articleListSortDirection: SortDirection;
}

const INITIAL_STATE: IArticleReduxState = {
	articleList: List<ArticleListItem>(),
	articleListCurrentPage: 1,
	articleListPageSize: 8,
	articleListSortField: 'CreatedDateUtc',
	articleListSortDirection: SortDirection.NUMBER_2,

	articlePage: {},
	allTags: List<Tag>(),
	addArticle: {},
	addArticleImage: null,
	addArticleImageChangeCounter: 0,
	addArticleImageHadInitial: false
};

export const ArticleListReducer: Reducer<IArticleReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case ArticlePage_Removed:
			return handleArticlePageRemoved(state, action);
		case AddArticleTags_Saved:
			return handleAddArticleTagsSaved(state, action);
		case AddArticleTag_Saved:
			return handleAddArticleTagSaved(state, action);
		case AddArticle_Saved:
			return handleAddArticleSaved(state, action);
		case AddArticleContent_Saved:
			return handleAddArticleContentSaved(state, action);
		case AddArticleImageHadInitial_Saved:
			return handleAddArticleImageHadInitialSaved(state, action);
		case AddArticleTopic_Saved:
			return handleAddArticleTopicSaved(state, action);
		case AddArticleImage_Saved:
			return handleAddArticleImageSaved(state, action);
		case AddArticleImage_Removed:
			return handleAddArticleImageRemoved(state, action);
		case AddArticleImageCounter_Removed:
			return handleAddArticleImageCounterRemoved(state, action);
		case AddArticleId_Saved:
			return handleAddArticleIdSaved(state, action);
		case AllTags_Saved:
			return handleAllTagsSaved(state, action);
		case ArticleList_Saved:
			return handleArticleListSaved(state, action);
		case ArticleListLoadMore_Saved:
			return handleArticleListLoadMore(state, action);
		case ArticleItem_Removed:
			return handleArticleItemRemoved(state, action);
		case ArticlePage_Saved:
			return handleArticlePageSaved(state, action);
		case AddTag_Saved:
			return handleAddTagSaved(state, action);
		case AddArticle_Removed:
			return handleAddArticleRemoved(state, action);
		case ArticlePageComment_Removed:
			return handleArticlePageCommentRemoved(state, action);
		case LikeDislike_Saved:
			return handleLikeDislikeArticleSaved(state, action);
		default:
			return state;
	}
};

const CalculateLikesDislikes = (
	action: ILikeDislikeSavedAction,
	userLiked: boolean,
	userDisliked: boolean,
	likes: number,
	dislikes: number
) => {
	if (action.like == false && action.dislike == false) {
		//usuwamy lajka
		if (userLiked == true && userDisliked == false) {
			likes--;
		}
		//usuwamy negatyw
		else if (userLiked == false && userDisliked == true) {
			dislikes--;
		}
	} else if (action.like == false && action.dislike == true) {
		//dajemy negatyw mając lajka
		if (userLiked == true && userDisliked == false) {
			likes--;
			dislikes++;
		}
		//dajemy negatyw
		else if (userLiked == false && userDisliked == false) {
			dislikes++;
		}
	} else {
		//dajemy lajka
		if (userLiked == false && userDisliked == false) {
			likes++;
		}
		//dajemy lajka mając negatyw
		else if (userLiked == false && userDisliked == true) {
			dislikes--;
			likes++;
		}
	}
	return { likes, dislikes };
};

const handleLikeDislikeArticleSaved = (
	state: IArticleReduxState,
	action: ILikeDislikeSavedAction
): IArticleReduxState => {
	let article = {
		...state.articlePage,
		statistics: {
			...state.articlePage.statistics,
			statisticsUser: { ...state.articlePage.statistics?.statisticsUser }
		}
	};
	let articles = [...state.articleList.toArray()];

	let articleToChange = {
		...articles.find((x) => x.statisticsId == action.statisticsId)!
	};
	let commentToChange = {
		...article.comments?.find((x) => x.statisticsId == action.statisticsId)
	};

	if (article.statisticsId === action.statisticsId) {
		//artykul single
		let calculatedLikesDislikes = CalculateLikesDislikes(
			action,
			article.statistics?.statisticsUser?.like ?? false,
			article.statistics?.statisticsUser?.dislike ?? false,
			article.statistics?.likes ?? 0,
			article.statistics?.dislikes ?? 0
		);
		article.statistics!.likes = calculatedLikesDislikes.likes;
		article.statistics!.dislikes = calculatedLikesDislikes.dislikes;

		if (!article.statistics?.statisticsUser) {
			let statisticsUser: StatisticsUser = {};
			article.statistics!.statisticsUser = statisticsUser;
		}
		article.statistics!.statisticsUser!.like = action.like;
		article.statistics!.statisticsUser!.dislike = action.dislike;
	}
	if (Object.keys(commentToChange).length > 0) {
		//comment w artykule
		let calculatedLikesDislikes = CalculateLikesDislikes(
			action,
			commentToChange.statistics?.statisticsUser?.like ?? false,
			commentToChange.statistics?.statisticsUser?.dislike ?? false,
			commentToChange.statistics?.likes ?? 0,
			commentToChange.statistics?.dislikes ?? 0
		);
		commentToChange.statistics!.likes = calculatedLikesDislikes.likes;
		commentToChange.statistics!.dislikes = calculatedLikesDislikes.dislikes;
		if (!commentToChange.statistics?.statisticsUser) {
			let statisticsUser: StatisticsUser = {};
			commentToChange.statistics!.statisticsUser = statisticsUser;
		}
		commentToChange.statistics!.statisticsUser!.like = action.like;
		commentToChange.statistics!.statisticsUser!.dislike = action.dislike;
		const index = article.comments!.findIndex((x) => x.id === commentToChange.id);
		article.comments!.splice(index, 1, commentToChange);
	}
	if (Object.keys(articleToChange).length > 0) {
		//artykuł w liscie
		let calculatedLikesDislikes = CalculateLikesDislikes(
			action,
			articleToChange.userLiked ?? false,
			articleToChange.userDisliked ?? false,
			articleToChange.likes ?? 0,
			articleToChange.dislikes ?? 0
		);
		articleToChange.likes = calculatedLikesDislikes.likes;
		articleToChange.dislikes = calculatedLikesDislikes.dislikes;
		articleToChange.userLiked = action.like;
		articleToChange.userDisliked = action.dislike;
		const index = articles.findIndex((x) => x.id === articleToChange.id);
		articles.splice(index, 1, articleToChange);
	}

	return {
		...state,
		articleList: List<ArticleListItem>(articles),
		articlePage: article
	};
};

const handleAddArticleImageSaved = (
	state: IArticleReduxState,
	action: IAddArticleImageSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticleImage: action.addArticleImage,
		addArticleImageChangeCounter: state.addArticleImageChangeCounter + 1
	};
};

const handleAddArticleImageRemoved = (
	state: IArticleReduxState,
	action: IAddArticleImageRemovedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: {
			...state.addArticle,
			pictureOriginal: null,
			pictureLabel: null,
			pictureModified: null,
			pictureScore: null,
			pictureSource: null,
			pictureThumbnail: null
		},
		addArticleImage: null
	};
};

const handleAddArticleImageCounterRemoved = (
	state: IArticleReduxState,
	action: IAddArticleImageCounterRemovedAction
): IArticleReduxState => {
	return {
		...state,
		addArticleImageChangeCounter: 0,
		addArticleImageHadInitial: false
	};
};

const handleAddTagSaved = (
	state: IArticleReduxState,
	action: IAddTagSavedAction
): IArticleReduxState => {
	const tags = [...state.allTags.toArray(), action.addTag];
	return {
		...state,
		allTags: List<Tag>(tags),
		addArticle: {
			...state.addArticle,
			tags: [...(state.addArticle.tags ?? []), action.addTag]
		}
	};
};

const handleAddArticleTagSaved = (
	state: IArticleReduxState,
	action: IAddArticleTagSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: {
			...state.addArticle,
			tags: [...(state.addArticle.tags ?? []), action.addArticleTag]
		}
	};
};

const handleAddArticleSaved = (
	state: IArticleReduxState,
	action: IAddArticleSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: action.addArticle
	};
};

const handleAddArticleTagsSaved = (
	state: IArticleReduxState,
	action: IAddArticleTagsSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: { ...state.addArticle, tags: action.addArticleTags }
	};
};

const handleAddArticleContentSaved = (
	state: IArticleReduxState,
	action: IAddArticleContentSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: { ...state.addArticle, content: action.addArticleContent }
	};
};

const handleAddArticleImageHadInitialSaved = (
	state: IArticleReduxState,
	action: IAddArticleImageHadInitialSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticleImageHadInitial: action.addArticleImageHadInitial
	};
};

const handleAddArticleTopicSaved = (
	state: IArticleReduxState,
	action: IAddArticleTopicSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: { ...state.addArticle, topic: action.addArticleTopic }
	};
};

const handleAddArticleIdSaved = (
	state: IArticleReduxState,
	action: IAddArticleIdSavedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: { ...state.addArticle, articleId: action.articleId }
	};
};

const handleArticleListSaved = (
	state: IArticleReduxState,
	action: IArticleListSavedAction
): IArticleReduxState => {
	return {
		...state,
		articleListResultsCount: action.count,
		articleListCurrentPage: action.page,
		articleList: List<ArticleListItem>(action.articles),
		articleListSortField: action.sortBy
	};
};

const handleArticleListLoadMore = (
	state: IArticleReduxState,
	action: IArticleListLoadedMoreAction
): IArticleReduxState => {
	return {
		...state,
		articleListResultsCount: action.count,
		articleListCurrentPage: action.page,
		articleList: List<ArticleListItem>([...state.articleList.toArray(), ...action.articles])
	};
};

const handleAllTagsSaved = (
	state: IArticleReduxState,
	action: IAllTagsSaveAction
): IArticleReduxState => {
	return {
		...state,
		allTags: List<Tag>(action.allTags)
	};
};

const handleArticleItemRemoved = (
	state: IArticleReduxState,
	action: IArticleItemRemovedAction
): IArticleReduxState => {
	const updated = state.articleList.filter((item: Article) => {
		return item.id !== action.articleItemId;
	});
	return {
		...state,
		articleList: updated
	};
};

const handleArticlePageRemoved = (
	state: IArticleReduxState,
	action: IArticlePageRemovedAction
): IArticleReduxState => {
	return {
		...state,
		articlePage: {}
	};
};

const handleAddArticleRemoved = (
	state: IArticleReduxState,
	action: IAddArticleRemovedAction
): IArticleReduxState => {
	return {
		...state,
		addArticle: {},
		addArticleImage: null,
		addArticleImageChangeCounter: 0,
		addArticleImageHadInitial: false
	};
};

const handleArticlePageCommentRemoved = (
	state: IArticleReduxState,
	action: IArticlePageCommentRemovedAction
): IArticleReduxState => {
	const updated = state.articlePage.comments!.filter((item) => item.id != action.commentId);
	return {
		...state,
		articlePage: { ...state.articlePage, comments: [...updated] }
	};
};

const handleArticlePageSaved = (
	state: IArticleReduxState,
	action: IArticlePageSavedAction
): IArticleReduxState => {
	return {
		...state,
		articlePage: { ...action.articlePage }
	};
};
