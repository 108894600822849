import { Menu, MenuItem, Tooltip, Modal } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteComment } from '../../../../business/Article/ArticleEpics';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { Comment } from '../../../../client';
import { Dropshare } from '../../Dropshare/Dropshare';
import { ReportViolation } from '../../Violations/ReportViolation';

export interface ICommentListProfileItemProps {
	comment: Comment;
	upVote: (statisticsId: number, currentLike: boolean) => void;
	downVote: (statisticsId: number, currentDislike: boolean) => void;
	auth: IAuthReduxState;
}

export const CommentListProfileItem: React.FC<ICommentListProfileItemProps> = (props) => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [open, setOpen] = React.useState(false);

	const handleOpenModal = () => {
		handleClose();
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleDelete = () => {
		setAnchorEl(null);
		dispatch(deleteComment(props.comment));
	};
	const createdDateComment = moment.utc(props.comment.createdDateUtc).fromNow();
	const createdDate = moment.utc(props.comment.article?.createdDateUtc).fromNow();
	return (
		<div className="sh-section">
			<div className="sh-section__head">
				<div className="sh-avatar">
					<img
						src={
							props.comment.article?.user?.avatar
								? process.env.REACT_APP_API_URL +
								  '/' +
								  props.comment.article?.user?.avatar
								: '/assets/img/screamingfear.gif'
						}
						alt=""
					/>
				</div>
				<div>
					<Tooltip id="user" title="Przejdź do profilu użytkownika" placement="top">
						<Link
							to={'/profile/' + props.comment.article?.user?.id}
							className="sh-section__name"
						>
							{props.comment.article?.user?.userName}
						</Link>
					</Tooltip>
					<Tooltip
						id="date"
						title={moment
							.utc(props.comment.article?.createdDateUtc)
							.local()
							.format('LLL')}
						placement="bottom"
					>
						<span className="sh-section__passed">{createdDate}</span>
					</Tooltip>
				</div>
				<Dropshare
					linkToCopy={
						'/article/' + props.comment.article?.id + '/' + props.comment.article?.topic
					}
				/>
			</div>
			<div className="sh-section__content">
				<Tooltip id="article" title="Przejdź do dramki" placement="top-start">
					<Link
						to={
							'/article/' +
							props.comment.article?.id +
							'/' +
							props.comment.article?.topic
						}
					>
						<p className="sh-section__text">{props.comment.article?.topic}</p>
					</Link>
				</Tooltip>
				{props.comment.article?.pictureModified != null && (
					<div className="sh-section__image">
						<Link
							to={
								'/article/' +
								props.comment.article.id +
								'/' +
								props.comment.article.topic
							}
						>
							<img
								className="img-fluid"
								src={
									process.env.REACT_APP_API_URL +
									'/' +
									props.comment.article?.pictureModified
								}
								alt={
									props?.comment?.article?.pictureLabel +
									' ' +
									props?.comment?.article?.pictureScore +
									'; ' +
									props?.comment?.article?.pictureSource
								}
							/>
						</Link>
					</div>
				)}
			</div>
			<div className="sh-section__footer">
				{/* <Tooltip id="upVote" title="Daj lub usuń lajka" placement="top">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.upVote(
                props.comment.article?.statisticsId!,
                props.comment.article?.statistics?.statisticsUser?.like!
              );
            }}
            className={
              props.comment.article?.statistics?.statisticsUser?.like!
                ? "sh-section__btn-upvote sh-btn-icon active"
                : "sh-section__btn-upvote sh-btn-icon"
            }
          >
            <i className="icon-Upvote"></i>
          </a>
        </Tooltip>

        <Tooltip id="downVote" title="Daj lub usuń dislajka" placement="top">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.downVote(
                props.comment.article?.statisticsId!,
                props.comment.article?.statistics?.statisticsUser?.dislike!
              );
            }}
            className={
              props.comment.article?.statistics?.statisticsUser?.dislike!
                ? "sh-section__btn-downvote sh-btn-icon active"
                : "sh-section__btn-downvote sh-btn-icon"
            }
          >
            <i className="icon-Downvote"></i>
          </a>
        </Tooltip> */}

				<Tooltip
					id="averageLikes"
					title={
						'Średnia ilość lajków. ' +
						props.comment.article?.statistics?.likes +
						' lajki, ' +
						props.comment.article?.statistics?.dislikes +
						' dislajki.'
					}
					placement="top"
				>
					<div className="sh-section__btn-like sh-btn-icon">
						<i className="icon-Favorite_Full"></i>
						<span>
							{(props.comment.article?.statistics?.likes ?? 0) -
								(props.comment.article?.statistics?.dislikes ?? 0)}
						</span>
					</div>
				</Tooltip>

				<Tooltip id="comments" title="Ilość komentarzy" placement="top">
					<Link
						to={
							'/article/' +
							props.comment.article?.id +
							'/' +
							props.comment.article?.topic
						}
						className="sh-section__btn-comment sh-btn-icon"
					>
						<i className="icon-Comment_Full"></i>
						<span>{props.comment.article?.comments?.length}</span>
					</Link>
				</Tooltip>
			</div>
			<div className="sh-comments__section">
				<div className="sh-comments__avatar sh-avatar">
					<img
						src={
							props.comment.user?.avatar
								? process.env.REACT_APP_API_URL + '/' + props.comment.user?.avatar
								: '/assets/img/screamingfear.gif'
						}
						alt=""
					/>
				</div>
				<div className="sh-comments__content">
					<Tooltip id="user" title="Przejdź do profilu użytkownika" placement="top">
						<Link
							to={'/profile/' + props.comment.user?.id}
							className="sh-comments__name"
						>
							{props.comment.user?.userName + ' '}
						</Link>
					</Tooltip>
					<Tooltip
						id="date"
						title={moment
							.utc(props.comment.createdDateUtc ?? null)
							.local()
							.format('LLL')}
						placement="right"
					>
						<span className="sh-comments__passed">{'skomentował ' + createdDate}</span>
					</Tooltip>
					<div dangerouslySetInnerHTML={{ __html: props.comment.content! }}></div>
					<div className="sh-comments__info">
						<Tooltip id="upVote" title="Daj lub usuń lajka" placement="top">
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									props.upVote(
										props.comment.statisticsId ?? 0,
										props.comment.statistics?.statisticsUser?.like ?? false
									);
								}}
								className={
									props.comment.statistics?.statisticsUser?.like
										? 'sh-comments__btn-upvote sh-btn-icon active'
										: 'sh-comments__btn-upvote sh-btn-icon'
								}
							>
								<i className="icon-Upvote"></i>
								<span>{props.comment.statistics?.likes}</span>
							</a>
						</Tooltip>

						<Tooltip id="downVote" title="Daj lub usuń unlajka" placement="top">
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									props.downVote(
										props.comment.statisticsId ?? 0,
										props.comment.statistics?.statisticsUser?.dislike ?? false
									);
								}}
								className={
									props.comment.statistics?.statisticsUser?.dislike
										? 'sh-comments__btn-down sh-btn-icon active'
										: 'sh-comments__btn-down sh-btn-icon'
								}
							>
								<i className="icon-Downvote"></i>
								<span>{props.comment.statistics?.dislikes}</span>
							</a>
						</Tooltip>

						<Tooltip id="options" title="Wyświetl opcje" placement="top">
							<a
								href="#"
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e: any) => handleClick(e)}
								className="sh-section__btn-options sh-btn-icon"
							>
								<i className="icon-Options"></i>
							</a>
						</Tooltip>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem onClick={handleOpenModal}>Zgłoś</MenuItem>
							<Modal
								open={open}
								onClose={handleCloseModal}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
							>
								<ReportViolation
									userId={props.comment.userId}
									userName={props.comment.user?.name ?? ''}
									commentId={props.comment.id}
									articleId={props.comment.articleId}
									close={handleCloseModal}
								></ReportViolation>
							</Modal>
							{Number(props.auth.userId) == props.comment.userId && (
								<>
									<MenuItem onClick={handleDelete}>Usuń</MenuItem>
								</>
							)}
						</Menu>
					</div>
				</div>
			</div>
		</div>
	);
};
