import * as React from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { AnyAction, Dispatch } from "redux";
import { downVote, upVote } from "../../../../business/Article/ArticleEpics";
import { getArticlePageSelector } from "../../../../business/Article/ArticleSelectors";
import { IAuthReduxState } from "../../../../business/Auth/AuthReducer";
import { getAuth } from "../../../../business/Auth/AuthSelectors";
import { RootState } from "../../../../business/root-store";
import {
  AddOrUpdateEmoji,
  fetchEmojies,
} from "../../../../business/Static/StaticEpics";
import { Article } from "../../../../client";
import { CommentListComponent } from "./CommentListComponent";

interface ICommentListContainerReduxMergedProps {
  article: Article;
  auth: IAuthReduxState;
}

interface ICommentListContainerOwnProps {}

interface ICommentListContainerDispatchProps {
  onUpVote: (statisticsId: number, currentLike: boolean) => void;
  onDownVote: (statisticsId: number, currentDislike: boolean) => void;
}

interface ICommentListContainerProps
  extends ICommentListContainerOwnProps,
    ICommentListContainerDispatchProps,
    ICommentListContainerReduxMergedProps {}

interface ICommentListComponentState {}

class CommentListContainer extends React.Component<
  ICommentListContainerProps,
  ICommentListComponentState
> {
  constructor(
    props: ICommentListContainerProps,
    state: ICommentListComponentState
  ) {
    super(props, state);
    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return (
      <CommentListComponent
        comments={this.props.article.comments ?? []}
        onUpVote={this.props.onUpVote}
        onDownVote={this.props.onDownVote}
        auth={this.props.auth}
      />
    );
  }

  public static mapStateToProps = (
    state: RootState,
    ownProps: ICommentListContainerOwnProps
  ): ICommentListContainerReduxMergedProps => {
    return {
      article: getArticlePageSelector(state.article),
      auth: getAuth(state),
    };
  };

  public static mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>
  ): ICommentListContainerDispatchProps => {
    return {
      ...Redux.bindActionCreators(
        {
          onUpVote: upVote,
          onDownVote: downVote,
          getEmojies: fetchEmojies,
          addOrUpdateEmoji: AddOrUpdateEmoji,
        },
        dispatch
      ),
    };
  };

  private getInitialState = (): ICommentListComponentState => {
    return {} as ICommentListComponentState;
  };
}
export const CommentList = connect(
  CommentListContainer.mapStateToProps,
  CommentListContainer.mapDispatchToProps
)(CommentListContainer);
