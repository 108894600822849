import * as React from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { AnyAction, Dispatch } from 'redux';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { getAuth } from '../../../../business/Auth/AuthSelectors';
import { RootState } from '../../../../business/root-store';
import { GetUserInfoResponse } from '../../../../client';
import { ProfileMenuComponent } from './ProfileMenuComponent';

interface IProfileMenuContainerReduxMergedProps {
	auth: IAuthReduxState;
}

interface IProfileMenuContainerOwnProps {
	userInfo: GetUserInfoResponse;
}

interface IProfileMenuContainerDispatchProps {}

interface IProfileMenuContainerProps
	extends IProfileMenuContainerOwnProps,
		IProfileMenuContainerDispatchProps,
		IProfileMenuContainerReduxMergedProps {}

interface IProfileMenuComponentState {}

class ProfileMenuContainer extends React.Component<
	IProfileMenuContainerProps,
	IProfileMenuComponentState
> {
	constructor(props: IProfileMenuContainerProps, state: IProfileMenuComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <ProfileMenuComponent userInfo={this.props.userInfo} auth={this.props.auth} />;
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IProfileMenuContainerOwnProps
	): IProfileMenuContainerReduxMergedProps => {
		return {
			auth: getAuth(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IProfileMenuContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({}, dispatch)
		};
	};

	private getInitialState = (): IProfileMenuComponentState => {
		return {} as IProfileMenuComponentState;
	};
}
export const ProfileMenu = connect(
	ProfileMenuContainer.mapStateToProps,
	ProfileMenuContainer.mapDispatchToProps
)(ProfileMenuContainer);
