export const routes = {
  home: "/",
  articlesByTag: "/tag/:tagId/:tagName",
  article: "/article/:id/:topic",
  addArticle: "/article/add",
  editArticle: "/article/edit",
  login: "/login",
  register: "/register",
  profile: "/profile/:id",
  editProfile: "/profile/:id/edit",
  patreon: "/profile/:id/patreon",
  patreonList: "/patreonList/",
  verifyMail: "/auth/activate/:guid",
  aboutUs: "/aboutous",
  paymentsSuccess: "/payment/success/:paymentId",
  paymentsNegatgive: "/payment/negative/:paymentId",
  regulamin: "/regulamin",
  regulaminPatreon: "/regulamin/patreon",
  verifyMailPlease: "/auth/activate",
};
