import * as React from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { AnyAction, Dispatch } from "redux";
import { IAuthReduxState } from "../../../../business/Auth/AuthReducer";
import { getAuth } from "../../../../business/Auth/AuthSelectors";
import { RootState } from "../../../../business/root-store";
import { Comment } from "../../../../client";
import { downVote, upVote } from "../../../../business/Article/ArticleEpics";
import { CommentListProfileComponent } from "./CommentListProfileComponent";
import { List } from "immutable";

interface ICommentListProfileContainerReduxMergedProps {
  auth: IAuthReduxState;
}

interface ICommentListProfileContainerOwnProps {
  comments: List<Comment>;
}

interface ICommentListProfileContainerDispatchProps {
  onUpVote: (statisticsId: number, currentLike: boolean) => void;
  onDownVote: (statisticsId: number, currentDislike: boolean) => void;
}

interface ICommentListProfileContainerProps
  extends ICommentListProfileContainerOwnProps,
    ICommentListProfileContainerDispatchProps,
    ICommentListProfileContainerReduxMergedProps {}

interface ICommentListProfileComponentState {}

class CommentListProfileContainer extends React.Component<
  ICommentListProfileContainerProps,
  ICommentListProfileComponentState
> {
  constructor(
    props: ICommentListProfileContainerProps,
    state: ICommentListProfileComponentState
  ) {
    super(props, state);
    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return (
      <CommentListProfileComponent
        comments={this.props.comments}
        onUpVote={this.props.onUpVote}
        onDownVote={this.props.onDownVote}
        auth={this.props.auth}
      />
    );
  }

  public static mapStateToProps = (
    state: RootState,
    ownProps: ICommentListProfileContainerOwnProps
  ): ICommentListProfileContainerReduxMergedProps => {
    return {
      auth: getAuth(state),
    };
  };

  public static mapDispatchToProps = (
    dispatch: Dispatch<AnyAction>
  ): ICommentListProfileContainerDispatchProps => {
    return {
      ...Redux.bindActionCreators(
        {
          onUpVote: upVote,
          onDownVote: downVote,
        },
        dispatch
      ),
    };
  };

  private getInitialState = (): ICommentListProfileComponentState => {
    return {} as ICommentListProfileComponentState;
  };
}
export const CommentListProfile = connect(
  CommentListProfileContainer.mapStateToProps,
  CommentListProfileContainer.mapDispatchToProps
)(CommentListProfileContainer);
