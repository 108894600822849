import './MenuFooter.css';

export const MenuFooter: React.FC = () => {
	return (
		<div className="menuFooter">
			<a href="https://dramki.pl" className="footer" target="_blank">
				Dramki.pl
			</a>
			<a href="https://dramki.pl/regulamin" className="footer" target="_blank">
				Regulamin
			</a>
		</div>
	);
};
