import 'moment/locale/pl';

import { Menu, MenuItem, Modal, Tooltip } from '@material-ui/core';

import { AddComment } from '../AddComment/AddComment';
import { Comment } from '../../../../client';
import { IAuthReduxState } from '../../../../business/Auth/AuthReducer';
import { Link } from 'react-router-dom';
import React from 'react';
import { ReportViolation } from '../../Violations/ReportViolation';
import { deleteComment } from '../../../../business/Article/ArticleEpics';
import moment from 'moment';
import { useDispatch } from 'react-redux';

export interface ICommentListItemProps {
	comment: Comment;
	childsComments?: Comment[];
	upVote: (statisticsId: number, currentLike: boolean) => void;
	downVote: (statisticsId: number, currentDislike: boolean) => void;
	setReplyStateFromParent?: React.Dispatch<
		React.SetStateAction<{
			reply: boolean;
			userId?: number | undefined;
			userName?: string | undefined;
			change?: boolean | undefined;
			commentToEdit?: Comment | undefined;
		}>
	>;
	auth: IAuthReduxState;
}

export const CommentListItem: React.FC<ICommentListItemProps> = (props) => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [open, setOpen] = React.useState(false);

	const handleOpenModal = () => {
		handleClose();
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleEdit = () => {
		setAnchorEl(null);
		if (isChild) {
			props.setReplyStateFromParent!({
				reply: true,
				userId: props.comment.userId,
				change: true,
				commentToEdit: props.comment
			});
		} else {
			setReplyState({
				reply: true,
				userId: props.comment.userId,
				change: true,
				commentToEdit: props.comment
			});
		}
	};

	const handleDelete = () => {
		setAnchorEl(null);
		dispatch(deleteComment(props.comment));
	};
	const [replyState, setReplyState] = React.useState<{
		reply: boolean;
		userId?: number;
		userName?: string;
		change?: boolean;
		commentToEdit?: Comment;
	}>({ reply: false });
	const createdDate = moment.utc(props.comment.createdDateUtc).fromNow();
	const isChild = props.setReplyStateFromParent != undefined ?? false;
	return (
		<>
			<div className="sh-comments__section">
				<div className="sh-avatar">
					<img
						src={
							props.comment.user?.avatar
								? process.env.REACT_APP_API_URL + '/' + props.comment.user?.avatar
								: '/assets/img/screamingfear.gif'
						}
						alt=""
					/>
				</div>
				<div className="sh-comments__content">
					<Tooltip id="user" title="Przejdź do profilu użytkownika" placement="top">
						<Link
							to={'/profile/' + props.comment.user?.id}
							className="sh-comments__name"
						>
							{props.comment.user?.userName + ' '}
						</Link>
					</Tooltip>
					<Tooltip
						id="date"
						title={moment
							.utc(props.comment.createdDateUtc ?? null)
							.local()
							.format('LLL')}
						placement="right"
					>
						<span className="sh-comments__passed">{createdDate}</span>
					</Tooltip>
					<div dangerouslySetInnerHTML={{ __html: props.comment.content! }}></div>
					<div className="sh-comments__info">
						<Tooltip id="upVote" title="Daj lub usuń lajka" placement="top">
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									props.upVote(
										props.comment.statisticsId ?? 0,
										props.comment.statistics?.statisticsUser?.like ?? false
									);
								}}
								className={
									props.comment.statistics?.statisticsUser?.like
										? 'sh-comments__btn-upvote sh-btn-icon active'
										: 'sh-comments__btn-upvote sh-btn-icon'
								}
							>
								<i className="icon-Upvote"></i>
								<span>{props.comment.statistics?.likes}</span>
							</a>
						</Tooltip>

						<Tooltip id="downVote" title="Daj lub usuń dislajka" placement="top">
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									props.downVote(
										props.comment.statisticsId ?? 0,
										props.comment.statistics?.statisticsUser?.dislike ?? false
									);
								}}
								className={
									props.comment.statistics?.statisticsUser?.dislike
										? 'sh-comments__btn-down sh-btn-icon active'
										: 'sh-comments__btn-down sh-btn-icon'
								}
							>
								<i className="icon-Downvote"></i>
								<span>{props.comment.statistics?.dislikes}</span>
							</a>
						</Tooltip>

						<Tooltip id="averageLikes" title="Odpowiedz" placement="top">
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									if (isChild) {
										props.setReplyStateFromParent!({
											reply: true,
											userId: props.comment.userId,
											userName: props.comment.user?.userName ?? undefined,
											change: true
										});
									} else {
										setReplyState({
											reply: true,
											userId: props.comment.userId,
											userName: props.comment.user?.userName ?? undefined,
											change: true
										});
									}
								}}
								className="sh-section__btn-like sh-btn-icon"
							>
								<i className="icon-Reply"></i>
							</a>
						</Tooltip>

						<Tooltip id="options" title="Wyświetl opcje" placement="top">
							<a
								href="#"
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e: any) => handleClick(e)}
								className="sh-section__btn-options sh-btn-icon"
							>
								<i className="icon-Options"></i>
							</a>
						</Tooltip>
					</div>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={handleOpenModal}>Zgłoś</MenuItem>
						<Modal
							open={open}
							onClose={handleCloseModal}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<ReportViolation
								userId={props.comment.userId}
								userName={props.comment.user?.userName ?? ''}
								commentId={props.comment.id}
								articleId={props.comment.articleId}
								close={handleCloseModal}
							></ReportViolation>
						</Modal>
						{Number(props.auth.userId) == props.comment.userId && (
							<>
								<MenuItem onClick={handleEdit}>Edytuj</MenuItem>
								<MenuItem onClick={handleDelete}>Usuń</MenuItem>
							</>
						)}
					</Menu>
					{props.childsComments?.map((childComment: Comment) => {
						return (
							<div className="marginleft-30">
								<CommentListItem
									setReplyStateFromParent={setReplyState}
									comment={childComment}
									upVote={props.upVote}
									downVote={props.downVote}
									auth={props.auth}
								/>
							</div>
						);
					})}
				</div>
			</div>
			{replyState.reply && (
				<>
					<br />
					<AddComment
						setReplyState={setReplyState}
						replyState={replyState}
						parentCommentId={props.comment.id}
						commentLenghtLimit={300}
					/>
				</>
			)}
		</>
	);
};
