import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilePondFile } from 'filepond';
import { List } from 'immutable';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
	AddArticleTagSaved,
	AddArticleTagsSaved,
	AddArticleTopicSaved
} from '../../../../business/Article/ArticleActions';
import { AddOrUpdateArticle, Tag } from '../../../../client';
import { CustomFilePond } from '../../CustomFilePond/CustomFilePond';
import { CustomQuill } from '../../CustomQuill/CustomQuill';
import {
	CustomColorCheckbox,
	CustomTextField
} from '../../StyledMaterialUiComponents/StyledMaterialUiComponents';

export interface IAddArticleProps {
	allTags: List<Tag>;
	addArticle: AddOrUpdateArticle;
	addArticleImage: string | FilePondFile | null;
	AddTag: (tagName: string) => void;
	AddArticleImageSaved: (addArticleImage: FilePondFile) => void;
	AddArticleContentSaved: (content: string) => void;
	AddArticleImageRemoved: () => void;
	AddArticleImageHadInitialSaved: (addArticleHadinitial: boolean) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddArticleItem: React.FC<IAddArticleProps> = (props) => {
	const dispatch = useDispatch();

	const handleChange = (e: any) => {
		dispatch(AddArticleTopicSaved(e.target.value));
	};

	const handleChangeAutocomplete = (e: any, value: any) => {
		dispatch(AddArticleTagsSaved(value));
	};

	const handleInputAutocomplete = (event: any) => {
		switch (event.key) {
			case ',':
			case ' ':
			case 'Enter': {
				event.preventDefault();
				if (
					event.target.value.length > 0 &&
					!props.addArticle.tags?.some((x) => x.name === event.target.value)
				) {
					if (props.allTags.some((x) => x.name === event.target.value)) {
						dispatch(
							AddArticleTagSaved(
								props.allTags.filter((x) => x.name === event.target.value).first()
							)
						);
					} else {
						props.AddTag(event.target.value);
					}
				}
				break;
			}
			default:
		}
	};

	let image = props.addArticleImage;
	if (props.addArticle.pictureOriginal != null && props.addArticle.pictureOriginal != undefined) {
		props.AddArticleImageHadInitialSaved(true);
		image = props.addArticle.pictureOriginal;
	}
	return (
		<div className="sh-section ">
			<div className="sh-section__content">
				<div className="formSection">
					<CustomTextField
						value={props.addArticle.topic || ''}
						placeholder="Tytuł dramki"
						fullWidth
						margin="normal"
						InputLabelProps={{
							shrink: true
						}}
						onChange={handleChange}
					/>
				</div>
				<br />
				<div className="formSection">
					<CustomFilePond
						imageSaved={props.AddArticleImageSaved}
						description="Wybierz lub przerzuć aby dodać miniaturkę, która wyróżni dramkę"
						image={image}
						imageRemoved={props.AddArticleImageRemoved}
					/>
				</div>
				<div className="formSection">
					<CustomQuill
						value={props.addArticle.content}
						handleChange={props.AddArticleContentSaved}
					/>
				</div>
				<br />
				<div className="formSection">
					<Autocomplete
						aria-required={true}
						value={props.addArticle.tags ?? []}
						onChange={(event, value) => handleChangeAutocomplete(event, value)}
						multiple
						id="checkboxes-tags-demo"
						options={props.allTags.toArray()}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name!}
						renderOption={(option, { selected }) => (
							<React.Fragment>
								<CustomColorCheckbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.name!}
							</React.Fragment>
						)}
						renderInput={(params) => (
							<CustomTextField
								{...params}
								placeholder="Tagi"
								variant="outlined"
								onKeyDown={handleInputAutocomplete}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};
