import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/profilePage";
import { GetUserInfoResponse } from "../../../client";
import { Header } from "../../molecules/Header/Header";
import { ProfileMenu } from "../../molecules/Menu/ProfileMenu/ProfileMenuContainer";
import Parallax from "../../molecules/Parallax/Parallax";
import { ProfileActivites } from "../../molecules/Profile/ProfileActivities/ProfileActivites";
import { ProfileInfo } from "../../molecules/Profile/ProfileInfo/ProfileInfo";

export interface IProfilePageItemProps {
  userInfo: GetUserInfoResponse;
  userId: number;
}
const useStyles = makeStyles(styles);

export const ProfilePageItem: React.FC<IProfilePageItemProps> = (props) => {
  const classes = useStyles();
  return (
    <>
      <Header>
        <ProfileMenu userInfo={props.userInfo} />
      </Header>
      <Parallax small filter image={"/assets/img/profile-bg.jpg"} />
      <div className={classNames(classes.mainRaised)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-1 d-none d-lg-block"></div>
            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12 px-1 px-md-2">
              <ProfileInfo userInfo={props.userInfo} />
              <ProfileActivites
                userInfo={props.userInfo}
                userId={props.userId}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 d-none d-md-block">
              <ProfileMenu userInfo={props.userInfo} />
            </div>
            <div className="col-xl-1 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </>
  );
};
