import React from "react";
import { IAuthReduxState } from "../../../../business/Auth/AuthReducer";
import { Comment } from "../../../../client";
import { CommentListProfileItem } from "./CommentListProfileItem";
import { List } from "immutable";

interface ICommentListProfileComponentProps {
  comments: List<Comment>;
  onUpVote: (statisticsId: number, currentLike: boolean) => void;
  onDownVote: (statisticsId: number, currentDislike: boolean) => void;
  auth: IAuthReduxState;
}

interface ICommentListProfileComponentState {}

export class CommentListProfileComponent extends React.Component<
  ICommentListProfileComponentProps,
  ICommentListProfileComponentState
> {
  constructor(
    props: ICommentListProfileComponentProps,
    state: ICommentListProfileComponentState
  ) {
    super(props, state);

    this.state = this.getInitialState();
  }

  public render(): JSX.Element {
    return (
      <>
        {this.isListVisible() && <>{this.renderCommentListProfileSection()}</>}
      </>
    );
  }

  private renderCommentListProfileSection = (): JSX.Element => {
    if (!this.isListVisible() || !this.props.comments) {
      return <></>;
    }
    return (
      <div>
        {this.props.comments.map((comment: Comment) => {
          return (
            <CommentListProfileItem
              comment={comment}
              upVote={this.handleUpVote}
              downVote={this.handleDownVote}
              auth={this.props.auth}
            />
          );
        })}
      </div>
    );
  };

  private handleUpVote = (statisticsId: number, currentLike: boolean): void => {
    this.props.onUpVote(statisticsId, currentLike);
  };

  private handleDownVote = (
    statisticsId: number,
    currentDislike: boolean
  ): void => {
    this.props.onDownVote(statisticsId, currentDislike);
  };

  private isListVisible = (): boolean => {
    return this.props.comments && this.props.comments.count() > 0;
  };

  private getInitialState = (): ICommentListProfileComponentState => {
    return {} as ICommentListProfileComponentState;
  };
}
