import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { Backdrop, CircularProgress, MuiThemeProvider, Tooltip, Zoom } from '@material-ui/core';
import {
	CustomFab,
	CustomTheme
} from './components/molecules/StyledMaterialUiComponents/StyledMaterialUiComponents';
import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import AddArticlePage from './components/organisms/AddArticlePage/AddArticlePage';
import ArticlePage from './components/organisms/ArticlePage/ArticlePage';
import { ArticlesByTagPage } from './components/organisms/ArticlesByTagPage/ArticlesByTagPage';
import { ConnectedRouter } from 'connected-react-router';
import EditArticlePage from './components/organisms/EditArticlePage/EditArticlePage';
import { EditProfilePage } from '../src/components/organisms/EditProfilePage/EditProfilePageContainer';
import { ExceptionHandlingResultModel } from './client';
import { FetchPatreons } from './business/Static/StaticEpics';
import Footer from './components/molecules/Footer/Footer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LandingPage from './components/organisms/LandingPage/LandingPage';
import LoginRegisterPage from './components/organisms/LoginRegisterPage/LoginRegisterPage';
import { PatreonListPage } from '../src/components/organisms/PatreonPage/PatreonListPageContainer';
import { PatreonPage } from '../src/components/organisms/PatreonPage/PatreonPageContainer';
import PaymentsSuccess from './components/organisms/Payments/PaymentsSuccess';
import ProblemPage from './components/organisms/ProblemPage/ProblemPage';
import { ProfilePage } from '../src/components/organisms/ProfilePage/ProfilePageContainer';
import { Route } from 'react-router';
import ScrollToTop from './components/atoms/ScrollToTop/ScrollToTop';
import TermsAndConditions from '../src/components/organisms/TermsAndConditions/TermsAndConditions';
import TermsAndConditionsPatreon from '../src/components/organisms/TermsAndConditions/TermsAndConditionsPatreon';
import VerifyMailPage from './components/organisms/VerifyMailPage/VerifyMailPage';
import { VerifyMailPageLogic } from './components/organisms/VerifyMailPage/VerifyMailPageLogic';
import { actions } from 'react-redux-toastr';
import appHistory from './app-history';
import { getPatreons } from './business/Static/StaticSelectors';
import { navigationAction } from './business/router/router-actions';
import { routes } from './business/router/path-definitions';

export const App: React.FC = () => {
	const patreons = useSelector(getPatreons);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(FetchPatreons());
	}, [patreons]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [fab, setFab] = React.useState(true);
	const fabChange = () => {
		const windowsScrollTop = window.scrollY;
		if (windowsScrollTop > 800) {
			setFab(false);
		} else {
			setFab(true);
		}
	};
	window.addEventListener('scroll', fabChange);
	window.addEventListener('error', (event) => {
		console.log(event);
	});

	window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
		console.log(event);
	});

	var numberOfAjaxCAllPending = 0;
	axios.interceptors.request.use(
		function (config) {
			numberOfAjaxCAllPending++;
			setIsLoading(true);
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		function (response) {
			numberOfAjaxCAllPending--;
			if (numberOfAjaxCAllPending == 0) {
				setIsLoading(false);
			}
			return response;
		},
		function (reason: AxiosError<{ additionalInfo: string }>) {
			numberOfAjaxCAllPending--;
			if (numberOfAjaxCAllPending == 0) {
				setIsLoading(false);
			}

			if (reason.response!.status === 401) {
				dispatch(
					actions.add({
						message: 'Zaloguj się, aby wykonać tę akcję',
						type: 'warning'
					})
				);
				dispatch(navigationAction.open(routes.login));
			} else {
				var res = reason.response!.data as ExceptionHandlingResultModel;
				if (
					res !== null &&
					res !== undefined &&
					res.errors != null &&
					res.errors != undefined &&
					res.errors.length > 0
				) {
					res.errors.forEach((element) => {
						dispatch(
							actions.add({
								message: element.message ?? '',
								type: 'error'
							})
						);
					});
				} else {
					dispatch(
						actions.add({
							message: 'Wystąpil problem ' + res?.errors,
							type: 'error'
						})
					);
				}
			}

			// console.log(reason.response!);
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject();
		}
	);

	return (
		<MuiThemeProvider theme={CustomTheme}>
			<ConnectedRouter history={appHistory}>
				<Backdrop open={isLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<ScrollToTop />
				<Switch>
					<Route exact path={routes.home} component={LandingPage} />
					<Route path={routes.addArticle} component={AddArticlePage} />
					<Route path={routes.editArticle} component={EditArticlePage} />
					<Route path={routes.article} component={ArticlePage} />
					<Route path={routes.articlesByTag} component={ArticlesByTagPage} />
					<Route exact path={routes.editProfile} component={EditProfilePage} />
					<Route exact path={routes.profile} component={ProfilePage} />
					<Route exact path={routes.patreon} component={PatreonPage} />
					<Route exact path={routes.patreonList} component={PatreonListPage} />
					<Route exact path={routes.paymentsSuccess} component={PaymentsSuccess} />
					<Route path={routes.login} component={() => LoginRegisterPage(0)} />
					<Route path={routes.register} component={() => LoginRegisterPage(1)} />
					<Route exact path={routes.verifyMail} component={VerifyMailPageLogic} />
					<Route exact path={routes.verifyMailPlease} component={VerifyMailPage} />
					<Route
						exact
						path={routes.regulaminPatreon}
						component={TermsAndConditionsPatreon}
					/>
					<Route path={routes.regulamin} component={TermsAndConditions} />

					<Route path="/404" component={ProblemPage} />
					<Redirect to="/404" />
				</Switch>

				<Zoom in={!fab} unmountOnExit>
					<CustomFab
						size="large"
						onClick={() => {
							window.scrollTo(0, 0);
						}}
						disabled={fab}
					>
						<Tooltip title="Wróć na górę" placement="top">
							<KeyboardArrowUpIcon style={{ fill: 'white' }} />
						</Tooltip>
					</CustomFab>
				</Zoom>
				<Footer />
			</ConnectedRouter>
		</MuiThemeProvider>
	);
};
