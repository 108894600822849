import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import styles from "../../../assets/jss/material-kit-react/views/ArticlePage";
import { Header } from "../../molecules/Header/Header";
const useStyles = makeStyles(styles);

export default function TermsAndConditionsPatreon() {
  const classes = useStyles();
  return (
    <>
      <Header>{/* faqmenu */}</Header>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url('/assets/img/bg7.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      ></div>
      <div className={classNames(classes.mainRaised)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="sh-content-head sh-content-head__flex-off">
                <div className="row">
                  <div className="sh-section">
                    <div className="sh-section__head">
                      <h1>Regulamin przyznania statusu VIP w serwisie dramki.pl</h1>
                    </div>
                    <div className="sh-section__content">
                      <div className="sh-section__text">
                        <main role="main">
                          <h3>I. Określenia i definicje</h3>
                          <p>
                            Ilekroć w niniejszym Regulaminie stosuje się
                            poniższe określenia i definicje, należy je rozumieć
                            jak następuje:
                          </p>
                          <ul>
                            <li>
                              <span className="fw-bold">Regulamin</span>{" "}
                              „Regulamin konta VIP (patrona) w Serwisie dramki.pl”
                              stanowiący regulamin w rozumieniu w rozumieniu
                              ustawy z dnia 18 lipca 2002 roku o świadczeniu
                              usług drogą elektroniczną (Dz. U. nr 144 poz.
                              1204), określający zasady świadczenia usług drogą
                              elektroniczną przez Tailored Apps na rzecz
                              Użytkowników prowadzonego przez nią Serwisu
                              Dramki, obejmujący zasady tworzenia konta VIP Użytkownika.
                            </li>
                            <li>
                              <span className="fw-bold">Serwis</span> Dramki.pl
                              Serwis internetowy prowadzony przez Tailored Apps
                              pod aktualnym adresem URL: https://www.dramki.pl
                              oraz pod adresami będącymi przekierowaniami do
                              tego adresu, a także każdym innym będącym jego
                              uzupełnieniem lub kontynuacją, a także każdym,
                              który go zastąpi.
                            </li>
                            <li>
                              <span className="fw-bold">Tailored Apps</span>{" "}
                              Tailored Apps Łukasz Kowalski, z siedzibą w Łodzi
                              przy ulicy Wólczańska 125 prowadzony jest przez
                              Łukasza Kowalskiego prowadzącego działalność
                              gospodarczą pod firmą, wpisaną do Centralnej
                              Ewidencji i Informacji o Działalności Gospodarczej
                              (CEIDG) prowadzonej przez ministra właściwego ds.
                              gospodarki, NIP: 728 261 44 83.
                            </li>
                            <li>
                              <span className="fw-bold">Użytkownik</span> Osoba
                              fizyczna korzystająca z bezpłatnego indywidualnego
                              konta Użytkownika Serwisu Dramki.pl.
                            </li>
                            <li>
                              <span className="fw-bold">konto VIP</span> Osoba
                              fizyczna korzystająca z  Serwisu Dramki.pl posiadająca konto użytkownika  oraz wykupiony status VIP z aktualnym okresem wsparcia
                            </li>
                            <li>
                              <span className="fw-bold">patron</span> Osoba
                              fizyczna korzystająca z Serwisu Dramki.pl posiadająca konto użytkownika oraz wykupiony status VIP
                            </li>
                            <li>
                              <span className="fw-bold">okres wsparcia</span> Osoba
                              okres trwający 1 miesiąc od momentu zakupu konta VIP w serwisie 
                            </li>
                            <li>
                              <span className="fw-bold">operator płatności</span> System pośredniczący umożliwiający dokonanie zakupu konta VIP
                            </li>
                          </ul>
                          <h3>
                            II. Zasady zakupu konta VIP.
                          </h3>
                          <p>Zakup konta:</p>
                          <ol>
                            <li>
                              Rejestrację i uzupełnienie formularza zakupu oraz dokonanie płatnosci przez operatora płatności jest wymagane do tego aby uzyskać status konta VIP.
                            </li>
                            <li>
                              Użytkownikiem konta VIP może zostać osoba pełnoletnia.
                              Osoba niepełnoletnia może zostać Użytkownikiem
                              konta za zgodą swoich rodziców lub opiekunów
                              prawnych.
                            </li>
                            <li>
                              Po poprawnej weryfikacji płatności użytkownik uzyskuje możliwość korzystania z
                              konta w Serwisie Dramki.pl z oznaczeniem 💥
                              automatycznie po zaakceptowaniu płatności w serwisie dramki.pl
                              oraz wypełnieniu i wysłaniu formularza
                              rejestracyjnego.
                            </li>
                            <li>
                              Powiadomienia dotyczące dokonanej płatności będą
                              przesyłane na adres poczty elektronicznej podanej
                              przez Użytkownika w formularzu rejestracyjnym.
                              Użytkownik jest wyłącznie odpowiedzialny za
                              podanie aktualnego i poprawnego adresu e-mail.
                            </li>
                            <li>
                              Tailored Apps nie kontroluje, ani nie weryfikuje
                              danych identyfikacyjnych użytkownika zawartych w
                              formularzu rejestracyjnym.
                            </li>
                            <li>
                              Wysłanie wypełnionego formularza rejestracyjnego
                              przez Użytkownika oznacza jego zgodę na
                              gromadzenie, przetwarzanie i wykorzystywanie
                              zawartych w nim danych osobowych przez Tailored
                              Apps w celu świadczenia dostępnych na stronach
                              Serwisu Dramki.pl usług oraz w celach
                              wynikających z obowiązku podatkowego, zgodnie z ustawami z dnia 18 lipca
                              2002 roku o świadczeniu usług drogą elektroniczną
                              (Dz. U. Nr 144 poz.1204) oraz z 29 sierpnia 1997
                              roku o ochronie danych osobowych (Dz. U. nr 133,
                              poz. 883).
                            </li>
                            <li>
                              Tailored Apps zapewnia każdemu Użytkownikowi konta
                              dostęp do dotyczących go danych w celu ich
                              weryfikacji lub modyfikacji. Tailored Apps
                              zobowiązuje się dołożyć wszelkich starań w celu
                              ochrony danych Użytkowników kont.
                            </li>
                            <li>
                              Użytkownik ponosi wyłączną odpowiedzialność za
                              sposób, w jaki wykorzystuje bezpłatne konto w
                              Serwisie Dramki.pl, w tym za zawartość i formę
                              zamieszczanych treści, z uwzględnieniem
                              odpowiedzialności karnej lub cywilnej za działania
                              niezgodne z prawem. Na żądanie uprawnionych
                              organów państwa, na potrzeby prowadzonych przez
                              nie postępowań, Tailored Apps przekazuje
                              pozostające w jego posiadaniu dane Użytkowników
                              kont Serwisu Dramki.pl.
                            </li>
                            
                            <li>
                              Tailored Apps nie ponosi odpowiedzialności za
                              wszelkie straty i szkody, w tym utratę danych,
                              wynikające z awarii, przerw w funkcjonowaniu
                              systemu z przyczyn technicznych lub z przyczyn
                              niezależnych od Tailored Apps.
                            </li>
                            <li>
                              Tailored Apps zastrzega sobie prawo do usuniecia statusu VIP dla
                              konta Użytkownika, gdy:
                              <ul>
                                <li>
                                  Użytkownik wykorzystuje konto niezgodnie z
                                  postanowieniami obowiązującego prawa lub
                                  Regulaminu,
                                </li>
                                <li>
                                  nazwa konta narusza prawo, prawem chronione
                                  dobra osób trzecich, jest niezgodna z dobrymi
                                  obyczajami, w tym jest wyrażeniem powszechnie
                                  uznanym za obraźliwe.
                                </li>
                                <li>
                                  minie okres 1 (jednego) miesiąca od momentu zakupu konta VIP w serwisie.
                                </li>
                              </ul>
                            </li>
                          </ol>
                          <h3>
                            III.Zasady zwrotów i reklamacji w serwisie.
                          </h3>
                          <ol>
                            <li>
                              Użytkownik ma prawo do rezygnacji z konta VIP. po zgłoszeniu na adres contact@tailoredapps.pl
                            </li>
                            <li>
                              Zwrot środków może nastąpić o pomniejszoną kwotę obliczoną proporcjonalnie od momentu zakupu do momentu rezygnacji.
                            </li>
                           
                            <li>
                              Reklamacje na podstawie Regulaminu powinny być
                              zgłaszane niezwłocznie pisemnie na adres
                              contact@tailoredapps.pl . Reklamacje powinny
                              zawierać co najmniej następujące dane:
                              <ul>
                                <li>
                                  a) oznaczenie podmiotu składającego reklamację
                                  (imię i nazwisko/nazwa, adres, adres poczty
                                  elektronicznej);
                                </li>
                                <li>b) przedmiot reklamacji;</li>
                                <li>
                                  c) wszystkie okoliczności uzasadniające
                                  reklamację.
                                </li>
                              </ul>
                            </li>
                            <li>
                              Reklamacje nie zawierające powyższych danych nie
                              będą rozpatrywane.
                            </li>
                            <li>
                              Reklamacje rozpatrywać będzie Serwis dramki.pl w
                              terminie 30 (trzydziestu) dni od dnia ich
                              otrzymania.
                            </li>
                            <li>
                              Decyzja Serwisu dramki.pl w sprawie reklamacji będzie
                              ostateczna.
                            </li>
                          </ol>
                         
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 d-none d-xxl-block"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
        </div>
      </div>
    </>
  );
}
